import React, {useEffect, useState} from 'react'

import './FadeLogo.css'

const FadeLogo = (props) => {

  const [logos, setLogos] = useState(props.fadeLogoSettings);

  useEffect(() => {
    const intervals = []
    logos.forEach((item, idx) => {
      if(!intervals[idx]){
        let interval = setInterval(() => {

          setLogos(oldLogos => {
            const logosIds = []

            oldLogos.forEach((oldLogo) => {
              logosIds.push(oldLogo.front.id, oldLogo.back.id)
            })

            const newLogos = props.logosData.filter((newLogosItem) => {
              return !logosIds.find(id => id === newLogosItem.id)
            })

            const newLogo = newLogos[Math.floor(Math.random() * (newLogos.length - 1))];

            return oldLogos.reduce((acc, circle)=>{
              if(circle.id === item.id){
                if(circle.display === 0 ){
                  return acc.concat({
                    ...circle,
                    display: 1,
                    back: {
                      ...newLogo
                    }
                  })

                } else if(circle.display === 1) {
                  return acc.concat({
                    ...circle,
                    display: 0,
                    front: {
                      ...newLogo
                    }
                  })
                }

              }

              return acc.concat(circle)

            }, [])
          })

        }, item.seconds * 1000);

        intervals.push(interval)

      }
    })

    return () => intervals.map((item) =>clearInterval(item))

  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <div className='fade-logo'>
      {logos.map((logo, idx) => (
        <div
          key={idx} className={`logo-${logo.id}`}>

          <div className='logo-face' style={{opacity: `${logo.display === 1 ? 0 : 1}`, transition: `all ${logo.animTime}s ease-in-out ${logo.display === 0 ? logo.animTime - .3 +'s' : '0s'}`}}>
            <img src={logo.front.img} alt="logo" />
          </div>
          <div className='logo-back' style={{opacity: `${logo.display === 0 ? 0 : 1}`, transition: `all ${logo.animTime}s ease-in-out ${logo.display === 1 ? logo.animTime - .3 +'s' : '0s'}`}}>
            <img src={logo.back.img} alt="logo" />
          </div>
        </div>
      ))}
    </div>
  )
}

export default FadeLogo