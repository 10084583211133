import React from "react";
import { Link } from "react-router-dom";

import img1 from "../../../assets/icons/question.svg";

import "./QuestionsBtn.css";

const QuestionsBtn = () => {
  return (
    <section className="cb-questions-btn hide-tablet hide-desktop">
      <div className="cb-container">
        <div className="cb-questions-btn-wrapper">
          <Link to="/frequent-questions">
            <h2>
              <img src={img1} alt="icon" />
              Intrebari frecvente
            </h2>
          </Link>
        </div>
      </div>
    </section>
  );
};

export default QuestionsBtn;
