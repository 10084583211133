import React from "react";

import { Helmet } from "react-helmet";
import ShopsListing from "./shops-listing/ShopsListing";
import { QuestionsBtn } from "../../components";

// import img1 from '../../assets/images/shops/appDark.png';
// import img2 from '../../assets/images/shops/app.png';

const SpShops = () => {
  return (
    <>
      <Helmet>
        <meta charSet="utf-8" />
        <title>Shops</title>
      </Helmet>
      <ShopsListing />
      {/* <GetAppSMS
        desktopBgColor={{
          background: 'linear-gradient(180deg, rgba(67,192,101,1) 0%, rgba(97,213,108,1) 100%)',
          margin: '5rem 0',
        }}
        desktopImage={img1}
        desktopTitle='CashClub pe mobil'
        desktopContent='Descarca aplicatia de mobil si poti urmari in timp real situatia contului tau'
        desktopBtnColor='black'
        desktopTextStyle={{ color: '#ffffff', opacity: 'initial', width: '30rem' }}
        mobileImage={img2}
        mobileTitle='Cashclub pe mobil'
        mobileContent='Descarca aplicatia de mobil si poti urmari in timp real situatia contului tau'
        mobileBtnColor='green'
        mobileBtnLink='https://play.google.com/store/apps'
      /> */}
      <QuestionsBtn />
    </>
  );
};

export default SpShops;
