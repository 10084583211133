const initialState = {
  isLoading: false,
  error: false,
  couponsInfo: {
    firstPageUrl: `${process.env.REACT_APP_API_ROOT}/coupons?page=1`,
    nextPageUrl: null,
    prevPageUrl: null,
  },
  coupons: [],
  filterKeyword: null,
  sortKeyword: null,
};

const couponsReducer = (state = initialState, action) => {
  const { type, payload } = action;

  switch (type) {
    case "AWAITING_COUPONS":
      return {
        ...state,
        isLoading: true,
      };

    case "ERROR_COUPONS":
      return {
        ...state,
        isLoading: false,
        error:
          payload.e && payload.e.response
            ? payload.e.response
            : payload.e
            ? payload.e
            : null,
      };

    case "SUCCESS_GET_COUPONS":
      if (
        state.filterKeyword ||
        (state.coupons.length > 40 && !payload.prevPageUrl)
      ) {
        return {
          ...state,
          isLoading: false,
          couponsInfo: {
            firstPageUrl: payload.firstPageUrl,
            nextPageUrl: payload.nextPageUrl,
          },
          coupons: payload.data,
          filterKeyword: state.filterKeyword,

          sortKeyword: state.sortKeyword,
        };
      } else if (
        !state.filterKeyword ||
        (state.coupons.length > 40 && payload.prevPageUrl)
      ) {
        return {
          ...state,
          isLoading: false,
          couponsInfo: {
            firstPageUrl: payload.firstPageUrl,
            nextPageUrl: payload.nextPageUrl,
          },
          coupons: [...state.coupons, ...payload.data],
          filterKeyword: state.filterKeyword,

          sortKeyword: state.sortKeyword,
        };
      }

      break;

    case "SUCCESS_FILTER_COUPONS":
      if (
        (!state.filterKeyword && !payload.sortKey) ||
        state.filterKeyword !== payload.filterKey ||
        payload.sortKey !== state.sortKeyword
      ) {
        return {
          ...state,
          isLoading: false,
          couponsInfo: {
            firstPageUrl: payload.firstPageUrl,
            nextPageUrl: payload.nextPageUrl,
          },
          coupons: payload.data,
          filterKeyword: payload.filterKey,

          sortKeyword: payload.sortKey
        };
      } else if (
        state.filterKeyword === payload.filterKey ||
        (state.filterKeyword === payload.filterKey &&
          state.sortKeyword === payload.sortKey)
      ) {
        return {
          ...state,
          isLoading: false,
          couponsInfo: {
            firstPageUrl: payload.firstPageUrl,
            nextPageUrl: payload.nextPageUrl,
          },
          coupons: [...state.coupons, ...payload.data],
          filterKeyword: payload.filterKey,

          sortKeyword: payload.sortKey
        };
      }

      break;

    default:
      return state;
  }
};

export default couponsReducer;
