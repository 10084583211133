import React from "react";
import { Link } from "react-router-dom";
import {
  CcCarousel,
  FlipCards,
  ResponsiveComponent,
} from "../../../../components";

import products from "../../../../assets/data/products";

import "./StepThree.css";

function StepThree() {
  return (
    <section className="step-three">
      <div className="cb-container small-padding no-side-padding-mobile">
        <div className="step-three-wrapper">
          <div className="step-three-content">
            <span className="content-label">Pasul 2</span>
            <h2>Compara preturi la peste 1.600.000 de produse</h2>
            <p>
              Zambesti, cumperi si primesti bani inapoi la fiecare tranzactie
              online, accesand produsele preferate din magazinele partenere.
            </p>
            <div className="hide-mobile">
              <Link to="/products" className="black btn-append-arrow">
                Comparator preturi
              </Link>
            </div>
          </div>
          <div>
            <ResponsiveComponent showOnDesktop>
              <FlipCards products={products} className={"hide-mobile"} />
            </ResponsiveComponent>
          </div>
          <div className="step-three-carousel hide-desktop hide-tablet">
            <ResponsiveComponent showOnMobile>
              <CcCarousel
                name="hp-products"
                options={{
                  duration: 150000,
                }}
                breakpoints={[
                  { media: "@media (max-width: 768px)", width: 250 },
                  { media: "@media (min-width: 769px)", width: 480 },
                ]}
              >
                {products.map((itm, idx) => (
                  <div key={idx} className="slide">
                    <div>
                      <div className="card-image">
                        <img src={itm.img} alt="product1" />
                      </div>
                      <div className="card-summary">
                        <h3 className="summary-title">{itm.title}</h3>
                        <img
                          src={itm.icon}
                          className="summary-icon"
                          alt="product icon"
                        />
                        <span className="summary-discount">{itm.discount}</span>
                        <span className="summary-shop">{itm.shop}</span>
                      </div>
                    </div>
                  </div>
                ))}
              </CcCarousel>
            </ResponsiveComponent>
          </div>
          <div className="hide-tablet hide-desktop">
            <Link to="/products" className="btn outline black">
              <span className="btn-append-arrow">Comparator preturi</span>
            </Link>
          </div>
        </div>
      </div>
    </section>
  );
}

export default StepThree;
