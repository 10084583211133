import React from "react";

import placeholder from "../../assets/icons/placeholder.jpg";

import "./Image.css";

export default function Image({ src, alt }) {
  return (
    <img
      className="cc-image"
      src={src ? src : placeholder}
      onError={(e) => {
        e.target.src = placeholder;
      }}
      alt={alt}
    />
  );
}
