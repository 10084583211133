import React, { useEffect, useState } from 'react';

import './ScrollTop.css';

const ScrollTop = () => {
  const [scroll, setScroll] = useState(null);

  const handleScroll = () => {
    if (window.scrollY > 200) {
      setScroll(true);
    } else {
      setScroll(null);
    }
  };

  useEffect(() => {
    window.addEventListener('scroll', handleScroll);
    return () => window.removeEventListener('scroll', handleScroll);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <div className='cc-scroll-top-btn'>
      {scroll && <button type='button' onClick={() => window.scrollTo({ top: 0, behavior: 'smooth' })}></button>}
    </div>
  );
};

export default ScrollTop;
