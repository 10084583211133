import React from "react";

import "./TimeAvailability.css";

import clock from "../../assets/icons/wallClock.png";

export default function TimeAvailability({ text }) {
  return (
    <div className="cc-time-availability">
      <img src={clock} alt="clock icon" />
      <p>{text}</p>
    </div>
  );
}
