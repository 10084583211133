const initialState = {
  isLoading: false,
  error: false,
  shopsInfo: {
    firstPageUrl: `${process.env.REACT_APP_API_ROOT}/shops?pageNumber=1`,
    nextPageUrl: null,
    prevPageUrl: null,
  },
  shops: [],
  filterKeyword: null,
};

const shopsReducer = (state = initialState, action) => {
  const { type, payload } = action;

  switch (type) {
    case "AWAITING_SHOPS":
      return {
        ...state,
        isLoading: true,
      };

    case "ERROR_SHOPS":
      return {
        ...state,
        isLoading: false,
        error:
          payload.e && payload.e.response
            ? payload.e.response
            : payload.e
            ? payload.e
            : null,
      };

    case "SUCCESS_GET_SHOPS":
      if (
        state.filterKeyword ||
        (state.shops.length > 40 && !payload.prevPageUrl)
      ) {
        return {
          ...state,
          isLoading: false,
          shopsInfo: {
            firstPageUrl: payload.firstPageUrl,
            nextPageUrl: payload.nextPageUrl,
          },
          shops: payload.data,
          filterKeyword: null,
        };
      } else if (
        !state.filterKeyword ||
        (state.shops.length > 40 && payload.prevPageUrl)
      ) {
        return {
          ...state,
          isLoading: false,
          shopsInfo: {
            firstPageUrl: payload.firstPageUrl,
            nextPageUrl: payload.nextPageUrl,
          },
          shops: [...state.shops, ...payload.data],
          filterKeyword: null,
        };
      }

      break;

    case "SUCCESS_FILTER_SHOPS":
      if (!state.filterKeyword || state.filterKeyword !== payload.filterKey) {
        return {
          ...state,
          isLoading: false,
          shopsInfo: {
            firstPageUrl: payload.firstPageUrl,
            nextPageUrl: payload.nextPageUrl,
          },
          shops: payload.data,
          filterKeyword: payload.filterKey,
        };
      } else if (state.filterKeyword === payload.filterKey) {
        return {
          ...state,
          isLoading: false,
          shopsInfo: {
            firstPageUrl: payload.firstPageUrl,
            nextPageUrl: payload.nextPageUrl,
          },
          shops: [...state.shops, ...payload.data],
          filterKeyword: payload.filterKey,
        };
      }

      break;

    default:
      return state;
  }
};

export default shopsReducer;
