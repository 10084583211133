import React, { useState, useEffect } from 'react';
import { Helmet } from 'react-helmet';
import { useDispatch, useSelector } from 'react-redux';
import { Link } from 'react-router-dom';
import { userResetPass } from '../../store/actions/userActions';
import { LoadingSpinner } from '../../components';

import './ResetPassword.css';

const ResetPassword = (props) => {
  /* Get State */
  const { isLoading, errorResetPassword, resetPassword } = useSelector((state) => state.ccUser);

  /* Update State */
  const dispatch = useDispatch();

  const [credentials, setCredentials] = useState({
    email: '',
    password: '',
    password_confirmation: '',
    token: null,
  });

  const handleChange = (e) => {
    setCredentials({
      ...credentials,
      [e.target.name]: e.target.value,
    });
  };

  const handleSendEmail = (e) => {
    e.preventDefault();
    dispatch(userResetPass(credentials));
  };

  useEffect(() => {
    const ccPath = window.location.href;
    const ccResetCode = ccPath.substr(ccPath.lastIndexOf('/') + 1);

    if (50 < ccResetCode.length && ccResetCode.length < 70) {
      setCredentials({
        ...credentials,
        token: ccResetCode,
      });
    } else {
      props.history.push('/login');
    }

    if (errorResetPassword || resetPassword.message) {
      dispatch({
        type: 'SUCCESS_RESET_PASSWORD',
        payload: {},
      });
      dispatch({
        type: 'ERROR_RESET_PASSWORD',
        payload: {},
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <>
      <Helmet>
        <meta charSet='utf-8' />
        <title>Reset Password</title>
      </Helmet>
      <section className='cc-change-password'>
        <div className='cb-container small-padding'>
          <div className='cc-change-password-wrapper'>
            <div>
              <p>Reseteaza parola</p>
            </div>
            {isLoading ? (
              <LoadingSpinner padding={'2'} width={'15'} />
            ) : errorResetPassword ? (
              <div className='cc-error-messages'>
                <p>A aparut o eroare, reincarca pagina sau incearca mai tarziu</p>
              </div>
            ) : resetPassword.message && resetPassword.status !== 200 ? (
              <div className='cc-error-messages'>
                <p>{resetPassword.message}</p>
              </div>
            ) : resetPassword.message && resetPassword.status === 200 ? (
              <div className='cc-success-messages'>
                <p>{resetPassword.message}</p>
              </div>
            ) : null}
            <div>
              <form onSubmit={(e) => handleSendEmail(e)}>
                <input type='email' name='email' placeholder='Email' required onChange={(e) => handleChange(e)} />
                <input
                  type='password'
                  name='password'
                  placeholder='Parola'
                  required
                  onChange={(e) => handleChange(e)}
                />
                <input
                  type='password'
                  name='password_confirmation'
                  placeholder='Confirma parola'
                  required
                  onChange={(e) => handleChange(e)}
                />
                <button type='submit' value='Submit' name='submit' className='btn primary green login'>
                  Trimite
                </button>
              </form>
              <Link to='/login' className='btn primary green'>
                Catre autentificare
              </Link>
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

export default ResetPassword;
