import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Link } from "react-router-dom";
import {
  getProducts,
  filterProducts,
} from "../../../../store/actions/productsActions";
import auth from "../../../../helpers/authentication";
import {
  Search,
  MoreArticlesBtn,
  LoadingSpinner,
  PriceSort,
  ResponsiveComponent,
  ModalContent,
  Image,
  usePriceSort,
} from "../../../../components";

import img from "../../../../assets/icons/placeholder.jpg";

import "./products-listing.css";

const ProductsListing = () => {
  /* Modal */
  const [toggleModal, setToggleModal] = useState(false);

  const handleModal = (idx) => {
    if (toggleModal === false) {
      setToggleModal(idx);
      document.getElementsByTagName("html")[0].className = "menu-no-scroll";
    } else {
      setToggleModal(false);
      document.getElementsByTagName("html")[0].className = "";
    }
  };

  /* Get State */
  const { products, filterKeyword, isLoading, error } = useSelector(
    (state) => state.ccProducts
  );
  const { firstPageUrl, nextPageUrl } = useSelector(
    (state) => state.ccProducts.productsInfo
  );

  const { isAuthenticated } = useSelector((state) => state.ccAccount);

  /* Update State Data */
  const dispatch = useDispatch();

  const handleMoreProducts = (e) => {
    e.preventDefault();
    if (isLoading === false) {
      if (!filterKeyword) {
        dispatch(getProducts(nextPageUrl));
      } else if (filterKeyword) {
        dispatch(filterProducts(JSON.parse(filterKeyword), nextPageUrl));
      }
    }
  };

  useEffect(() => {
    if (error !== false) {
      dispatch({
        type: "ERROR_PRODUCTS",
        payload: {},
      });
    }
    if (
      !products.length ||
      error !== false
    ) {
      dispatch(getProducts(firstPageUrl));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  /* Price sort */
  const newProducts = usePriceSort(products);

  return (
    <section className="products-listing">
      <div className="cb-container small-padding">
        <div className="products-listing-wrapper">
          <div className="products-listing-header">
            <h1>Comparator preturi</h1>
            <p>
              Informeaza-te corect si cumpara inteligent, selectand cele mai
              bune preturi de pe piata. Iti prezentam preturile produsului pe
              care il doresti, din toate magazinele partenere.
            </p>
            <div className="products-listing-header-sort-area">
              <Search pageName={"products"} placeholder={"Cauta produse..."} />
              <PriceSort />
            </div>
          </div>

          <div className="products-listing-list">
            {!auth.isAuthenticated() && !isAuthenticated && (
              <div className="list-header hide-mobile">
                <p>Fa-ti cont si primesti cashback.</p>
                <Link to="/register">Cont nou</Link>
                <Link to="/login">Am cont deja</Link>
              </div>
            )}

            <div className="list-products">
              {isLoading ? (
                <LoadingSpinner padding={"2"} width={"10"} />
              ) : error ? (
                <div className="cc-error-messages">
                  <p>
                    A aparut o eroare, reincarca pagina sau incearca mai tarziu
                  </p>
                </div>
              ) : !products.length && filterKeyword ? (
                <div>Nu au fost gasite rezultate pentru aceasta cautare</div>
              ) : (
                newProducts.length &&
                newProducts.map((product, idx) => (
                  <div key={idx}>
                    <ResponsiveComponent hideOnTablet>
                      <>
                        <div className="list-product">
                          <div className="product-image">
                            <Image src={product.image_url} alt="product" />
                          </div>
                          <div className="product-info">
                            <h3>{product.product_name}</h3>
                            <div
                              className="product-info-text"
                              dangerouslySetInnerHTML={{
                                __html: product.product_description,
                              }}
                            />
                          </div>
                          <div className="product-price">
                            {product.old_price &&
                              product.old_price > product.price && (
                                <p className="price-cut">
                                  <strike>{product.old_price} RON</strike>
                                </p>
                              )}
                            <p className="price">{product.price} RON</p>
                            {product.commission && (
                              <p className="discount">
                                {product.commission} cashback
                              </p>
                            )}
                          </div>
                          <div className="product-shop">
                            <p>cumpara de pe</p>
                            {auth.isAuthenticated() && isAuthenticated ? (
                              <button
                                type="button"
                                className="btn product black"
                                onClick={() => handleModal(idx)}
                              >
                                {product.program_name}
                                <span>→</span>
                              </button>
                            ) : (
                              <Link to="/login" className="btn product black">
                                {product.program_name}
                                <span>→</span>
                              </Link>
                            )}
                          </div>

                          {toggleModal === idx && (
                            <ModalContent
                              product={product}
                              handleModal={() => handleModal(false)}
                            />
                          )}
                        </div>
                      </>
                    </ResponsiveComponent>

                    <ResponsiveComponent showOnTablet>
                      <div className="mobile-list-product">
                        <div className="product-image">
                          <img
                            src={product.image_url ? product.image_url : img}
                            onError={(e) => {
                              e.target.src = img;
                            }}
                            alt="product"
                          />
                        </div>
                        <div className="product-info">
                          <h3>{product.product_name}</h3>
                          <p className="price">{product.price} RON</p>
                          {product.commission && (
                            <p className="discount">
                              {product.commission} cashback
                            </p>
                          )}
                        </div>
                        <div className="product-shop">
                          {auth.isAuthenticated() && isAuthenticated ? (
                            <button
                              type="button"
                              className="btn product black"
                              onClick={() => handleModal(idx)}
                            >
                              {product.program_name}
                              <span>→</span>
                            </button>
                          ) : (
                            <Link to="/login" className="btn product black">
                              {product.program_name}
                              <span>→</span>
                            </Link>
                          )}
                        </div>
                        {toggleModal === idx && (
                          <ModalContent
                            pageName="products"
                            product={product}
                            handleModal={() => handleModal(false)}
                          />
                        )}
                      </div>
                    </ResponsiveComponent>
                  </div>
                ))
              )}

              <MoreArticlesBtn
                title={"Mai multe produse"}
                nextPageUrl={nextPageUrl}
                onClick={(e) => handleMoreProducts(e)}
                loading={isLoading}
              />
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default ProductsListing;
