const initialState = {
  isLoading: false,
  error: false,
  promosInfo: {
    firstPageUrl: `${process.env.REACT_APP_API_ROOT}/promotions?page=1`,
    nextPageUrl: null,
    prevPageUrl: null,
  },
  promos: [],
  filterKeyword: null,
};

const promosReducer = (state = initialState, action) => {
  const { type, payload } = action;

  switch (type) {
    case 'AWAITING_PROMOS':
      return {
        ...state,
        isLoading: true,
      };

    case 'ERROR_PROMOS':
      return {
        ...state,
        isLoading: false,
        error: payload.e && payload.e.response ? payload.e.response : payload.e ? payload.e : null,
      };

    case 'SUCCESS_GET_PROMOS':
      if (state.filterKeyword || (state.promos.length > 16 && !payload.prevPageUrl)) {
        return {
          ...state,
          isLoading: false,
          promosInfo: {
            firstPageUrl: payload.firstPageUrl,
            nextPageUrl: payload.nextPageUrl,
          },
          promos: payload.data,
          filterKeyword: null,
        };
      } else if (!state.filterKeyword || (state.promos.length > 16 && payload.prevPageUrl)) {
        return {
          ...state,
          isLoading: false,
          promosInfo: {
            firstPageUrl: payload.firstPageUrl,
            nextPageUrl: payload.nextPageUrl,
          },
          promos: [...state.promos, ...payload.data],
          filterKeyword: null,
        };
      }

      break;

    case 'SUCCESS_FILTER_PROMOS':
      if (!state.filterKeyword || state.filterKeyword !== payload.filterKey) {
        return {
          ...state,
          isLoading: false,
          promosInfo: {
            firstPageUrl: payload.firstPageUrl,
            nextPageUrl: payload.nextPageUrl,
          },
          promos: payload.data,
          filterKeyword: payload.filterKey,
        };
      } else if (state.filterKeyword === payload.filterKey) {
        return {
          ...state,
          isLoading: false,
          promosInfo: {
            firstPageUrl: payload.firstPageUrl,
            nextPageUrl: payload.nextPageUrl,
          },
          promos: [...state.promos, ...payload.data],
          filterKeyword: payload.filterKey,
        };
      }

      break;

    default:
      return state;
  }
};

export default promosReducer;
