const initialState = {
  isLoading: false,
  error: false,
  productsInfo: {
    firstPageUrl: `${process.env.REACT_APP_API_ROOT}/products?pageNumber=1`,
    nextPageUrl: null,
    prevPageUrl: null,
  },
  products: [],
  filterKeyword: null,
  sortBy: "<",
};

const productsReducer = (state = initialState, action) => {
  const { type, payload } = action;

  switch (type) {
    case "AWAITING_PRODUCTS":
      return {
        ...state,
        isLoading: true,
      };

    case "ERROR_PRODUCTS":
      return {
        ...state,
        isLoading: false,
        error:
          payload.e && payload.e.response
            ? payload.e.response
            : payload.e
            ? payload.e
            : null,
      };

    case "SUCCESS_GET_PRODUCTS":
      if (
        state.filterKeyword ||
        (state.products.length > 20 && !payload.prevPageUrl)
      ) {
        return {
          ...state,
          isLoading: false,
          productsInfo: {
            firstPageUrl: payload.firstPageUrl,
            nextPageUrl: payload.nextPageUrl,
          },
          products: payload.data,
          filterKeyword: null,
        };
      } else if (
        !state.filterKeyword ||
        (state.products.length > 20 && payload.prevPageUrl)
      ) {
        return {
          ...state,
          isLoading: false,
          productsInfo: {
            firstPageUrl: payload.firstPageUrl,
            nextPageUrl: payload.nextPageUrl,
          },
          products: [...state.products, ...payload.data],
          filterKeyword: null,
        };
      }

      break;

    case "SUCCESS_FILTER_PRODUCTS":
      if (!state.filterKeyword || state.filterKeyword !== payload.filterKey) {
        return {
          ...state,
          isLoading: false,
          productsInfo: {
            firstPageUrl: payload.firstPageUrl,
            nextPageUrl: payload.nextPageUrl,
          },
          products: payload.data,
          filterKeyword: payload.filterKey,
        };
      } else if (state.filterKeyword === payload.filterKey) {
        return {
          ...state,
          isLoading: false,
          productsInfo: {
            firstPageUrl: payload.firstPageUrl,
            nextPageUrl: payload.nextPageUrl,
          },
          products: [...state.products, ...payload.data],
          filterKeyword: payload.filterKey,
        };
      }

      break;

    case "SUCCESS_SORT_PRODUCTS":
      return {
        ...state,
        isLoading: false,
        sortBy: payload.sort ? payload.sort : null,
      };

    default:
      return state;
  }
};

export default productsReducer;
