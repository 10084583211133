import axios from "axios";

/* Get Categories */

export const getCategories = (props) => async (dispatch) => {
  try {
    dispatch({
      type: "AWAITING_CATEGORIES",
    });

    const response = await axios.get(`${props}`);

    const data = response.data;

    dispatch({
      type: "SUCCESS_GET_CATEGORIES",
      payload: {
        data,
      },
    });
  } catch (e) {
    dispatch({
      type: "ERROR_CATEGORIES",
      payload: {
        e,
      },
    });
  }
};

/* Get Shops */

export const getCouponsShops = (props) => async (dispatch) => {
  try {
    dispatch({
      type: "AWAITING_CATEGORIES",
    });

    const response = await axios.get(`${props}`);

    const data = response.data;

    dispatch({
      type: "SUCCESS_GET_COUPONS_SHOPS",
      payload: {
        data,
      },
    });
  } catch (e) {
    dispatch({
      type: "ERROR_CATEGORIES",
      payload: {
        e,
      },
    });
  }
};
