import React, { useEffect, useState } from 'react';
import { withRouter } from 'react-router';
import { Helmet } from 'react-helmet';
import { useDispatch, useSelector } from 'react-redux';
import { getUserInfo } from '../../../../store/actions/accountActions';
import { userEditInfo } from '../../../../store/actions/userActions';
import { LoadingSpinner } from '../../../../components';

import img from '../../../../assets/icons/placeholder-user.jpg';

import './EditAccount.css';

const EditAccount = (props) => {
  const dispatch = useDispatch();

  /* Get data */

  const { userInfo, error } = useSelector((state) => state.ccAccount);
  const { errorEditInfo, isLoading, editUserInfo } = useSelector((state) => state.ccUser);

  /* Handle data */

  const [showEdit, setShowEdit] = useState(0);

  const [changeImage, setChangeImage] = useState({
    image: null,
    first_name: '',
    last_name: '',
    prevImg: null,
    _method: '',
  });

  const [changeUserInfo, setChangeUserInfo] = useState({
    first_name: userInfo ? userInfo.first_name : '',
    last_name: userInfo ? userInfo.last_name : '',
    email: '',
  });

  const [changePassword, setChangePassword] = useState({
    password: '',
    password_confirmation: '',
  });

  const handleChangeImage = (e) => {
    setChangeImage({
      ...changeImage,
      [e.target.name]: e.target.files[0],
      first_name: userInfo.first_name,
      last_name: userInfo.last_name,
      _method: 'PUT',
      prevImg: e.target.files.length ? URL.createObjectURL(e.target.files[0]) : changeImage.prevImg,
    });
  };

  const handleChangeUserInfo = (e) => {
    setChangeUserInfo({
      ...changeUserInfo,
      [e.target.name]: e.target.value,
      email: userInfo.mail,
    });
  };

  const handleChangePassword = (e) => {
    setChangePassword({
      ...changePassword,
      [e.target.name]: e.target.value,
    });
  };

  /* Set data */

  const handleSetImage = (e) => {
    e.preventDefault();
    const bodyFormData = new FormData();
    bodyFormData.append('image', changeImage.image);
    // bodyFormData.append('first_name', changeImage.first_name);
    // bodyFormData.append('last_name', changeImage.last_name);
    // bodyFormData.append('_method', changeImage._method);

    dispatch(userEditInfo(bodyFormData, 'image'));
  };

  const handleSetUserInfo = (e) => {
    e.preventDefault();
    dispatch(userEditInfo(changeUserInfo, 'name'));
  };

  const handleSetPassword = (e) => {
    e.preventDefault();
    dispatch(userEditInfo(changePassword, 'pass'));
  };

  useEffect(() => {
    if (userInfo.length) {
      dispatch(getUserInfo(props));
    }

    if (errorEditInfo) {
      dispatch({
        type: 'ERROR_EDIT_INFO',
        payload: {},
      });
    }

    if (editUserInfo) {
      dispatch({
        type: 'SUCCESS_EDIT_INFO',
        payload: {},
      });
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <>
      <Helmet>
        <meta charSet='utf-8' />
        <title>Edit Account</title>
      </Helmet>
      <section className='cc-edit-account'>
        <div className='cb-container small-padding'>
          <div className='cc-edit-account-wrapper'>
            <div className='cc-edit-account-title'>
              <h1>Date cont</h1>
              <p>De aici poti edita datele contului tau</p>
            </div>

            {isLoading ? (
              <LoadingSpinner padding={'2'} width={'10'} />
            ) : errorEditInfo && errorEditInfo.password ? (
              <div className='cc-error-messages'>
                {errorEditInfo.password.map((message, idx) => (
                  <p key={idx}>{message}</p>
                ))}
              </div>
            ) : (errorEditInfo && !errorEditInfo.password) || error ? (
              <div className='cc-error-messages'>
                <p>A aparut o eroare, reincarca pagina sau incearca mai tarziu</p>
              </div>
            ) : (
              editUserInfo && (
                <div className='cc-success-messages'>
                  <p>Datele tale au fost modificate cu succes.</p>
                </div>
              )
            )}

            <div className='cc-edit-account-body'>
              <div className='cc-edit-user-image'>
                <form onSubmit={(e) => handleSetImage(e)}>
                  <div
                    className='edit-user-image-frame'
                    style={{
                      backgroundImage: `url(
                      ${
                        !changeImage.prevImg && userInfo.image
                          ? `${userInfo.image}`
                          : changeImage.prevImg
                          ? changeImage.prevImg
                          : img
                      }
                    )`,
                    }}
                  ></div>
                  <div className='cc-edit-account-action-group'>
                    <h2>Poza de profil</h2>
                    {showEdit !== 1 && (
                      <button
                        type='button'
                        className='cc-account-actions-btn'
                        onClick={() => {
                          setShowEdit(1);
                        }}
                      >
                        Editeaza
                      </button>
                    )}
                  </div>
                  {showEdit === 1 && (
                    <>
                      <input
                        type='file'
                        name='image'
                        accept='image/*'
                        required
                        onChange={(e) => handleChangeImage(e)}
                      />
                      <div className='cc-edit-account-action-group'>
                        <button type='submit' value='Submit' name='submit' className='btn primary green'>
                          Salveaza
                        </button>
                        <button
                          type='button'
                          className='btn outline grey'
                          onClick={() => {
                            setShowEdit(0);
                          }}
                        >
                          Anuleaza
                        </button>
                      </div>
                    </>
                  )}
                </form>
              </div>

              <div className='cc-edit-account user-separator'></div>

              <div className='cc-edit-user-info'>
                <div>
                  <div className='cc-edit-account-action-group'>
                    <h2>Informatii generale</h2>
                    {showEdit !== 2 && (
                      <button
                        type='button'
                        className='cc-account-actions-btn'
                        onClick={() => {
                          setShowEdit(2);
                        }}
                      >
                        Editeaza
                      </button>
                    )}
                  </div>
                  {showEdit !== 2 && <p>{userInfo.first_name + ' ' + userInfo.last_name}</p>}
                </div>
                {showEdit === 2 && (
                  <form onSubmit={(e) => handleSetUserInfo(e)}>
                    <div className='cc-edit-user-info-input'>
                      <input
                        type='text'
                        name='first_name'
                        placeholder={userInfo.first_name}
                        value={changeUserInfo.first_name}
                        required
                        onChange={(e) => handleChangeUserInfo(e)}
                      />
                      <input
                        type='text'
                        name='last_name'
                        placeholder={userInfo.last_name}
                        value={changeUserInfo.last_name}
                        required
                        onChange={(e) => handleChangeUserInfo(e)}
                      />
                    </div>
                    <div className='cc-edit-account-action-group'>
                      <button type='submit' value='Submit' name='submit' className='btn primary green'>
                        Salveaza
                      </button>
                      <button
                        type='button'
                        className='btn outline grey'
                        onClick={() => {
                          setShowEdit(0);
                        }}
                      >
                        Anuleaza
                      </button>
                    </div>
                  </form>
                )}
              </div>

              <div className='cc-edit-account user-separator'></div>

              <div className='cc-edit-user-password'>
                <div className='cc-edit-account-action-group'>
                  <h2>Modifica parola</h2>
                  {showEdit !== 3 && (
                    <button
                      type='button'
                      className='cc-account-actions-btn'
                      onClick={() => {
                        setShowEdit(3);
                      }}
                    >
                      Editeaza
                    </button>
                  )}
                </div>
                {showEdit === 3 && (
                  <form onSubmit={(e) => handleSetPassword(e)}>
                    <label>(Minim 8 caractere, o litera mare, o litera mica si o cifra)</label>
                    <input
                      type='password'
                      name='password'
                      placeholder='Parola noua'
                      required
                      onChange={(e) => handleChangePassword(e)}
                      style={{
                        boxShadow: `${
                          errorEditInfo && errorEditInfo.password
                            ? '0 2px 12px 0 rgb(255 0 0 / 42%)'
                            : '0 2px 12px 0 rgba(0,0,0,0.1)'
                        }`,
                      }}
                    />
                    <input
                      type='password'
                      name='password_confirmation'
                      placeholder='Confirma parola'
                      required
                      onChange={(e) => handleChangePassword(e)}
                      style={{
                        boxShadow: `${
                          errorEditInfo && errorEditInfo.password
                            ? '0 2px 12px 0 rgb(255 0 0 / 42%)'
                            : '0 2px 12px 0 rgba(0,0,0,0.1)'
                        }`,
                      }}
                    />
                    <div className='cc-edit-account-action-group'>
                      <button type='submit' value='Submit' name='submit' className='btn primary green'>
                        Salveaza
                      </button>
                      <button
                        type='button'
                        className='btn outline grey'
                        onClick={() => {
                          setShowEdit(0);
                        }}
                      >
                        Anuleaza
                      </button>
                    </div>
                  </form>
                )}
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

export default withRouter(EditAccount);
