import React from "react";

import "./SortUserHistory.css";

const SortUserHistory = (props) => {

  return (
    <div className="cc-sort-user-history hide-mobile"> 
        {
          
        props.filterShopList 
        
        ?
          
        <select
          name="user-history-sort"
          id="user-histry-sort"
          defaultValue={"DEFAULT"}
          onChange={(e) => props.handleFilter(e)}
        >
          <option value="DEFAULT" disabled>
            Sorteaza dupa magazin
          </option>
          <option value="all">Toate comisioanele</option>
          {props.filterShopList 
            ? props.filterShopList.map(
                (itm, idx) =>
                  itm.shopId &&
                  itm.shopName && (
                    <option key={idx} value={itm.shopId}>
                      {itm.shopName}
                    </option>
                  )
              )
            : null}
        </select>

      : 

        props.filterStatusList &&

        <select
          name="user-history-sort"
          // id="user-histry-sort"
          // defaultValue={"DEFAULT"}
          value={props.resetValue === "DEFAULT" ? "DEFAULT" : props.resetValue === "all" ? "all" : undefined}
          onChange={(e) => props.handleFilter(e)}
        >
          <option value="DEFAULT" disabled>
            Sorteaza dupa status
          </option>
          <option value="all">Toate statusurile</option>
          {props.filterStatusList
            ? props.filterStatusList.map(
                (itm, idx) =>
                    <option key={idx} value={[itm.status , itm.payment_status]}>
                      {

                        itm.status === "in asteptare"                   
                        ? 
                          'inregistrat'
                        : 

                        itm.status === "aprobat"                           
                        ? 
                          itm.status
                        :

                        itm.status === "platibil" && itm.payment_status !== "paid"
                        ?
                          itm.status
                        : 

                        itm.status === "platibil" && itm.payment_status === "paid" 
                        ? 
                          'platit'
                        : 

                        itm.status === "anulat" && 
                         itm.status

                        }
                    </option>
                  )
              
            : null }
        </select>
      }
    </div>
  );
};

export default SortUserHistory;



