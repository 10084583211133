import axios from 'axios';

/* Get Shops */

export const getPromos = (pageUrl) => async (dispatch) => {
  try {
    dispatch({
      type: 'AWAITING_PROMOS',
    });

    const response = await axios.get(`${pageUrl}&perPage=16`);

    const { firstPageUrl, nextPageUrl, prevPageUrl, data } = response.data;

    dispatch({
      type: 'SUCCESS_GET_PROMOS',
      payload: {
        firstPageUrl,
        nextPageUrl,
        prevPageUrl,
        data,
      },
    });
  } catch (e) {
    dispatch({
      type: 'ERROR_PROMOS',
      payload: {
        e,
      },
    });
  }
};

/* Filter Shops */

export const filterPromos = (filterData, pageUrl) => async (dispatch) => {
  try {
    dispatch({
      type: 'AWAITING_PROMOS',
    });

    const response = await axios.post(`${pageUrl}&perPage=16`, filterData);

    const { firstPageUrl, nextPageUrl, data } = response.data;
    const filterKey = response.config.data;

    dispatch({
      type: 'SUCCESS_FILTER_PROMOS',
      payload: {
        firstPageUrl,
        nextPageUrl,
        data,
        filterKey,
      },
    });
  } catch (e) {
    dispatch({
      type: 'ERROR_PROMOS',
      payload: {
        e,
      },
    });
  }
};
