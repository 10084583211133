import React, { useEffect } from "react";
import { Link } from "react-router-dom";
// import auth from '../../helpers/authentication'
import { useDispatch, useSelector } from "react-redux";
import { withRouter } from "react-router";
import { LoadingSpinner } from "../../components";
import { getUserInfo } from "../../store/actions/accountActions";
import { InviteUserReferral } from "../../components";

import img from "../../assets/icons/placeholder-user.jpg";
import "./MobileHomepage.css";

const MobileHomepage = (props) => {
  const { userInfo, error, isLoading } = useSelector(
    (state) => state.ccAccount
  );

  /* Update State */

  const dispatch = useDispatch();

  useEffect(() => {
    if (error) {
      dispatch({
        type: "ERROR_ACCOUNT",
        payload: {},
      });
    }

    dispatch(getUserInfo(props));

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <section className="cc-mobile-homepage">
      <div className=".cb-container.is-full-width no-side-padding-mobile">
        <div className="cc-mobile-homepage-wrapper">
          <div className="cc-mobile-homepage-hero">
            {isLoading ? (
              <LoadingSpinner padding={"2"} width={"25"} white />
            ) : (
              <>
                <div
                  className="cc-mhp-user-image"
                  style={{
                    backgroundImage: `url(${
                      !userInfo.image ||
                      userInfo.image === "https://api.cashclub.ro/"
                        ? img
                        : userInfo.image
                    })`,
                  }}
                ></div>
                <h3 className="cc-mhp-user-name">
                  {userInfo && userInfo.first_name + " " + userInfo.last_name}
                </h3>
                <p className="cc-mhp-user-email">
                  {userInfo && userInfo.email}
                </p>
                <div className="cc-mhp-user-redraw">
                  <span>{userInfo && userInfo.wallet} Lei </span>
                  <Link
                    to="/account/withdraw-funds"
                    className="cc-account-actions-btn disabled-link"
                    style={{
                      pointerEvents: `${userInfo.wallet >= 50 ? "" : "none"}`,
                      opacity: `${userInfo.wallet >= 50 ? "" : "0.4"}`,
                      userSelect: "none",
                    }}
                  >
                    Retragere
                  </Link>
                </div>
              </>
            )}
            {error && (
              <div className="cc-error-messages">
                <p>
                  A aparut o eroare, reincarca pagina sau incearca mai tarziu
                </p>
              </div>
            )}
          </div>
          <InviteUserReferral userReferralCode={userInfo.code} />
          <div className="cc-mobile-homepage-menu">
            <Link to="/account">Istoric</Link>
            <Link to="/cashback">Cashback</Link>
            <Link to="/products">Comparator</Link>
            <Link to="/shops">Magazine</Link>
            <Link to="/coupons">Cupoane reducere</Link>
            {/* <Link to="/promos">Promotii</Link> */}

            {/* <button onClick={handleLogOut}>Log out</button> */}
          </div>
        </div>
      </div>
    </section>
  );
};

export default withRouter(MobileHomepage);
