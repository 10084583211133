
import React, { useEffect, useMemo } from "react";
import styles from "./CcCarousel.module.css";

function CcCarousel({ name, options, breakpoints, children }) {
  useEffect(() => {
    const exists = document.head.querySelector(`[data-cc-carousel="${name}"]`);
    if (exists) exists.remove();

    const cssMap = breakpoints.map(
      (bk) => `
      ${bk.media} {
      .cc-carousel-slide-${name} {
        flex: 0 0 ${bk.width}px;
      }

      @keyframes moveAnim-${name} {
        0% { transform: translate3d(0, 0, 0); }
        100% {
          transform: translate3d(-${
            bk.width * React.Children.count(children)
          }px, 0, 0);
        }
      }
    }`
    );

    const style = document.createElement("style");
    style.setAttribute("data-cc-carousel", name);
    style.appendChild(document.createTextNode(cssMap.join(" ")));
    document.head.appendChild(style);
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [name, options.slideWidth, breakpoints]);

  const slides = useMemo(() => {
    const items = React.Children.map(children, (item, idx) => {
      return {
        id: idx + 1,
        index: idx,
        content: item,
      };
    });

    return [...items, ...items];
  }, [children]);

  return (
    <div className={styles.carousel}>
      <div
        className={styles.slides}
        style={{
          animation: `moveAnim-${name} linear infinite`,
          animationDuration: `${options.duration}ms`,
        }}
      >
        {slides.map((slide, idx) => (
          <div key={idx} className={`${styles.slide} cc-carousel-slide-${name}`}>
            {slide.content}
          </div>
        ))}
      </div>
    </div>
  );
}

CcCarousel.defaultProps = {
  name: 'default'
}

export default CcCarousel;
