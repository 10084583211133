import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { isIOS } from "react-device-detect";
import { useSelector, useDispatch } from "react-redux";
import { getAffiliateLink } from "../../../store/actions/affiliateAction";
import { LoadingSpinner } from "../../";
import WarningModal from "../warning-modal/WarningModal";
import WarningModalSettings from "../warning-modal-settings/WarningModalSettings";

import "./ModalContent.css";

const ModalContent = (props) => {
  const userName = useSelector((state) =>
    state.ccAccount.userInfo ? state.ccAccount.userInfo.first_name : null
  );
  const { isLoading, affiliateError, affiliateSuccess } = useSelector(
    (state) => state.ccAffiliate
  );
  const dispatch = useDispatch();

  const affiliateRoute = props.product
    ? `/shops/${props.product.program_id}/products/${props.product._id}/link`
    : props.promo
    ? `/shops/${props.promo.shopId}/promotions/${props.promo.promotionId}/link`
    : props.shop &&
      `/affiliates/${props.shop.affiliateId}/shops/${props.shop.id}/link`;

  const cbValue = props.product
    ? props.product.commission
    : props.promo
    ? props.promo.cashbackValue
    : props.shop && props.shop.cashbackValue;

  const shopName = props.product
    ? props.product.program_name
    : props.promo
    ? props.promo.programName
    : props.shop && props.shop.name;

  useEffect(() => {
    dispatch(getAffiliateLink(affiliateRoute));

    if (affiliateError || affiliateSuccess) {
      dispatch({
        type: "SUCCESS_AFFILIATE",
        payload: {},
      });
      dispatch({
        type: "ERROR_AFFILIATE",
        payload: {},
      });
    }

    const isIos107 = localStorage.getItem("ccQuery");
    const iOSSettings = localStorage.getItem("cc_iOS_Settings");

    isIOS && !isIos107 && handleModal("noSettings");
    isIOS && isIos107 && !iOSSettings && handleModal("withSettings");
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const [toggleModal, setToggleModal] = useState(false);

  const handleModal = (variant) => {
    if (typeof variant === "string" && toggleModal !== variant) {
      setToggleModal(variant);
    } else {
      setToggleModal(false);
    }
  };

  const handleCopy = () => {
    var copyText = document.getElementById("cc-user-refferal");
    copyText.select();
    copyText.setSelectionRange(0, 99999);
    document.execCommand("copy");
  };

  const localPath =
    "https://cashclub.ro/shop-redirect?cashclub_pending_redirect=";

  return (
    <div className="cc-modal-content">
      <span
        onClick={props.handleModal}
        className="cc-modal-content-close hide-mobile"
      ></span>
      <div className="cc-modal-content-body">
        <span
          onClick={props.handleModal}
          className="cc-modal-content-close hide-tablet hide-desktop"
        ></span>
        <h1>CashClub</h1>

        {/* {isIOS && (
          <div className="safari-warning-message">
            <img src={img} alt="info-icon" />
            <p>
              Pentru a te asigura ca vei primi bonusul aferent achizitiei tale,
              te rugam daca folosești Safari sa mergi in Settings si sa te
              asiguri ca ai oprit:{" "}
            </p>
            <p>a) Prevent cross-site tracking</p>
            <p> b) Block all cookies</p>
          </div>
        )} */}

        <h2>
          {cbValue} cashback pe {shopName}
        </h2>
        <p>
          Felicitari {userName && userName}! Vei primi {cbValue} bonus pentru
          cumparaturile in magazinul {shopName}. Spor la cumparaturi!
        </p>

        {isLoading === true && !affiliateError ? (
          <LoadingSpinner padding={"2"} width={"10"} />
        ) : isLoading === false && affiliateError && !affiliateSuccess ? (
          <div className="cc-error-messages">
            <p>A aparut o eroare, reincarca pagina sau incearca mai tarziu</p>
          </div>
        ) : (
          isLoading === false &&
          affiliateError &&
          affiliateError.message &&
          !affiliateSuccess && (
            <div className="cc-error-messages">
              <p>{affiliateError.message}</p>
            </div>
          )
        )}

        <a
          href={affiliateSuccess}
          onClick={props.handleModal}
          className="btn primary green"
          target="_blank"
          rel="noopener noreferrer"
        >
          Accept Termenii si Conditiile
        </a>
        <p>
          Trimite linkul de mai jos unui prieten iar tu vei primi comisionul.
        </p>
        <div className="affiliate-link-copy">
          <input
            readOnly
            type="text"
            value={affiliateSuccess?.replace("https://", localPath) || ""}
            id="cc-user-refferal"
          />
          <button type="button" onClick={handleCopy}>
            Copiaza
          </button>
        </div>
        <Link to="/terms-and-conditions" className="btn grey">
          Termeni si Conditii
        </Link>
      </div>

      {toggleModal === "noSettings" && (
        <WarningModal handleModal={handleModal} />
      )}
      {toggleModal === "withSettings" && (
        <WarningModalSettings handleModal={handleModal} />
      )}
    </div>
  );
};

export default ModalContent;
