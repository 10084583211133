import React from "react";
import {
  TimeAvailability,
  CouponCopy,
  Image,
  // DeviceDetect,
} from "../../../components";
// import ResponsiveComponent from "../../responsive-component/ResponsiveComponent";

import "./CouponModal.css";

const CouponModal = (props) => {
  const { data, handleModal } = props;

  return (
    <div className="cc-coupon-modal">
      <span
        onClick={handleModal}
        className="cc-coupon-modal-close hide-mobile"
      ></span>

      <div className="cc-coupon-modal-body">
        <span
          onClick={props.handleModal}
          className="cc-coupon-modal-close hide-tablet hide-desktop"
        ></span>

        <div className="cc-body-content">
          <div className="content-image">
            <Image src={data.shopLogo} alt="Shop Logo" />
          </div>

          <div className="content-elements">
            {data.title && <h2>{data.title}</h2>}

            {data.endDate && (
              <TimeAvailability text={`Valabil pana la ${data.endDate}`} />
            )}

            {data.code && (
              <div className="cc-coupone-copy-spacing">
                <CouponCopy data={data.code} />
              </div>
            )}

            {data.aff_link && (
              <div className="cc-coupone-affiliate">
                <a
                  href={data.aff_link}
                  target="_blank"
                  rel="noopener noreferrer"
                  className="btn primary black mobile-full-width"
                >
                  Obtine reducerea {data.shopName}
                </a>
              </div>
            )}
          </div>
        </div>
        {/* <ResponsiveComponent showOnDesktop showOnTablet>
          <div className="cc-body-footer">
            <div className="cc-body-footer-text">
              <h3>Doriti sa beneficiati de ofertele oferite de CashClub?</h3>
              <p>
                Instaleaza extensia CashClub si beneficiaza de cashback la toate
                magazinele partenere
              </p>
            </div>
            <div className="cc-body-footer-link">
              <a
                href="https://chrome.google.com/webstore/detail/cashclub/bmcjeckemmdkoapcmpmffabkelgknlme?hl=ro&fbclid=IwAR1eIJFJ9Sh4XHeyDL1FkrQFIdTZKB35DO4NHmfDGxYjrwcS_aJZCCjOTy0"
                target="_blank"
                rel="noopener noreferrer"
                className="btn primary green"
              >
                Descarca extensia
              </a>
            </div>
          </div>
        </ResponsiveComponent>
        <ResponsiveComponent showOnMobile hideOnTablet>
          <div className="cc-body-footer">
            <div className="cc-body-footer-text">
              <h3>Doriti sa beneficiati de ofertele oferite de CashClub?</h3>
              <p>
                Instaleaza aplicatia CashClub si beneciaza de cashback oricand
                si oriunde
              </p>
            </div>
            <div className="cc-body-footer-link">
              <a
                href={DeviceDetect()}
                target="_blank"
                rel="noopener noreferrer"
                className="btn primary green mobile-full-width"
              >
                Spre aplicatie
              </a>
            </div>
          </div>
        </ResponsiveComponent> */}
      </div>
      <div className="cc-coupon-modal-underlay" onClick={handleModal}></div>
    </div>
  );
};

export default CouponModal;
