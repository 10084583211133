import {useEffect} from 'react'
import {useDispatch} from 'react-redux'
import {RegisterEmailConfirmation} from '../../../store/actions/userActions'

const AccountConfirmation = (props) => {

  const dispatch = useDispatch()

  useEffect(() => {

    const ccPath = window.location.href
    const ccConfirmCode = ccPath.substr(ccPath.lastIndexOf('/') + 1);

    if( 35 < ccConfirmCode.length  &&  ccConfirmCode.length < 45 ){
      dispatch(RegisterEmailConfirmation(props, ccConfirmCode))
    } else {
      props.history.push("/login")
    }

  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  return(null)
}

export default AccountConfirmation