import React from "react";
import { Link } from "react-router-dom";
import { ResponsiveComponent } from "..";

import "./InactiveCardOverlay.css";

export default function IncativeCardOverlay({ shopName, children }) {
  return (
    <>
      <ResponsiveComponent showOnDesktop>
        <div className="cc-incative-card">
          {children}
          <div className="cc-incative-card-overlay">
            <div className="card-overlay"></div>
            <div className="card-overlay-content">
              <h3>{shopName}</h3>
              <p>Fa-ti cont si primesti cashback</p>
              <Link className="cb-promo-btn" to="/register">
                Vreau cont
              </Link>
              <Link to="/login">Am cont deja</Link>
            </div>
          </div>
        </div>
      </ResponsiveComponent>
      <ResponsiveComponent showOnMobile>
        <Link to="/login">{children}</Link>
      </ResponsiveComponent>
    </>
  );
}
