import React from "react";
import AnchorLink from "react-anchor-link-smooth-scroll";
import { useSelector } from "react-redux";
import { Link } from "react-router-dom";
import { Image, CopyToClipboard } from "../../../../../components";
import auth from "../../../../../helpers/authentication";

import "./ShopViewAffiliate.css";

export default function ShopViewAffiliate({ data }) {
  /* Is authenticated */
  const { isAuthenticated } = useSelector((state) => state.ccAccount);
  const isActive = auth.isAuthenticated() && isAuthenticated;

  const localPath =
    "https://cashclub.ro/shop-redirect?cashclub_pending_redirect=";

  return (
    <div className="shop-view-affiliate-wrapper">
      <div className="shop-view-image">
        <Image src={data.logo} alt="Shop Logo" />
      </div>

      <div className="shop-view-discount">
        <p>
          <span>{data.discount ? data.discount : null}</span>cashback
        </p>
      </div>

      <div className="shop-view-button-group">
        {data.aff_link && isActive ? (
          <a
            href={data.aff_link}
            className="btn primary green "
            target="_blank"
            rel="noopener noreferrer"
          >
            Mergi catre magazin
          </a>
        ) : (
          <Link to="/login" className="btn primary green">
            Mergi catre magazin
          </Link>
        )}

        {data.aff_link && isActive ? (
          <div>
            <CopyToClipboard
              title="Trimite link-ul unui prieten si primesti tu bonusul"
              data={data?.aff_link?.replace("https://", localPath)}
            />
          </div>
        ) : null}

        {data.description && (
          <AnchorLink href="#cc-shop-aboute" className="btn grey">
            Termenii si conditiile magazinului
          </AnchorLink>
        )}
        {data.cashback_conditions && (
          <AnchorLink href="#cc-shop-conditions" className="btn grey">
            Conditii cashback
          </AnchorLink>
        )}
      </div>
    </div>
  );
}
