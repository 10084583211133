import React from "react";
import { Helmet } from "react-helmet";

import CbToggleText from "./components/cb-toggle-text/CbToggletext";
import CbSideImage from "./components/cb-side-image/CbSideImage";
import CbBenefits from "./components/cb-benefits/CbBenefits";
// import StepOne from './components/step-one/StepOne';
import StepTwo from "./components/step-two/StepTwo";
import StepThree from "./components/step-three/StepThree";
import { QuestionsBtn } from "../../components";

const Cashback = () => {
  return (
    <>
      <Helmet>
        <meta charSet="utf-8" />
        <title>CashBack</title>
      </Helmet>
      <CbSideImage />
      <CbBenefits />
      {/* <StepOne /> */}
      <StepTwo />
      <StepThree />
      <CbToggleText />
      <QuestionsBtn />
    </>
  );
};

export default Cashback;
