import React from 'react'
import {Link} from 'react-router-dom'
import './VerticalNav.css'

function HorizontalNav(props) {
  return (
    <nav className="vertical-nav">
      <ul>
        {
        props.links
        ?
          <>
            {props.links.map((item, idx) => (
              <li key={idx}>
                <Link
                  to={{
                    pathname: item.link,
                    question: item.question
                  }}
                  onClick={props.handleClick}>{item.name}</Link>
              </li>
            ))}
          </>
        :
          <li>
            No links data
          </li>
        }
      </ul>
    </nav>
  )
}

export default HorizontalNav