import React from "react";
import { useDispatch, useSelector } from "react-redux";

import "./PriceSort.css";

const PriceSort = () => {
  const dispatch = useDispatch();

  const handleChange = (e) => {
    const sort = e.target.value;

    dispatch({
      type: "SUCCESS_SORT_PRODUCTS",
      payload: {
        sort,
      },
    });
  };

  return (
    <div className="cc-price-sort hide-mobile">
      <select
        name="price-sort"
        id="price-sort"
        defaultValue={"DEFAULT"}
        onChange={(e) => handleChange(e)}
      >
        <option value="DEFAULT" disabled>
          Sortare dupa pret
        </option>
        <option value="<">Pret crescator</option>
        <option value=">">Pret descrescator</option>
      </select>
    </div>
  );
};

export default PriceSort;

export function usePriceSort(data) {
  const { sortBy } = useSelector((state) => state.ccProducts);
  let newProducts;
  if (sortBy) {
    newProducts =
      data && sortBy && sortBy === "<"
        ? data.sort((a, b) => parseFloat(a.price) - parseFloat(b.price))
        : data && sortBy && sortBy === ">"
        ? data.sort((a, b) => parseFloat(b.price) - parseFloat(a.price))
        : data;
  }
  return newProducts;
}
