import React from 'react';

import { Helmet } from 'react-helmet';
import FaHero from './components/fa-hero/FaHero';
import FaToggleText from './components/fa-toggle-text/FaToggleText';

const Faq = (props) => {
  return (
    <>
      <Helmet>
        <meta charSet='utf-8' />
        <title>Frequent Questions</title>
      </Helmet>
      <FaHero />
      <FaToggleText props={props} />
    </>
  );
};

export default Faq;
