import React, { useEffect, useRef, useState } from "react";

import iconUp from "../../../../assets/icons/chevron-up.svg";
import iconDown from "../../../../assets/icons/chevron-down.svg";

import "./ShopInfo.css";

export default function ShopInfo({ description, conditions }) {
  const [toggleContent, setToggleContent] = useState(null);
  const [contentHeight, setContentHeight] = useState(null);
  const [contentHeight2, setContentHeight2] = useState(null);
  const ref1 = useRef(null);
  const ref2 = useRef(null);

  const hndleMoreContent = (contentId) => {
    contentId === toggleContent
      ? setToggleContent(null)
      : setToggleContent(contentId);
  };

  const height = 113;

  useEffect(() => {
    setContentHeight(ref1.current?.clientHeight);
    setContentHeight2(ref2.current?.clientHeight);
  }, []);

  return (
    <section className="cc-read-more-text">
      <div className="cb-container small-padding">
        {description && (
          <div id="cc-shop-aboute" className="read-more-item">
            <h3 className="read-more-title">Despre magazin</h3>
            <div
              className={`read-more-top ${
                contentHeight >= height && toggleContent !== 1 ? "hide" : "show"
              }`}
              ref={ref1}
              dangerouslySetInnerHTML={{ __html: description }}
            />
            {contentHeight >= height && (
              <button
                className="read-more-button"
                onClick={() => hndleMoreContent(1)}
              >
                {toggleContent === 1 ? "Mai putin" : "Mai mult"}{" "}
                <img src={toggleContent === 1 ? iconUp : iconDown} alt="icon" />
              </button>
            )}
          </div>
        )}

        {conditions && (
          <div id="cc-shop-conditions" className="read-more-item">
            <h3 className="read-more-title">Conditii cashback</h3>
            <div
              className={`read-more-top ${
                contentHeight2 >= height && toggleContent !== 2
                  ? "hide"
                  : "show"
              }`}
              ref={ref2}
              dangerouslySetInnerHTML={{ __html: conditions }}
            ></div>
            {contentHeight2 >= height && (
              <button
                className="read-more-button"
                onClick={() => hndleMoreContent(2)}
              >
                {toggleContent === 2 ? "Mai putin" : "Mai mult"}{" "}
                <img src={toggleContent === 2 ? iconUp : iconDown} alt="icon" />
              </button>
            )}
          </div>
        )}
      </div>
    </section>
  );
}
