import React, { useState, useEffect } from 'react';
import { Helmet } from 'react-helmet';
import { useDispatch, useSelector } from 'react-redux';
import { Link } from 'react-router-dom';
import { userForgottenPass } from '../../store/actions/userActions';
import { LoadingSpinner } from '../../components';

import './ForgottenPassword.css';

const ForgottenPassword = () => {
  /* Get State */
  const { isLoading, errorFrgottenPassword } = useSelector((state) => state.ccUser);
  const { errors } = useSelector((state) => state.ccUser.forgottenPassword);

  /* Update State */
  const dispatch = useDispatch();

  const [credentials, setCredentials] = useState({
    email: '',
  });

  const handleChange = (e) => {
    setCredentials({
      ...credentials,
      [e.target.name]: e.target.value,
    });
  };

  const handleSendEmail = (e) => {
    e.preventDefault();
    dispatch(userForgottenPass(credentials));
  };

  useEffect(() => {
    if (errors || errorFrgottenPassword) {
      dispatch({
        type: 'SUCCESS_FORGOTTEN_PASSWORD',
        payload: {},
      });
      dispatch({
        type: 'ERROR_FORGOTTEN_PASSWORD',
        payload: {},
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <>
      <Helmet>
        <meta charSet='utf-8' />
        <title>Forgotten Password</title>
      </Helmet>
      <section className='cc-forgotten-password'>
        <div className='cb-container small-padding'>
          <div className='cc-forgotten-password-wrapper'>
            <div>
              <p>Trimite email de resetare a parolei la adresa contului pentru care ai uitat parola</p>
            </div>
            {isLoading ? (
              <LoadingSpinner padding={'2'} width={'15'} />
            ) : errors ? (
              <div className='cc-error-messages'>
                <p>{errors}</p>
              </div>
            ) : (
              errorFrgottenPassword && (
                <div className='cc-error-messages'>
                  <p>A aparut o eroare, reincarca pagina sau incearca mai tarziu</p>
                </div>
              )
            )}
            <div>
              <form onSubmit={(e) => handleSendEmail(e)}>
                <input
                  type='email'
                  name='email'
                  value={credentials.email}
                  placeholder='Email'
                  required
                  onChange={(e) => handleChange(e)}
                />
                <button type='submit' value='Submit' name='submit' className='btn primary green login'>
                  Trimite
                </button>
              </form>
              <Link to='/login' className='btn outline black'>
                Catre autentificare
              </Link>
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

export default ForgottenPassword;
