import React, { useState } from "react";
import AnchorLink from "react-anchor-link-smooth-scroll";
// import { DeviceDetect } from "../../../../components";

import img1 from "../../../../assets/images/cashback/video.png";

import "./CbSideImage.css";

function CbSideImage() {
  const [toggleModal, setToggleModal] = useState(false);

  const handleClick = () => {
    if (toggleModal === false) {
      setToggleModal(true);
      document.getElementsByTagName("html")[0].className = "menu-no-scroll";
    } else {
      setToggleModal(false);
      document.getElementsByTagName("html")[0].className = "";
    }
  };

  return (
    <section className="cb-side-image">
      <div className="cb-container small-padding">
        <div className="cb-side-image-wrapper">
          <div className="cb-side-image-content">
            <h1>Ce este cashback?</h1>
            <p>
              Cashback este despre bucuria de a cumpara online, economisind
              bani! Practic, ai acces la preturi mai bune decat in magazin, iar
              pentru fiecare tranzactie realizata, in contul tau se strange
              GRATIS o suma de bani. Astfel, intre 1 si 30% din pretul
              produselor si serviciilor dorite, iti revine tie!
            </p>
            <div className="hide-mobile">
              <AnchorLink href="#cc-cb-extension" className="btn outline black">
                Afla mai multe
              </AnchorLink>
            </div>
          </div>

          <div className="cb-side-image-video" onClick={handleClick}>
            <img src={img1} alt="Get app" />
            {toggleModal === true && (
              <div className="cb-side-image-video-modal">
                <span
                  onClick={handleClick}
                  className="video-modal-close"
                ></span>
                <iframe
                  title="video"
                  src="https://www.youtube.com/embed/v1zSODLjaxg?&autoplay=1"
                  frameBorder="0"
                  allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture"
                  allowFullScreen=""
                ></iframe>
              </div>
            )}
          </div>

          {/* <div className="hide-desktop hide-tablet">
            <a
              href={DeviceDetect()}
              className="btn primary green mobile-full-width"
              target="_blank"
              rel="noopener noreferrer"
            >
              Descarca Aplicatia
            </a>
          </div> */}
        </div>
      </div>
    </section>
  );
}

export default CbSideImage;
