import auth from "../../helpers/authentication";
import { apiGet, apiPost, apiPut } from "../../helpers/api";
import { getUserInfo } from "./accountActions";

/* Register */

export const userRegister =
  (props, credentials, refCode) => async (dispatch) => {
    try {
      dispatch({
        type: "AWAITING_USER",
      });

      const { data } = await apiPost(`/register?${refCode}`, credentials);
      const { errors, email } = data;

      dispatch({
        type: "SUCCESS_USER_REGISTER",
        payload: {
          errors,
          email,
        },
      });

      if (email) {
        props.history.push("/thank-you");
      }
    } catch (e) {
      dispatch({
        type: "ERROR_USER_REGISTER",
        payload: {
          e,
        },
      });
    }
  };

/* Account Confirmation */

export const RegisterEmailConfirmation =
  (props, ccConfirmCode) => async (dispatch) => {
    try {
      dispatch({
        type: "AWAITING_USER",
      });

      await apiPost(`/verify/${ccConfirmCode}`);

      dispatch({
        type: "SUCCESS_ACC_CONFIRMATION",
        payload: {},
      });

      props.history.push("/login");
    } catch (e) {
      dispatch({
        type: "ERROR_ACC_CONFIRMATION",
        payload: {
          e,
        },
      });

      props.history.push("/login");
    }
  };

/* Login */

export const userLogin = (props, credentials) => async (dispatch) => {
  try {
    dispatch({
      type: "AWAITING_USER",
    });

    const { data } = await apiPost("/login", credentials);
    const { access_token } = data;

    dispatch({
      type: "SUCCESS_USER_LOGIN",
      payload: {
        data,
      },
    });

    if (access_token) {
      auth.login(access_token, () => props.history.push("/"));
    }
  } catch (e) {
    dispatch({
      type: "ERROR_USER_LOGIN",
      payload: {
        e,
      },
    });
  }
};

/* Facebook Login */

export const userFacebookLogin =
  (props, accessToken, permissions) => async (dispatch) => {
    try {
      if (!accessToken || !permissions) {
        return;
      }

      const deniedPermission = permissions?.find(
        (permission) => permission.status === "declined"
      );

      if (deniedPermission) {
        dispatch({
          type: "ERROR_USER_LOGIN",
          payload: {
            e: "deniedPermission",
          },
        });
        return;
      }

      dispatch({
        type: "AWAITING_USER",
      });

      const { data } = await apiGet(
        `/auth/facebook/callback?token=${accessToken}`
      );

      const { access_token } = data;

      dispatch({
        type: "SUCCESS_USER_LOGIN",
        payload: {
          data,
        },
      });

      auth.login(access_token, () => props.history.push("/"));
    } catch (e) {
      dispatch({
        type: "ERROR_USER_LOGIN",
        payload: {
          e,
        },
      });
    }
  };

export const userFacebookLoginApp =
  (props, fbApiResponse) => async (dispatch) => {
    try {
      if (!fbApiResponse?.accessToken || fbApiResponse?.isCancelled) {
        return;
      }

      if (fbApiResponse?.declinedPermissions?.length) {
        dispatch({
          type: "ERROR_USER_LOGIN",
          payload: {
            e: "deniedPermission",
          },
        });
        return;
      }

      dispatch({
        type: "AWAITING_USER",
      });

      const { data } = await apiGet(
        `/auth/facebook/callback?token=${fbApiResponse?.accessToken}`
      );

      const { access_token } = data;

      dispatch({
        type: "SUCCESS_USER_LOGIN",
        payload: {
          data,
        },
      });

      auth.login(access_token, () => props.history.push("/"));
    } catch (e) {
      dispatch({
        type: "ERROR_USER_LOGIN",
        payload: {
          e,
        },
      });
    }
  };

/* Apple Login not finished*/

// export const userAppleLogin = (code) => async (dispatch) => {
//   try {
//     dispatch({
//       type: "AWAITING_USER",
//     });

//     const params = {
//       client_id: "ro.cashclub.SignInApple-Cashclub",
//       client_secret:
//         "eyJraWQiOiIzRDhQUTc3WDhTIiwiYWxnIjoiRVMyNTYifQ.eyJpc3MiOiIyUzNQNTJVTjhRIiwiaWF0IjoxNjAzNDgzNTA4LCJleHAiOjE2MTkwMzU1MDgsImF1ZCI6Imh0dHBzOi8vYXBwbGVpZC5hcHBsZS5jb20iLCJzdWIiOiJyby5jYXNoY2x1Yi5TaWduSW5BcHBsZS1DYXNoY2x1YiJ9._NhQJ1o1JbmVuwhB3yRLflL4f5spGMmbprxHERgXplL_XpOJyhuYIdnUeOutIt-JcqTBoxk8iEhlZ6DWdp9o3w",
//       code: code,
//       redirect_uri: "https://cashclub.ro/login/apple-login",
//       grant_type: "authorization_code",
//     };

//     const res = axios
//       .post("https://appleid.apple.com/auth/token", null, {
//         params,
//       })
//       .then((response) => {
//         return axios.get(
//           `https://api.cashclub.ro/api/auth/apple/callback?token=${response.data.id_token}`
//         );
//       });

//   } catch (e) {
//     dispatch({
//       type: "ERROR_USER_LOGIN",
//       payload: {
//         e,
//       },
//     });
//   }
// };

/* Logout */

export const userLogout = () => async (dispatch) => {
  try {
    dispatch({
      type: "AWAITING_USER",
    });

    const { data } = await apiPost("/logout");

    dispatch({
      type: "SUCCESS_USER_LOGOUT",
      payload: {
        data,
      },
    });

    window.FB.getLoginStatus(function (response) {
      if (response?.status === "connected") {
        window.FB.logout();
      }
    });
  } catch (e) {
    dispatch({
      type: "ERROR_USER_LOGOUT",
      payload: {
        e,
      },
    });
  }
};

/* Forgot Password */

export const userForgottenPass = (credentials) => async (dispatch) => {
  try {
    dispatch({
      type: "AWAITING_USER",
    });

    const response = await apiPost("/forgot-password", credentials);
    const { message } = response.data;

    dispatch({
      type: "SUCCESS_FORGOTTEN_PASSWORD",
      payload: {
        message,
      },
    });
  } catch (e) {
    dispatch({
      type: "ERROR_FORGOTTEN_PASSWORD",
      payload: {
        e,
      },
    });
  }
};

/* Reset Password */

export const userResetPass = (credentials) => async (dispatch) => {
  try {
    dispatch({
      type: "AWAITING_USER",
    });

    const response = await apiPost("/reset-password", null, {
      params: {
        ...credentials,
      },
    });

    const { message, status } = response.data;

    dispatch({
      type: "SUCCESS_RESET_PASSWORD",
      payload: {
        message,
        status,
      },
    });
  } catch (e) {
    dispatch({
      type: "ERROR_RESET_PASSWORD",
      payload: {
        e,
      },
    });
  }
};

/* Edit Account User Info */

export const userEditInfo = (body, type) => async (dispatch) => {
  try {
    dispatch({
      type: "AWAITING_USER",
    });

    let method = "";
    let endpoint = "";

    switch (type) {
      case "image":
        endpoint = "/accounts/avatar";
        method = apiPost;
        break;
      case "name":
        endpoint = "/accounts";
        method = apiPut;
        break;
      case "pass":
        endpoint = "/accounts/password-reset";
        method = apiPut;
        break;
      default:
        break;
    }

    if (!method || !endpoint) return;

    const { data } = await method(endpoint, body);
    const { email } = data.data || data;

    dispatch({
      type: "ERROR_EDIT_INFO",
      payload: {},
    });

    dispatch({
      type: "SUCCESS_EDIT_INFO",
      payload: {
        email,
      },
    });

    if (type === "name") {
      dispatch(getUserInfo());
    }
  } catch (e) {
    dispatch({
      type: "ERROR_EDIT_INFO",
      payload: {
        e,
      },
    });
  }
};

/* Contact Form */

export const userContact = (contact) => async (dispatch) => {
  try {
    dispatch({
      type: "AWAITING_USER",
    });

    const response = await apiPost("/submit-contact-form", contact);

    const { data } = response;

    dispatch({
      type: "SUCCESS_CONTACT",
      payload: {
        data,
      },
    });
  } catch (e) {
    dispatch({
      type: "ERROR_CONTACT",
      payload: {
        e,
      },
    });
  }
};

/* Withdraw Funds */

export const userWithdrawFunds = (credentials) => async (dispatch) => {
  try {
    dispatch({
      type: "AWAITING_USER",
    });

    const { data } = await apiPost("/accounts/retrieve-cashback", credentials);

    dispatch({
      type: "SUCCESS_WITHDRAW",
      payload: {
        data,
      },
    });
  } catch (e) {
    dispatch({
      type: "ERROR_WITHDRAW",
      payload: {
        e,
      },
    });
  }
};

/* Newsletter */

export const userNewsletter = (newsletter) => async (dispatch) => {
  try {
    dispatch({
      type: "AWAITING_USER_NEWSLETTER",
    });

    const { data, status } = await apiPost(`/newsletter`, newsletter);

    dispatch({
      type: "SUCCESS_NEWSLETTER",
      payload: {
        data,
        status,
      },
    });
  } catch (e) {
    dispatch({
      type: "ERROR_NEWSLETTER",
      payload: {
        e,
      },
    });
  }
};

/* Send SMS */

export const SendSMS = (phoneNr) => async (dispatch) => {
  try {
    dispatch({
      type: "AWAITING_USER",
    });

    const { data } = await apiPost(`/sms?phone=0${phoneNr.phone}`);

    dispatch({
      type: "SUCCESS_SMS",
      payload: {
        data,
      },
    });
  } catch (e) {
    dispatch({
      type: "ERROR_SMS",
      payload: {
        e,
      },
    });
  }
};
