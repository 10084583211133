import { apiGet } from "../../helpers/api";

/* Get Shops */

export const getShop = (props, pageUrl) => async (dispatch) => {
  try {
    dispatch({
      type: "AWAITING_SHOP",
    });

    const { data } = await apiGet(pageUrl);

    dispatch({
      type: "SUCCESS_GET_SHOP",
      payload: data,
    });
  } catch (e) {
    if (e.response.status === 401 && e.response.data.message === "Shop not found") {
      props.history.push("/404");
      return;
    }

    dispatch({
      type: "ERROR_SHOP",
      payload: {
        e,
      },
    });
  }
};
