import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { getShop } from "../../../store/actions/shopActions";
import { Helmet } from "react-helmet";
import ShopView from "./shop-view/ShopView";
import ShopRelated from "./shop-related/ShopRelated";
import ShopInfo from "./shop-info/ShopInfo";
import { LoadingSpinner } from "../../../components";

function ShopPage(props) {
  const pageURL = props.location.pathname;

  /* Get State */
  const { error, isLoading, shopInfo } = useSelector((state) => state.ccShop);

  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(getShop(props, pageURL));

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props.location.pathname]);

  return (
    <>
      <Helmet>
        <meta charSet="utf-8" />
        <title>Shop</title>
      </Helmet>

      {isLoading ? (
        <LoadingSpinner padding={"2"} width={"10"} />
      ) : error ? (
        <div className="cc-error-messages">
          <p>A aparut o eroare, reincarca pagina sau incearca mai tarziu</p>
        </div>
      ) : (
        shopInfo && (
          <>
            <ShopView data={shopInfo} />

            {shopInfo.recommendations && (
              <ShopRelated data={shopInfo.recommendations} />
            )}

            {(shopInfo.description || shopInfo.cashback_conditions) && (
              <ShopInfo
                description={shopInfo.description}
                conditions={shopInfo.cashback_conditions}
              />
            )}
          </>
        )
      )}
    </>
  );
}

export default ShopPage;
