import React from "react";
import { Carousel } from "../../../../components";
import ShopsCard from "../../shops-card/ShopsCard";
import { Link } from "react-router-dom";

import "./ShopRelated.css";

export default function ShopRelated({ data }) {
  return (
    <section className="cc-shop-related">
      <div className="cb-container small-padding">
        <div className="cc-shop-related-wrapper">
          <h3>S-ar putea sa-ti placa si...</h3>
          <Carousel
            responsive={[
              {
                breakpoint: 60000,
                settings: {
                  speed: 500,
                  arrows: true,
                  slidesToShow: data.length < 4 ? data.length : 4,
                  slidesToScroll: 1,
                  initialSlide: 1,
                  pauseOnHover: false,
                  swipe: false,
                  autoplay: false,
                  infinite: true,
                  dots: false,
                },
              },
              {
                breakpoint: 769,
                settings: {
                  arrows: true,
                  slidesToShow: 1,
                  slidesToScroll: 1,
                  initialSlide: 1,
                  pauseOnHover: true,
                  swipe: true,
                  autoplay: false,
                  infinite: true,
                  dots: false,
                  autoplaySpeed: 1,
                  speed: 500,
                  cssEase: "linear",
                },
              },
            ]}
          >
            {data.map((card, idx) => (
              <div className="slide" key={idx}>
                <Link to={`/shops/${card.detailsId}`}>
                  <ShopsCard data={card} />
                </Link>
              </div>
            ))}
          </Carousel>
        </div>
      </div>
    </section>
  );
}
