import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { getCategories } from "../../../store/actions/categoriesActions";

import { getShops, filterShops } from "../../../store/actions/shopsActions";
import { getPromos, filterPromos } from "../../../store/actions/promosActions";

export default function Filter(props) {
  /* Get Categories */
  const { categoriesInfo } = useSelector((state) => state.ccCategories);
  const categoryPageUrl = useSelector(
    (state) => state.ccCategories.categoriesInfo.firstPageUrl
  );
  const categories = useSelector(
    (state) => state.ccCategories.categoriesInfo.data
  );

  /* Get Pages State */
  const capitalizedPageName =
    props.pageName.charAt(0).toUpperCase() + props.pageName.slice(1);

  const { filterKeyword, isLoading } = useSelector(
    (state) => state[`cc${capitalizedPageName}`]
  );
  const { firstPageUrl } = useSelector(
    (state) => state[`cc${capitalizedPageName}`][`${props.pageName}Info`]
  );

  const oldFilterKeyord = filterKeyword ? JSON.parse(filterKeyword) : null;

  /* Update State */

  const dispatch = useDispatch();

  const handleChange = (e) => {
    const filterData = {
      filters: {
        categoryId: e.target.value,
      },
    };

    if (!isLoading && filterData.filters.categoryId !== "all") {
     

      if (
        !filterKeyword ||
        oldFilterKeyord.filters.categoryId !== filterData.filters.categoryId
      ) {
        switch (props.pageName) {
          case "shops":
            dispatch(filterShops(filterData, firstPageUrl));
            break;
          case "promos":
            dispatch(filterPromos(filterData, firstPageUrl));
            break;

          default:
            break;
        }
      }
    } else if (filterKeyword) {
      switch (props.pageName) {
        case "shops":
          dispatch(getShops(firstPageUrl));
          break;
        case "promos":
          dispatch(getPromos(firstPageUrl));
          break;

        default:
          break;
      }
    }
  };

  useEffect(() => {
    if (!categoriesInfo.data) {
      dispatch(getCategories(categoryPageUrl));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <div className="cc-filter">
      <select
        name="filter"
        id="filter"
        //defaultValue={"DEFAULT"}
        onChange={(e) => handleChange(e)}
        value={oldFilterKeyord?.filters?.categoryId || "all"}
      >
        <option disabled>
          Alege dupa categorie
        </option>
        <option value="all">Toate categoriile</option>
        {categories
          ? categories.map((category, idx) => (
              <option key={idx + 5} value={category.id}>
                {category.name}
              </option>
            ))
          : null}
      </select>
    </div>
  );
};
