import React, { useState, useEffect } from "react";
import { Helmet } from "react-helmet";
import { useDispatch, useSelector } from "react-redux";
import { userContact } from "../../store/actions/userActions";
import { LoadingSpinner } from "../../components";

import "./Contact.css";

const Contact = () => {
  /* Get State */
  const { userContactForm, errorContact, isLoading } = useSelector(
    (state) => state.ccUser
  );

  /* Update State */
  const dispatch = useDispatch();

  const [contact, setContact] = useState({
    name: "",
    email: "",
    subject: "",
    message: "",
  });

  const handleChange = (e) => {
    setContact({
      ...contact,
      [e.target.name]: e.target.value,
    });
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    dispatch(userContact(contact));
  };

  useEffect(() => {
    if (errorContact || userContactForm) {
      dispatch({
        type: "SUCCESS_CONTACT",
        payload: {},
      });

      dispatch({
        type: "ERROR_CONTACT",
        payload: {},
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <>
      <Helmet>
        <meta charSet="utf-8" />
        <title>Contact</title>
      </Helmet>
      <section className="cb-contact">
        <div className="cb-container medium-padding">
          <div className="cb-contact-wrapper">
            <div className="cb-contact-header">
              <h1>Contact</h1>
              {/* <p>
                Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the
                industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and
                scrambled it to make a type specimen book.{' '}
              </p> */}
            </div>

            <div className="cb-contact-content">
              <div className="cb-contact-content-form">
                {isLoading ? (
                  <LoadingSpinner padding={"2"} width={"10"} />
                ) : userContactForm && userContactForm.errors ? (
                  <div className="cc-error-messages">
                    {userContactForm.errors.email.map((message, idx) => (
                      <p key={idx}>{message}</p>
                    ))}
                  </div>
                ) : errorContact ? (
                  <div className="cc-error-messages">
                    <p>
                      A aparut o eroare, reincarca pagina sau incearca mai
                      tarziu
                    </p>
                  </div>
                ) : userContactForm && !userContactForm.errors ? (
                  <div className="cc-success-messages">
                    <p>
                      Mesjul tau a fost trimis cu succes, vei fi contactat in
                      cel mai scurt timp posibil
                    </p>
                  </div>
                ) : null}
                <form id="cb-contact-form" onSubmit={(e) => handleSubmit(e)}>
                  <input
                    type="text"
                    name="name"
                    placeholder="Nume"
                    required
                    onChange={(e) => handleChange(e)}
                  />
                  <input
                    type="email"
                    name="email"
                    placeholder="Email"
                    required
                    onChange={(e) => handleChange(e)}
                  />
                  <input
                    type="text"
                    name="subject"
                    placeholder="Subiect"
                    required
                    onChange={(e) => handleChange(e)}
                  />
                  <textarea
                    type="text"
                    name="message"
                    placeholder="Adauga mesaj"
                    required
                    onChange={(e) => handleChange(e)}
                  />
                  <button
                    type="submit"
                    name="submit"
                    className="btn primary green"
                  >
                    Trimite
                  </button>
                </form>
              </div>

              <div className="cb-contact-content-address">
                <h2>Contact Info</h2>
                <span>VAN CONSULTING SERVICES S.R.L.</span>
                <span>Cui: 39743787</span>
                <span>Reg. Com.: J40/11605/13.08.2018</span>
                <span>ING BANK RO44INGB0000999908271988</span>
                <br />
                <span>Aleea Banul Udrea, Nr.2</span>
                <span>Bucuresti 031278, Romania</span>
                <span>contact@cashclub.ro</span>
                <a href="tel:0799185679">0799 185 679</a>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

export default Contact;
