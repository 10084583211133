import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { filterProducts } from "../../../store/actions/productsActions";
import { filterShops } from "../../../store/actions/shopsActions";
import { filterPromos } from "../../../store/actions/promosActions";

import "./Search.css";

const Search = (props) => {
  /* Get State */

  const capitalizedPageName =
    props.pageName.charAt(0).toUpperCase() + props.pageName.slice(1);

  const { filterKeyword, isLoading } = useSelector(
    (state) => state[`cc${capitalizedPageName}`]
  );
  const { firstPageUrl } = useSelector(
    (state) => state[`cc${capitalizedPageName}`][`${props.pageName}Info`]
  );

  const oldFilterKeyord = filterKeyword ? JSON.parse(filterKeyword)?.filters?.keyword : null

  /* Update State Data */

  const [filterData, setFilterData] = useState({
    filters: {
      keyword: "",
    },
  });

  useEffect(()=>{
    setFilterData({
      ...filterData,
      filters: {
        keyword: oldFilterKeyord || ""
      }
    })
    
    // eslint-disable-next-line react-hooks/exhaustive-deps
  },[oldFilterKeyord])

  const handleChange = (e) => {
    setFilterData({
      ...filterData,
      filters: {
        keyword: e.target.value,
      },
    });
  };

  const dispatch = useDispatch();

  const handleSubmit = (e) => {
    e.preventDefault();

    if (isLoading === false) {
      
      if (
        !filterKeyword ||
        oldFilterKeyord !== filterData.filters.keyword
      ) {
        switch (props.pageName) {
          case "products":
            dispatch(filterProducts(filterData, firstPageUrl));
            break;
          case "shops":
            dispatch(filterShops(filterData, firstPageUrl));
            break;
          case "promos":
            dispatch(filterPromos(filterData, firstPageUrl));
            break;

          default:
            break;
        }
      }
    }
  };

  return (
    <div className="cc-search-box">
      <form onSubmit={(e) => handleSubmit(e)}>
        <input
          type="text"
          name="search"
          value={filterData.filters.keyword}
          placeholder={props.placeholder ? props.placeholder : "Cauta..."}
          onChange={(e) => handleChange(e)}
        />
        <button
          type="submit"
          value="Submit"
          name="submit"
          className="btn-search-submit"
        />
      </form>
    </div>
  );
};

export default Search;
