const initialState = {
  isLoading: false,
  categoriesInfo: {
    firstPageUrl: `${process.env.REACT_APP_API_ROOT}/categories?pageNumber=1`,
  },
  couponsShopsInfo: {
    firstPageUrl: `${process.env.REACT_APP_API_ROOT}/coupons/shops`,
  },
};

const categoriesReducer = (state = initialState, action) => {
  const { type, payload } = action;

  switch (type) {
    case "AWAITING_CATEGORIES":
      return {
        ...state,
        isLoading: true,
      };

    case "ERROR_CATEGORIES":
      return {
        ...state,
        isLoading: false,
      };

    case "SUCCESS_GET_CATEGORIES":
      return {
        ...state,
        isLoading: false,
        categoriesInfo: {
          ...payload.data,
        },
      };

    case "SUCCESS_GET_COUPONS_SHOPS":
      return {
        ...state,
        isLoading: false,
        couponsShopsInfo: {
          data: [...payload.data],
        },
      };

    default:
      return state;
  }
};

export default categoriesReducer;
