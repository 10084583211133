import React from "react";

import { Helmet } from "react-helmet";
import ProductsListing from "./components/products-listing/ProductsListing";
// import PrSideImage from './components/pr-side-image/PrSideImage';
import { QuestionsBtn } from "../../components";

const Products = () => {
  return (
    <>
      <Helmet>
        <meta charSet="utf-8" />
        <title>Produse</title>
      </Helmet>
      <ProductsListing />
      {/* <PrSideImage /> */}
      <QuestionsBtn />
    </>
  );
};

export default Products;
