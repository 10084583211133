import React from "react";

import "./ModalPermissions.css";

import img from "../../../assets/icons/complain.png";
import step1 from "../../../assets/images/login/step1.jpg";
import step2 from "../../../assets/images/login/step2.jpg";
import step3 from "../../../assets/images/login/step3.jpg";

export default function ModalPermissions({ handleModal }) {
  return (
    <div className="cc-permissions-modal">
      <span
        onClick={handleModal}
        className="cc-permissions-modal-close hide-mobile"
      ></span>

      <div className="cc-permissions-modal-body">
        <span
          onClick={handleModal}
          className="cc-permissions-modal-close hide-tablet hide-desktop"
        ></span>
        <div className="cc-permissions-modal-wrapper">
          <div className="cc-permissions-modal-content">
            <div className="cc-permissions-modal-image">
              <img src={img} alt="info-icon" />
              <h2>Autentificare esuata</h2>
            </div>

            <p>
              Autentificarea nu a reusit datorita faptului ca nu au fost
              acordate permisiunile necesare pentru autentificare. Pentru a te
              autentifica cu Facebook urmareste pasii de mai jos.
            </p>

            <p>1. Selecteaza butonul Login with Facebook</p>
            <div className="step-image">
              <img src={step1} alt="Pasul 1" />
            </div>
            <p>2. Selecteaza butonul Editeaza drepturile de acces</p>
            <div className="step-image">
              <img src={step2} alt="Pasul 2" />
            </div>
            <p>
              3. Asigura-te ca este bifat accesul la adresa de email si
              selecteaza butonul Continua ca
            </p>
            <div className="step-image">
              <img src={step3} alt="Pasul 3" />
            </div>
            <div className="modal-button">
              <button
                className="btn primary green  hide-mobile"
                onClick={handleModal}
              >
                Am inteles!
              </button>
            </div>
          </div>
          <div className="modal-button">
            <button
              className="btn primary green hide-tablet hide-desktop"
              onClick={handleModal}
            >
              Am inteles!
            </button>
          </div>
        </div>
      </div>
      <div
        className="cc-permissions-modal-underlay"
        onClick={handleModal}
      ></div>
    </div>
  );
}
