const initialState = {
  isLoading: false,
  error: false,
  shopInfo: null,
};

const shopReducer = (state = initialState, action) => {
  const { type, payload } = action;

  switch (type) {
    case "AWAITING_SHOP":
      return {
        ...state,
        isLoading: true,
      };

    case "ERROR_SHOP":
      return {
        ...state,
        isLoading: false,
        error: payload.e && payload.e.response ? payload.e.response : payload.e ? payload.e : null,
      };

    case "SUCCESS_GET_SHOP":
      return {
        ...state,
        isLoading: false,
        shopInfo: payload ? payload : null,
      };

    default:
      return state;
  }
};

export default shopReducer;
