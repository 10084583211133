import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Link } from "react-router-dom";
import { userWithdrawFunds } from "../../../../store/actions/userActions";
import { LoadingSpinner } from "../../../../components";

import "./WithdrawFunds.css";

const WithdrawFunds = (props) => {
  const [toggleSuccess, setToggleSuccess] = useState(false);

  const handleClick = () => {
    if (toggleSuccess === false) {
      setToggleSuccess(true);
      document.getElementsByTagName("html")[0].className = "menu-no-scroll";
    } else {
      setToggleSuccess(false);
      document.getElementsByTagName("html")[0].className = "";
    }
  };

  /* Get State */
  const { userInfo } = useSelector((state) => state.ccAccount);
  const { isLoading, errorWithdraw, userWithdraw } = useSelector(
    (state) => state.ccUser
  );

  /* Update State */
  const dispatch = useDispatch();

  const [credentials, setCredentials] = useState({
    contact_person: "",
    contact_email: "",
    contact_phone: "",
    account_holder: "",
    iban: "",
  });

  const handleChange = (e) => {
    setCredentials({
      ...credentials,
      [e.target.name]: e.target.value,
    });
  };

  const handleSendEmail = (e) => {
    e.preventDefault();
    dispatch(userWithdrawFunds(credentials));
  };

  useEffect(() => {
    if (userInfo && userInfo.wallet < 50) {
      props.history.push("/account");
    }

    if (userWithdraw && !userWithdraw.errors) {
      handleClick();
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [userInfo, userWithdraw]);

  return (
    <section className="cc-withdraw-funds">
      <div className="cb-container small-padding">
        <div className="cc-withdraw-funds-wrapper">
          <div className="cc-withdraw-funds-header">
            <h1>Retragere fonduri</h1>
            <p>Fonduri disponibile: {userInfo.wallet} Lei</p>
          </div>
          {isLoading ? (
            <LoadingSpinner padding={"2"} width={"15"} />
          ) : userWithdraw && userWithdraw.errors ? (
            <div className="cc-error-messages">
              <p>{userWithdraw.errors}</p>
            </div>
          ) : (
            errorWithdraw && (
              <div className="cc-error-messages">
                <p>
                  A aparut o eroare, reincarca pagina sau incearca mai tarziu
                </p>
              </div>
            )
          )}
          <div>
            <form onSubmit={(e) => handleSendEmail(e)}>
              <input
                type="text"
                name="contact_person"
                placeholder="Persoana contact"
                required
                minLength="3"
                onChange={(e) => handleChange(e)}
              />
              <input
                type="email"
                name="contact_email"
                placeholder="Email"
                required
                onChange={(e) => handleChange(e)}
              />
              <input
                type="text"
                name="contact_phone"
                placeholder="Telefon"
                required
                maxLength="12"
                onChange={(e) => handleChange(e)}
              />
              <input
                type="text"
                name="account_holder"
                placeholder="Titular cont"
                required
                minLength="3"
                onChange={(e) => handleChange(e)}
              />
              <input
                type="text"
                name="iban"
                placeholder="IBAN"
                required
                onChange={(e) => handleChange(e)}
              />
              <button
                type="submit"
                value="Submit"
                name="submit"
                className="btn primary green"
              >
                Solicita transfer
              </button>
              <Link to="/account" className="btn outline black">
                Anuleaza
              </Link>
            </form>
          </div>
          {toggleSuccess === true && (
            <div className="cc-withdraw-funds-modal">
              <div>
                <h1>Multumim!</h1>
                <h2>Cererea a fost trimisa</h2>
                <p>Vei primi un mail cand cererea ta a fost procesata.</p>
                <Link
                  to="/account"
                  onClick={() => handleClick()}
                  className="btn outline grey"
                >
                  Inapoi la contul meu
                </Link>
              </div>
            </div>
          )}
        </div>
      </div>
    </section>
  );
};

export default WithdrawFunds;
