import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Helmet } from "react-helmet";
import { Link } from "react-router-dom";
import { userRegister } from "../../store/actions/userActions";
import { LoadingSpinner } from "../../components";

import "./Register.css";

const Register = (props) => {
  /* If refferal url / get referral url */
  const refCode = window.location.href.split("?")[1]
    ? window.location.href.split("?")[1]
    : null;

  /* Get State */
  const { isLoading, errorRegister } = useSelector((state) => state.ccUser);
  const { errors, success } = useSelector((state) => state.ccUser.register);

  /* Update State */

  const dispatch = useDispatch();

  const [credentials, setCredentials] = useState({
    first_name: "",
    last_name: "",
    email: "",
    password: "",
    password_confirmation: "",
  });

  const handleChange = (e) => {
    setCredentials({
      ...credentials,
      [e.target.name]: e.target.value,
    });
  };

  const handleRegister = (e) => {
    e.preventDefault();

    const bodyFormData = new FormData();
    bodyFormData.append("first_name", credentials.first_name);
    bodyFormData.append("last_name", credentials.last_name);
    bodyFormData.append("email", credentials.email);
    bodyFormData.append("password", credentials.password);
    bodyFormData.append(
      "password_confirmation",
      credentials.password_confirmation
    );

    dispatch(userRegister(props, bodyFormData, refCode));
  };

  useEffect(() => {
    if (errors || errorRegister) {
      dispatch({
        type: "SUCCESS_USER_REGISTER",
        payload: {
          errors: null,
          success: null,
        },
      });

      dispatch({
        type: "ERROR_USER_REGISTER",
        payload: {
          error: null,
        },
      });
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <>
      <Helmet>
        <meta charSet="utf-8" />
        <title>Register</title>
      </Helmet>
      <section className="cc-create-account">
        <div className="cb-container small-padding">
          <div className="cc-create-account-wrapper">
            <div className="new-account">
              <h2>Cont nou</h2>
              <p>Daca ai deja cont, te rugam sa te autentifici</p>

              {isLoading ? (
                <LoadingSpinner padding={"2"} width={"15"} />
              ) : errors ? (
                <>
                  <div className="cc-error-messages">
                    {errors.email &&
                      errors.email.map((message, idx) => (
                        <p key={idx}>{message}</p>
                      ))}
                  </div>
                  <div className="cc-error-messages">
                    {errors.password &&
                      errors.password.map((message, idx) => (
                        <p key={idx}>{message}</p>
                      ))}
                  </div>
                </>
              ) : errorRegister ? (
                <div className="cc-error-messages">
                  <p>
                    A aparut o eroare, reincarca pagina sau incearca mai tarziu
                  </p>
                </div>
              ) : (
                success && (
                  <div className="cc-success-messages">
                    <p>
                      Un email pentru confirmare a contului a fost trimis.
                      Confirma contul accesand linkul din emailul primit.
                    </p>
                  </div>
                )
              )}

              <form onSubmit={(e) => handleRegister(e)}>
                <input
                  type="text"
                  name="first_name"
                  placeholder="Prenume"
                  required
                  onChange={(e) => handleChange(e)}
                />
                <input
                  type="text"
                  name="last_name"
                  placeholder="Nume"
                  required
                  onChange={(e) => handleChange(e)}
                />
                <input
                  type="email"
                  name="email"
                  placeholder="Email"
                  required
                  onChange={(e) => handleChange(e)}
                  style={{
                    boxShadow: `${
                      errors && errors.email
                        ? "0 2px 12px 0 rgb(255 0 0 / 42%)"
                        : "0 2px 12px 0 rgba(0,0,0,0.1)"
                    }`,
                  }}
                />
                <label>
                  (Minim 8 caractere, o litera mare, o litera mica si o cifra)
                </label>
                <input
                  type="password"
                  name="password"
                  placeholder="Parola"
                  required
                  onChange={(e) => handleChange(e)}
                  style={{
                    boxShadow: `${
                      errors && errors.password
                        ? "0 2px 12px 0 rgb(255 0 0 / 42%)"
                        : "0 2px 12px 0 rgba(0,0,0,0.1)"
                    }`,
                  }}
                />
                <input
                  type="password"
                  name="password_confirmation"
                  placeholder="Confirma parola"
                  required
                  onChange={(e) => handleChange(e)}
                  style={{
                    boxShadow: `${
                      errors && errors.password
                        ? "0 2px 12px 0 rgb(255 0 0 / 42%)"
                        : "0 2px 12px 0 rgba(0,0,0,0.1)"
                    }`,
                  }}
                />

                <div className="terms-and-conditions">
                  <input
                    type="checkbox"
                    name="checkbox"
                    required
                    onChange={(e) => handleChange(e)}
                  />
                  <label>
                    Accept
                    <Link
                      to="/terms-and-conditions"
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      Termenii si Conditiile
                    </Link>
                  </label>
                </div>
                <div className="cc-create-account-actions">
                  <button
                    type="submit"
                    value="Submit"
                    name="submit"
                    className="btn primary green login"
                  >
                    Creeaza cont
                  </button>
                  <Link to="/login" className="grey">
                    Am deja cont
                  </Link>
                </div>
              </form>
            </div>

            <div className="new-account-benefits hide-mobile">
              <h2>Avantaje</h2>
              <div>
                <div></div>
                <p>Economisesti zilnic</p>
              </div>
              <div>
                <div></div>
                <p>Simplu de folosit</p>
              </div>
              <div>
                <div></div>
                <p>Simplu de folosit</p>
              </div>
              <div>
                <div></div>
                <p>Disponibil pe mobil</p>
              </div>
              <div>
                <div></div>
                <p>800 de magazine partenere</p>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

export default Register;
