import store from '../store/Store';

class Auth {
  constructor() {
    this.authenticated = false;
  }

  token() {
    return localStorage.getItem('ccUserToken');
  }

  login(ccUserToken, cb) {
    if (ccUserToken) {
      localStorage.setItem('ccUserToken', ccUserToken);
    }

    if (cb) {
      cb();
    }
  }

  logout(cb) {
    localStorage.removeItem('ccUserToken');

    store.dispatch({ type: 'LOGOUT' });

    if (cb) {
      cb();
    }
  }

  isAuthenticated() {
    const ccUserToken = localStorage.getItem('ccUserToken');

    if (ccUserToken) {
      return (this.authenticated = true);
    } else {
      return (this.authenticated = false);
    }
  }
}

const authInstance = new Auth();

export default authInstance;
