const initialState = {
  isLoading: false,
  historyIsLoading: false,
  paymentIsLoading: false,
  detailsIsLoading: false,
  error: false,
  errorPayment: false,
  errorDetails: null,
  userInfo: {},
  userHistory: {
    hsShops: [],
    filteredHsShops: [],
  },
  userPaymentHistory: null,
  userPaymentDetails: {
    details: null,
    paymentId: null,
  },
};

const accountReducer = (state = initialState, action) => {
  const { type, payload } = action;

  switch (type) {
    /* AWAITING */
    case "AWAITING_ACCOUNT":
      return {
        ...state,
        isLoading: true,
      };

    case "AWAITING_ACCOUNT_HISTORY":
      return {
        ...state,
        historyIsLoading: true,
      };

    case "AWAITING_PAYMENT_HISTORY":
      return {
        ...state,
        paymentIsLoading: true,
      };

    case "AWAITING_PAYMENT_DETAILS":
      return {
        ...state,
        detailsIsLoading: true,
      };

    /* ERROR */
    case "ERROR_ACCOUNT":
      return {
        ...state,
        isLoading: false,
        isAuthenticated: false,
        historyIsLoading: false,
        error:
          payload.e && payload.e.response
            ? payload.e.response.status
            : payload.e
            ? payload.e
            : null,
      };

    case "ERROR_PAYMENT_ACCOUNT":
      return {
        ...state,
        paymentIsLoading: false,
        errorPayment: payload.e ? payload.e : null,
      };

    case "ERROR_PAYMENT_DETAILS":
      return {
        ...state,
        detailsIsLoading: false,
        errorDetails: payload.e ? payload.e : null,
      };

    /* SUCCESS */
    case "SUCCESS_USER_INFO":
      return {
        ...state,
        isLoading: false,
        isAuthenticated: true,
        userInfo: {
          ...payload.data,
        },
      };

    case "SUCCESS_USER_LOGIN":
      return {
        ...state,
        isAuthenticated: true,
      };

    case "SUCCESS_USER_HISTORY":
      return {
        ...state,
        historyIsLoading: false,
        userHistory: {
          hsShops: payload.data,
          filteredHsShops: [],
        },
      };

    case "SUCCESS_SORT_HISTORY":
      return {
        ...state,
        historyIsLoading: false,
        userHistory: {
          filteredHsShops: payload.data,
          hsShops: state.userHistory.hsShops,
        },
      };

    case "SUCCESS_PAYMENT_HISTORY":
      return {
        ...state,
        paymentIsLoading: false,
        userPaymentHistory: payload.data,
      };

    case "SUCCESS_PAYMENT_DETAILS":
      return {
        ...state,
        detailsIsLoading: false,
        userPaymentDetails: {
          details: payload.data,
          paymentId: payload.paymentId,
        },
      };

    default:
      return state;
  }
};

export default accountReducer;
