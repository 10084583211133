import React, { useEffect, useState } from "react";
// import { Link } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
// import auth from "../../../../helpers/authentication";
import { withRouter } from "react-router";
import { getUserInfo } from "../../../../store/actions/accountActions";
// import { userLogout } from "../../../../store/actions/userActions";
// import { LoadingSpinner } from "../../../../components";

// import img from "../../../../assets/icons/placeholder-user.jpg";

import "./AccountUserInfo.css";

const AccountUserInfo = (props) => {
  /* Get State from account*/
  const { userInfo, error } = useSelector((state) => state.ccAccount);

  const [paginateHistory, setPaginateHistory] = useState(5);

  const handleMoreHistory = (e) => {
    e.preventDefault();
    setPaginateHistory(() => paginateHistory + 5);
  };

  /* Update State */

  const dispatch = useDispatch();

  // const handleLogOut = () => {
  //   dispatch(userLogout());
  //   auth.logout(() => {
  //     props.history.push("/login");
  //   });
  // };

  useEffect(() => {
    if (error) {
      dispatch({
        type: "ERROR_ACCOUNT",
        payload: {},
      });
    }

    dispatch(getUserInfo(props));

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  /* Last 5 elements from referral history arr */
  const newReferralHistory = userInfo && userInfo.referrals;

  return (
    <section className="account-user-info">
      <div className="cb-container small-padding">
        <div className="user-info-wrapper">
          <div className="user-content">
            <div className="user-intro">
              <h1>Istoric</h1>
              <p>
                Acum ai sansa de a strange bani in timp ce cumperi! Urmareste
                evolutia economiilor tale si bucura-te de bani in plus.
              </p>
            </div>
            {/* <div className="user-data">
              {isLoading ? (
                <LoadingSpinner padding={"2"} width={"10"} />
              ) : (
                <>
                  <div className="user-data-summary">
                    <h3>
                      {userInfo &&
                        userInfo.first_name + " " + userInfo.last_name}
                    </h3>
                    <p>{userInfo && userInfo.email}</p>
                    <div className="user-data-summary-wallet">
                      <span>
                        {userInfo && userInfo.wallet ? userInfo.wallet : 0} Lei{" "}
                      </span>
                      <Link
                        to="/account/withdraw-funds"
                        className="cc-account-actions-btn disabled-link"
                        style={{
                          pointerEvents: `${userInfo.wallet >= 50 ? "" : "none"}`,
                          opacity: `${userInfo.wallet >= 50 ? "" : "0.4"}`,
                          userSelect: "none",
                        }}
                      >
                        Retragere
                      </Link>
                    </div>
                    <div className="user-data-summary-actions">
                      <Link
                        to="/account/edit-account"
                        className="cc-account-actions-btn"
                      >
                        Editare cont
                      </Link>
                      <button
                        className="cc-account-actions-btn"
                        onClick={handleLogOut}
                      >
                        Logout
                      </button>
                    </div>
                  </div>
                  <div
                    className="user-image"
                    style={{
                      backgroundImage: `url(
                        ${
                          !userInfo.image ||
                          userInfo.image === "https://api.cashclub.ro/"
                            ? img
                            : userInfo.image
                        })`,
                    }}
                  ></div>
                </>
              )}
            </div> */}
          </div>
          {error && (
            <div className="cc-error-messages">
              <p>A aparut o eroare, reincarca pagina sau incearca mai tarziu</p>
            </div>
          )}

          {/* <div className="user-separator"></div> */}

          <div className="user-referral-history">
            <h2>Istoric referral</h2>
            {newReferralHistory.length ? (
              <>
                <div className="user-separator hide-tablet hide-desktop"></div>

                <div className="user-referral-history-list">
                  {newReferralHistory
                    .slice(0, paginateHistory)
                    .map((item, idx) => (
                      <div key={idx}>
                        <div className="referral-history-name">
                          <h3>Nume</h3>
                          <span>{item.first_name + " " + item.last_name}</span>
                        </div>
                        <div>
                          <h3>Data inscriere</h3>
                          <span>{item.registered_date}</span>
                        </div>
                        <div className="hide-mobile">
                          <h3>Comenzi plasate</h3>
                          <span>
                            {item.has_approved_commission ? "DA" : "NU"}
                          </span>
                        </div>
                        <div className="hide-mobile">
                          <h3>Status</h3>
                            {item.has_approved_commission ? (
                              <span className="is-paid">platibil</span>
                            ) : (
                              <span className="is-in-process"> inregistrat</span>
                            )}
                        </div>
                      </div>
                    ))}
                </div>
                <div className="cc-listing-more-articles">
                  <button
                    type="button"
                    className="btn read-more grey"
                    onClick={(e) => handleMoreHistory(e)}
                    disabled={
                      newReferralHistory &&
                      newReferralHistory.length < paginateHistory
                        ? true
                        : false
                    }
                    style={{
                      opacity: `${
                        newReferralHistory &&
                        newReferralHistory.length < paginateHistory
                          ? 0.2
                          : 0.6
                      }`,
                    }}
                  >
                    Mai mult istoric
                  </button>
                </div>
              </>
            ) : (
              <>
                <div>Nu ai istoric referral</div>
                <div className="user-separator hide-tablet hide-desktop"></div>
              </>
            )}
          </div>
        </div>
      </div>
    </section>
  );
};

export default withRouter(AccountUserInfo);
