import pr1 from '../images/products/pr1.png';
import pr2 from '../images/products/pr2.png';
import pr3 from '../images/products/pr3.png';
import pr4 from '../images/products/pr4.png';
import pr5 from '../images/products/pr5.png';
import pr6 from '../images/products/pr6.png';
import pr7 from '../images/products/pr7.png';
import pr8 from '../images/products/pr8.png';
import pr9 from '../images/products/pr9.png';
import pr10 from '../images/products/pr10.png';
import pr11 from '../images/products/pr11.png';
import pr12 from '../images/products/pr12.png';
import pr13 from '../images/products/pr13.png';
import pr14 from '../images/products/pr14.png';
import pr15 from '../images/products/pr15.png';
import pr16 from '../images/products/pr16.png';
import pr17 from '../images/products/pr17.png';
import pr18 from '../images/products/pr18.png';
import pr19 from '../images/products/pr19.png';
import pr20 from '../images/products/pr20.png';
import pr21 from '../images/products/pr21.png';
import pr22 from '../images/products/pr22.png';
import pr23 from '../images/products/pr23.png';

import icon1 from '../icons/Group12.svg';

const products = [
  {
    id: '1',
    img: pr1,
    title: 'Beats by Dr. Dre Solo 3 by Dr. Dre',
    icon: icon1,
    discount: '0.5% cashback',
    shop: 'evomag.ro',
  },
  {
    id: '2',
    img: pr2,
    title: 'Telefon Mobil Apple iPhone XS',
    icon: icon1,
    discount: '0.5% cashback',
    shop: 'evomag.ro',
  },
  {
    id: '3',
    img: pr3,
    title: 'Telefon Mobil Samsung Galaxy S20 Ultra',
    icon: icon1,
    discount: '0.5% cashback',
    shop: 'evomag.ro',
  },
  {
    id: '4',
    img: pr4,
    title: 'Laptop Lenovo ThinkPad P73',
    icon: icon1,
    discount: '0.5% cashback',
    shop: 'evomag.ro',
  },
  {
    id: '5',
    img: pr5,
    title: 'Sandale ZAXY',
    icon: icon1,
    discount: '2.4% cashback',
    shop: 'epantofi.ro',
  },
  {
    id: '6',
    img: pr6,
    title: 'Puzzle LOL Surprise',
    icon: icon1,
    discount: '3.5% cashback',
    shop: 'noriel.ro',
  },
  {
    id: '7',
    img: pr7,
    title: 'Carucior 3 in 1 Kraft 6 Marina',
    icon: icon1,
    discount: '3% cashback',
    shop: 'nichiduta.ro',
  },
  {
    id: '8',
    img: pr8,
    title: 'LEGO Super Heroes - Urmarirea Pinguinului cu Batboat! 76158',
    icon: icon1,
    discount: '3.5% cashback',
    shop: 'elefant.ro',
  },
  {
    id: '9',
    img: pr9,
    title: 'Garnier Fructis Banana Hair Food',
    icon: icon1,
    discount: '2% cashback',
    shop: 'notino.ro',
  },
  {
    id: '10',
    img: pr10,
    title: 'L’Oréal Paris Volume Million Lashes',
    icon: icon1,
    discount: '2% cashback',
    shop: 'notino.ro',
  },
  {
    id: '11',
    img: pr11,
    title: 'Loțiune Igienizantă pentru Mâini cu Alcool',
    icon: icon1,
    discount: '3.5% cashback',
    shop: 'farmec.ro',
  },
  {
    id: '12',
    img: pr12,
    title: 'Rochie dreapta cu model animal print',
    icon: icon1,
    discount: '3% cashback',
    shop: 'fashiondays.ro',
  },
  {
    id: '13',
    img: pr13,
    title: 'Tricou polo din material pique a',
    icon: icon1,
    discount: '3% cashback',
    shop: 'fashiondays.ro',
  },
  {
    id: '14',
    img: pr14,
    title: 'Ochelari de soare dama Guess ',
    icon: icon1,
    discount: '7% cashback',
    shop: 'lensa.ro',
  },
  {
    id: '15',
    img: pr15,
    title: 'Desigual - Poseta',
    icon: icon1,
    discount: '5% cashback',
    shop: 'answear.ro',
  },
  {
    id: '16',
    img: pr16,
    title: 'Ceas de dama Fossil',
    icon: icon1,
    discount: '4% cashback',
    shop: 'topwatch.ro',
  },
  {
    id: '17',
    img: pr17,
    title: 'Lustra Nido',
    icon: icon1,
    discount: '4% cashback',
    shop: 'vivre.ro',
  },
  {
    id: '18',
    img: pr18,
    title: 'Trotineta Town Oxelo',
    icon: icon1,
    discount: '2.5% cashback',
    shop: 'decathlon.ro',
  },
  {
    id: '19',
    img: pr19,
    title: 'Cană din porțelan Cooksmart ® Flowers',
    icon: icon1,
    discount: '1.5% cashback',
    shop: 'bonami.ro',
  },
  {
    id: '20',
    img: pr20,
    title: 'Antifragile',
    icon: icon1,
    discount: '5% cashback',
    shop: 'carturesti.ro',
  },
  {
    id: '21',
    img: pr21,
    title: 'Povestea mea - Michelle Obama',
    icon: icon1,
    discount: '5% cashback',
    shop: 'carturesti.ro',
  },
  {
    id: '22',
    img: pr22,
    title: 'Arta subtila a nepasarii - Mark Manson',
    icon: icon1,
    discount: '4% cashback',
    shop: 'libris.ro',
  },
  {
    id: '23',
    img: pr23,
    title: 'Rework - Jason Fried, David Heinemeier',
    icon: icon1,
    discount: '4% cashback',
    shop: 'libris.ro',
  },
];

export default products;
