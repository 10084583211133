import React from 'react'
import './Main.css'

function Main(props) {
  return (
    <main className="main">
      <div className="main-wrapper">
        {props.children}
      </div>
    </main>
  )
}

export default Main