import React from 'react';
import { useSelector } from 'react-redux';
import { Route, Redirect } from 'react-router-dom';
import auth from '../helpers/authentication';

const PrivateRoute = ({ component: Component, ...rest }) => {
  const isAuthenticated = useSelector((state) => state.ccAccount.isAuthenticated);
  const isAuth = auth.isAuthenticated();

  return (
    <Route
      {...rest}
      render={(props) => {
        if (isAuth && isAuthenticated) {
          return <Component {...props} />;
        } else {
          return <Redirect to='/login' />;
        }
      }}
    />
  );
};

export default PrivateRoute;
