import { combineReducers } from "redux";
import productsReducer from "../reducers/productsReducer";
import shopsReducer from "../reducers/shopsReducer";
import shopReducer from "../reducers/shopReducer";
import promosReducer from "../reducers/promosReducer";
import accountReducer from "../reducers/accountReducer";
import userReducer from "../reducers/userReducer";
import categoriesReducer from "../reducers/categoriesReducer";
import affiliateReducer from "../reducers/affiliateReducer";
import couponsReducer from "./couponsReducer";

const appReducer = combineReducers({
  ccProducts: productsReducer,
  ccShops: shopsReducer,
  ccShop: shopReducer,
  ccPromos: promosReducer,
  ccCategories: categoriesReducer,
  ccUser: userReducer,
  ccAccount: accountReducer,
  ccAffiliate: affiliateReducer,
  ccCoupons: couponsReducer,
});

const rootReducer = (state, action) => {
  if (action.type === "LOGOUT") {
    state = undefined;
  }

  return appReducer(state, action);
};

export default rootReducer;
