import React, { useEffect } from "react";
import { getUserInfo } from "./store/actions/accountActions";
import { useDispatch } from "react-redux";
import { BrowserRouter as Router, Switch, Route } from "react-router-dom";
import ScrollToTop from "react-router-scroll-top";

//Routes
import PrivateRoute from "./routes/PrivateRoute";
import AccountRedirect from "./routes/AccountRedirect";

//Layout
import { Header, Main, Footer } from "./components";

//Components
import { ScrollTop } from "./components";

//Global css
import "./assets/fonts/font.css";
import "./assets/css/Normalize.css";
import "./assets/css/Layout.css";
import "./assets/css/Helpers.css";
import "./assets/css/Buttons.css";

//Pages
// import Homepage from "./pages/homepage/Homepage";
import MobileHomepage from "./pages/mobile-homepage/MobileHomepage";
import Cashback from "./pages/cashback/Cashback";
import Products from "./pages/products/Products";
// import Promos from "./pages/promos/Promos";
import Shops from "./pages/shops/Shops";
import ShopPage from "./pages/shops/shop-page/ShopPage";
import Account from "./pages/account/Account";
import EditAccount from "./pages/account/components/edit-account/EditAccount";
import PaymentDetails from "./pages/account/components/payment-details/PaymentDetails";
import WithdrawFunds from "./pages/account/components/withdraw-funds/WithdrawFunds";
// import Login from './pages/login/Login';
import Login from "./pages/mobile-login/Login";
// import AppleLoginRedirect from "./pages/login/AppleLoginRedirect";
import ForgottenPassword from "./pages/forgotten-password/ForgottenPassword";
import ResetPassword from "./pages/reset-password/ResetPassword";
import Register from "./pages/register/Register";
import ThankYou from "./pages/thank-you/ThankYou";
import AccountConfirmation from "./pages/register/components/AccountConfirmation";
import Faq from "./pages/faq/Faq";
import PageNotFound from "./pages/404/404";
import Contact from "./pages/contact/Contact";
import TermsAndConditions from "./pages/terms-and-conditions/TermsAndConditions";
import DataDeletion from "./pages/data-deletion/DataDeletion";
// import PrivacyPolicy from './pages/privacy-policy/PrivacyPolicy';
import Coupons from "./pages/coupons/Coupons";

const App = () => {
  /* Update State Data */
  const dispatch = useDispatch();
  const iOSModalQuery = window.location.search;

  useEffect(() => {
    const ccUserToken = localStorage.getItem("ccUserToken");

    if (ccUserToken && window.location.pathname !== "/account") {
      dispatch(getUserInfo());
    }

    if (
      iOSModalQuery &&
      (iOSModalQuery === "?platform=ios&version=1.0.7" ||
        iOSModalQuery === "?platform=android&version=1.0.7")
    ) {
      window.localStorage.setItem("ccQuery", iOSModalQuery);
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [iOSModalQuery]);

  return (
    <>
      <Router>
        <ScrollToTop>
          <Header />
          <Main>
            <Switch>
              <PrivateRoute exact path="/" component={MobileHomepage} />
              <PrivateRoute path="/cashback" component={Cashback} />
              <PrivateRoute path="/products" component={Products} />
              <PrivateRoute exact path="/shops" component={Shops} />
              <PrivateRoute path="/shops/:id" component={ShopPage} />
              {/* <PrivateRoute path="/promos" component={Promos} /> */}
              <PrivateRoute path="/coupons" component={Coupons} />
              <PrivateRoute exact path="/account" component={Account} />
              <PrivateRoute
                path="/account/edit-account"
                component={EditAccount}
              />
              <PrivateRoute
                path="/account/withdraw-funds"
                component={WithdrawFunds}
              />
              <PrivateRoute
                path="/account/payment-details"
                component={PaymentDetails}
              />
              <AccountRedirect exact path="/login" component={Login} />
              {/* <Route path="/login/apple-login" component={AppleLoginRedirect} /> */}
              <Route
                path="/login/forgotten-password"
                component={ForgottenPassword}
              />
              <Route path="/login/reset" component={ResetPassword} />
              <Route path="/register" component={Register} />
              <Route path="/thank-you" component={ThankYou} />
              <Route path="/verify" component={AccountConfirmation} />
              <PrivateRoute path="/frequent-questions" component={Faq} />
              <PrivateRoute path="/contact" component={Contact} />
              <Route
                path="/terms-and-conditions"
                component={TermsAndConditions}
              />
              <Route path="/data-deletion" component={DataDeletion} />
              {/* <Route path='/privacy-policy' component={PrivacyPolicy} /> */}
              <Route component={PageNotFound} />
            </Switch>
            {/* Scroll to top button */}
            <ScrollTop />
          </Main>
          <Footer />
        </ScrollToTop>
      </Router>
    </>
  );
};

export default App;
