import React from "react";

import "./FaHero.css";

const FaHero = () => {
  return (
    <section className="fa-hero">
      <div className="cb-container small-padding">
        <div className="fa-hero-wrapper">
          <div>
            <h1>Intrebari frecvente</h1>
            {/* <p>
              Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the
              industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and
              scrambled it to make a type specimen book
            </p> */}
          </div>
        </div>
      </div>
    </section>
  );
};

export default FaHero;
