import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  SortUserHistory,
  LoadingSpinner,
  ResponsiveComponent,
} from "../../../../components";
import { getUserHistory } from "../../../../store/actions/accountActions";
import img from "../../../../assets/icons/placeholder.jpg";

import "./AccountUserHistory.css";

const AccountUserHistory = (props) => {
  /* Update State Data */
  const dispatch = useDispatch();
  /* Get State */
  const { userHistory, historyIsLoading, isLoading, error } = useSelector(
    (state) => state.ccAccount
  );

  /* User history data sorting */

  const [paginateHistory, setPaginateHistory] = useState(5);
  
  const [shopFilterElement, setShopFilterElement] = useState('all');
  const [statusFilterElement, setStatusFilterElement] = useState('all');
  const [paymentFilterElement, setPaymentFilterElement] = useState('all');
  const [resetStatus, setResetStatus] = useState('DEFAULT');

  const newUserHistory =
  shopFilterElement === 'all' && statusFilterElement === 'all' 
  ? userHistory.hsShops 
  : shopFilterElement !== 'all' && statusFilterElement === 'all'
  ? userHistory.hsShops.filter((item) => item.shopId === shopFilterElement) 
  : statusFilterElement !== 'all' && shopFilterElement === 'all'
  ? userHistory.hsShops.filter((item) => item.status === statusFilterElement && item.payment_status === paymentFilterElement) 
  : statusFilterElement !== 'all' && shopFilterElement !== 'all' 
  ? userHistory.hsShops.filter((item) => item.shopId === shopFilterElement && item.status === statusFilterElement && item.payment_status === paymentFilterElement) : null
  
  const filterShopList = userHistory.hsShops.filter((ele, ind) => ind === userHistory.hsShops.findIndex((elem) => elem.shopId === ele.shopId && elem.shopName === ele.shopName))

  const oldShopFilter = userHistory.hsShops.filter((item) => item.shopId === shopFilterElement)
  const filterStatusList = shopFilterElement !== "all" ?
  
  oldShopFilter.filter((ele, ind) => ind === oldShopFilter.findIndex((elem) => elem.status === ele.status && elem.payment_status === ele.payment_status))
  :
  userHistory.hsShops.filter((ele, ind) => ind === userHistory.hsShops.findIndex((elem) => elem.status === ele.status && elem.payment_status === ele.payment_status))
   
  const handleFilter = (e, filter) => {
    if(filter === 'shop'){
      setShopFilterElement(e.target.value);
      setStatusFilterElement('all');
      setPaymentFilterElement('all');
      setResetStatus('all');
    } else if(filter === 'status'){
      setStatusFilterElement(e.target.value.split(",")[0]);
      setPaymentFilterElement(e.target.value.split(",")[1]);
      setResetStatus(e.target.value.split(",")[0]);
    }
  }  

  /* Pagination */
  const handleMoreHistory = (e) => {
    e.preventDefault();
    setPaginateHistory(() => paginateHistory + 5);
  };

  

  useEffect(() => {
    if (!userHistory.hsShops.length || userHistory.filteredHsShops.length) {
      dispatch(getUserHistory(props));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <section className="user-history">
      <div className="cb-container small-padding">
        <div className="user-history-wrapper">
          <h2>Istoric comisioane</h2>

          {isLoading || historyIsLoading ? (
            <LoadingSpinner padding={"2"} width={"10"} />
          ) : error && !userHistory.hsShops.length ? (
            <div className="cc-error-messages">
              <p>A aparut o eroare, reincarca pagina sau incearca mai tarziu</p>
            </div>
          ) : userHistory.hsShops.length && !error ? (
            <>
              <div className="history-separator hide-tablet hide-desktop"></div>
              <div className="user-history-sort-group">
                <SortUserHistory filterShopList={filterShopList} handleFilter={(e) => handleFilter(e, 'shop')}/>
                <SortUserHistory filterStatusList={filterStatusList} handleFilter={(e) => handleFilter(e, 'status')} resetValue={resetStatus}/>
              </div>

              <ResponsiveComponent showOnDesktop>
                <div className="history-list hide-mobile">
                  {newUserHistory &&
                    newUserHistory
                      .slice(0, paginateHistory)
                      .map((item, idx) => (
                        <div key={idx} className="list-item">
                          <div className="list-item-image">
                            <img
                              src={item.shopLogo ? item.shopLogo : img}
                              onError={(e) => {
                                e.target.src = img;
                              }}
                              alt="promo"
                            />
                          </div>
                          <div className="list-item-shop">
                            <h3>Magazin</h3>
                            <span>
                              {item.shopName ? item.shopName : "magazin"}
                            </span>
                          </div>
                          <div className="list-item-estimate">
                            <h3>Suma estimata</h3>
                            <span>{item.estimatedAmount} Lei</span>
                          </div>
                          <div className="list-item-date">
                            <h3>Data cumpararii</h3>
                            <span>{item.createdAt}</span>
                          </div>
                          <div className="list-item-status">
                            <h3>Status</h3>

                            {

                            item.status === "in asteptare"                   
                            ? 
                              <span className="is-in-process">inregistrat</span>
                            : 

                            item.status === "aprobat"                           
                            ? 
                              <span className="is-paid">{item.status}</span>
                            :

                            (item.status === "platibil" && item.payment_status === "not_requested") || (item.status === "platibil" && item.payment_status === "requested")
                            ?
                              <span className="is-paid">{item.status}</span>
                            : 

                            item.status === "platibil" && item.payment_status === "paid" 
                            ? 
                              <span className="is-paid">platit</span>
                            : 

                            item.status === "anulat" && 
                              <span className="is-canceled">{item.status}</span>

                            }

                          </div>
                        </div>
                      ))}
                </div>
              </ResponsiveComponent>

              <ResponsiveComponent showOnMobile>
                <div className="history-list">
                  {newUserHistory &&
                    newUserHistory
                      .slice(0, paginateHistory)
                      .map((item, idx) => (
                        <div key={idx} className="list-item">
                          <div className="list-item-shop">
                            <h3>Magazin</h3>
                            <span>{item.shopName}</span>
                            <span>{item.createdAt}</span>
                          </div>
                          <div className="list-item-estimate">
                            <h3>Suma estimata</h3>
                            <span>{item.estimatedAmount} Lei</span>
                            {
                              item.status === "in asteptare"                   
                              ? 
                                <span className="is-in-process">inregistrat</span>
                              : 

                              item.status === "aprobat"                           
                              ? 
                                <span className="is-paid">{item.status}</span>
                              :

                              (item.status === "platibil" && item.payment_status === "not_requested") || (item.status === "platibil" && item.payment_status === "requested")
                              ?
                                <span className="is-paid">{item.status}</span>
                              : 

                              item.status === "platibil" && item.payment_status === "paid" 
                              ? 
                                <span className="is-paid">platit</span>
                              : 

                              item.status === "anulat" && 
                                <span className="is-canceled">{item.status}</span>

                            }
                          </div>
                        </div>
                      ))}
                </div>
              </ResponsiveComponent>

              <div className="cc-listing-more-articles">
                <button
                  type="button"
                  className="btn read-more grey"
                  onClick={(e) => handleMoreHistory(e)}
                  disabled={
                    newUserHistory && newUserHistory.length < paginateHistory
                      ? true
                      : false
                  }
                  style={{
                    opacity: `${
                      newUserHistory && newUserHistory.length < paginateHistory
                        ? 0.2
                        : 0.6
                    }`,
                  }}
                >
                  Mai mult istoric
                </button>
              </div>
            </>
          ) : (
            <>
              <div>Nu ai istoric comisioane</div>
              <div className="history-separator hide-tablet hide-desktop"></div>
            </>
          )}
        </div>
      </div>
    </section>
  );
};

export default AccountUserHistory;
