import React from "react";

import "./LoadingSpinner.css";

import img from "../../../assets/icons/Spinner-1s-64px.svg";
import imgWhite from "../../../assets/icons/Spinner-1s-64pxWhite.svg";

const LoadingSpinner = (props) => {
  return (
    <div
      className="cc-loading-spinner"
      style={{ padding: `${props.padding}%` }}
    >
      {props.white ? (
        <img
          src={imgWhite}
          alt="loading"
          style={{ width: `${props.width}%` }}
        />
      ) : (
        <img src={img} alt="loading" style={{ width: `${props.width}%` }} />
      )}
    </div>
  );
};

export default LoadingSpinner;
