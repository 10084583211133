import React, { useEffect, useRef } from "react";
import { Helmet } from "react-helmet";

import "./TermsAndConditions.css";

const TermasAndConditions = (props) => {
  const { location } = props;
  const anchorRef = useRef();

  useEffect(() => {
    if (location.search === "?privacy-policy") {
      const element = anchorRef.current;

      element.scrollIntoView({
        behavior: "smooth",
        // block: "start",
        // inline: "start",
      });
    }
  }, [location, anchorRef]);
  return (
    <>
      <Helmet>
        <meta charSet="utf-8" />
        <title>Terms and Conditions</title>
      </Helmet>
      <section className="cb-terms-and-conditions">
        <div className="cb-container small-padding">
          <div className="cb-terms-and-conditions-wrapper">
            <div>
              <h1>Termeni și condiții</h1>
              <h2>BINE AŢI VENIT!</h2>
              <p>Suntem bucuroşi că ne vizitaţi şi că vă putem fi de folos </p>
              <p>
                Prezentele termeni şi condiţii prevăd condiţiile în care se
                poate realiza accesul la site şi conţinutul acestuia, precum şi
                metodele şi condiţiile de folosire ale CashClub unde veti gasi
                cele mai convenabile bonusuri posibile pentru achizițiile
                dumneavoastră online.{" "}
              </p>
              <p>
                Bonusurile acumulate de dumneavoastră sunt aprobate de îndată ce
                magazinul online a aprobat și a acceptat achiziția
                dumneavoastră. În cazul în care returnați produsele sau anulați
                comanda, bonusurile nu se vor mai acorda. De asemenea, pe
                perioade limitate de timp unele magazine online pot acorda
                comisioane diferite fata de cele pe care le acorda in mod
                normal. La evenimente de genul Black Friday care includ deja
                reduceri, comisioanele pot fi schimbate fara notificare
                prealabila.{" "}
              </p>
              <p>
                Plata bonusurilor se face direct în contul dumneavoastră bancar
                când ați atins limita minima de retragere a bonusurilor de 50
                RON si dupa ce solicitati acest lucru prin intermediul
                platformei.
              </p>
            </div>
            <div>
              <h3>1. Introducere</h3>
              <p>
                Acest document este un acord legal care determină drepturile și
                obligațiile dumneavoastră în calitate de utilizator, drepturile
                și obligațiile companiei Van Consulting Services (denumită „noi”
                sau „CashClub”) asociate cu acest site și cu serviciile pe care
                le oferim („Serviciul CashClub”). Inainte de înregistrarea pe
                CashClub este necesar ca să citiți în întregime prezentul acord.
                Prin înregistrarea pe CashClub acceptați să încheiați un acord
                cu noi în termenii și condițiile acestuia. Ocazional, acest
                acord poate fi modificat în conformitate cu punctul 16 de mai
                jos. Toate persoanele care se înregistrează pe CashClub, vor
                crea simultan „Un cont” și vor primi statutul de „Utilizator”.
              </p>
            </div>
            <div>
              <h3>2. Serviciul CashClub</h3>
              <p>
                Serviciul CashClub permite „Utilizatorilor” să primească un
                bonus (în limba engleză „cashback”) din suma cheltuită pentru
                achiziționarea unui produs sau serviciu în magazinul online
                (denumit și „Vânzător”) înregistrat în catalogul CashClub, sub
                formă de procent. O conditie obligatorie pentru ca Utilizatorul
                să primească bonusul din partea magazinului online
                („Vânzătorului”), este ca magazinul online să confirme că
                achiziția online a Utilizatorului este reală, finalizată cu
                succes și este „un eveniment înregistrat”. Procesul unei
                tranzacții finalizate poate fi acela prin care Utilizatorul a
                achitat comanda, a primit-o și nu a returnat-o Vânzătorului în
                timpul perioadei de retur. Procesul de plată a bonusurilor este
                foarte scurt uneori, însă de cele mai multe ori magazinele
                online plătesc bonusurile după încheierea perioadei de returnare
                a produselor pe care Utilizatorul le-a achiziționat, ce poate
                dura până la 30 de zile. Magazinul online trebuie să plătească
                apoi în întregime CashClub comisionul (bonusul) pentru
                tranzacția finalizata, pentru ca la rândul său CashClub să poată
                plăti Utilizatorilor bonusurile pentru cumpărăturile lor.
              </p>
              <p>
                Astfel, în cazul în care magazinul online nu ne oferă informații
                cu privire la achizițiile dumneavoastră, nu ne asumăm
                răspunderea pentru imposibilitatea de a plăti bonusurile
                acestora.
              </p>
              <p>
                Definiție: Bonus (Cashback) este suma de bani pe care
                Utilizatorul o primește după confirmarea achiziției de către
                Vânzător ca fiind legitimă, completă și o finalizata în
                conformitate cu termenii acestui acord.
              </p>
              <p>
                Definiție: Eveniment înregistrat este o achiziție care se
                realizează corect prin intermediul site-ului CashClub și care a
                fost confirmată de magazinul online partener. Chiar dacă
                statusul acestui eveniment apare ca înregistrat, acest lucru nu
                garantează că magazinul online va plăti bonusul către CashClub.
              </p>
            </div>
            <div>
              <h3>3. Contul utilizatorului</h3>
              <p>
                Trebuie să aveți cel puțin 16 ani pentru a obține un cont de
                utilizator. De asemenea, o persoană poate avea doar un singur
                cont de utilizator pe site-ul CashClub. Informațiile cu caracter
                personal pe care trebuie să le furnizați in momentul
                înregistrării pe CashClub trebuie sa fie reale și actualizate.
                In acelasi timp, pentru a se putea transfera bonusul în contul
                dumneavoastră bancar, trebuie să specificați detaliile contului
                dumneavoastră și să respectați următoarele cerințe:
              </p>
              <ol>
                <li>
                  trebuie să dețineți dreptul deplin la utilizarea contului
                  bancar în momentul dat
                </li>
                <li>
                  trebuie să confirmați că doriți să primiți bonusul în același
                  cont bancar. Trebuie să furnizați aceste informații în timp
                  necesar și să mențineți actualizate datele din contul
                  dumneavoastră CashClub.
                </li>
              </ol>
            </div>
            <div>
              <h3>4. Bonus</h3>
              <p>
                După îndeplinirea tuturor cerințelor de catre Utilizator iar noi
                am primit de la Vânzător suma de bani corespunzătoare bonusului,
                noi vom plăti bonusul Utilizatorului în contul bancar specificat
                de acesta. Valoarea bonusului se calculează din valoarea
                cumpărăturii, fără a fi luate în considerare TVA, taxe de
                transport sau alte taxe. CashClub își rezervă dreptul de a
                decide ce procent din suma primită de la Vânzător va acorda
                Utilizatorului. Bonusul (cashback-ul) aparține în totalitate
                CashClub, iar Utilizatorul nu are drepturi asupra lui până când
                bonusul nu a fost plătit în contul bancar al Utilizatorului. Ne
                rezervăm dreptul de a solicita de la bancă suma transferată
                Utilizatorului în cazul în care Utilizatorul a încălcat termenii
                acestui contract de utilizare.
              </p>
              <p>
                Există mai multe situații în care Vânzătorul poate constata că
                nu au fost îndeplinite în mod corespunzător condițiile
                tranzacției/achiziției și, astfel, plata bonusurilor nu se va
                efectua. Există de asemenea diferite situații în care bonusul nu
                este plătit Utilizatorului iar acesta rămâne la dispoziția
                CashClub:
              </p>
              <ol>
                <li>
                  Dacă Utilizatorul face o achiziție prin CashClub fără a
                  efectua autentificarea pe site (adică, fără a introduce numele
                  de utilizator și parola)
                </li>
                <li>
                  Achiziția în urma căreia s-a primit un bonus (cashback) a fost
                  anulată după ce a fost efectuată (în limitele dreptului de
                  returnare a anumitor achiziții
                </li>
                <li>Bonusul (cashback-ul) aparține unui cont care:</li>
                <li>
                  a fost dezactivat în conformitate cu punctul 8 din prezentul
                  acord;
                </li>
                <li>
                  a încercat să fraudeze sau să încalce prezentul acord sub
                  orice formă;
                </li>
                <li>
                  a fost utilizat sau este utilizat în continuare pentru a face
                  achiziții în numele unei alte persoane sau în interesele altei
                  personae;
                </li>
                <li>nu a mai fost folosit de mai mult de 12 luni.</li>
              </ol>
            </div>
            <div>
              <h3>5. Proprietate intelectuală</h3>
              <p>
                Prin abonarea la serviciul CashClub și introducerea datelor
                personale și a altor informații, Utilizatorul este de acord cu
                următoarele condiții:
              </p>
              <ol>
                <li>
                  Utilizatorul ne oferă o licență non-exclusivă (inclusiv
                  dreptul de a distribui sub-licențe), de a utiliza, copia și
                  distribui aceste informații prin CashClub dar și alte servicii
                  interactive pe care noi sau persoanele de sub-licența noastră
                  le folosesc pentru a oferi serviciul CashClub;
                </li>
                <li>
                  Utilizatorul acordă altor utilizatori (prin noi, în baza
                  licenței de mai sus) dreptul personal, netransferabil și
                  non-exclusiv de a vedea anumite informații.Sunteți de acord și
                  ați înțeles că site-ul, mărcile comerciale și alte posibile
                  drepturi de proprietate intelectuală legate de serviciul
                  CashClub (inclusiv materialele la producția cărora au
                  contribuit utilizatorii și magazinele online partenere)
                  aparțin în totalitate CashClub sau sunt sub licența CashClub.
                  Astfel că, nimeni nu are dreptul de a copia, distribui, afișa
                  public sau dezvolta un serviciu derivat de la site-ul CashClub
                  cu excepția cazurilor în care ați primit de la noi în scris
                  dreptul expres de a face acest lucru.
                </li>
              </ol>
              <p ref={anchorRef}>
                Sunteți de acord și ați înțeles că site-ul, mărcile comerciale
                și alte posibile drepturi de proprietate intelectuală legate de
                serviciul CashClub (inclusiv materialele la producția cărora au
                contribuit utilizatorii și magazinele online partenere) aparțin
                în totalitate CashClub sau sunt sub licența CashClub. Astfel că,
                nimeni nu are dreptul de a copia, distribui, afișa public sau
                dezvolta un serviciu derivat de la site-ul CashClub cu excepția
                cazurilor în care ați primit de la noi în scris dreptul expres
                de a face acest lucru.
              </p>
            </div>
            <div>
              <h3>6. Politica de confidențialitate</h3>
              <p>
                Politica noastră de confidențialitate este parte a prezentului
                acord, și prin încheierea acestui acord cu noi sunteți de acord
                cu metodele noastre de stocare a datelor dumneavoastră
                personale, în conformitate cu politica de confidențialitate.
                Acordăm o atenție deosebită în ceea ce privește protejarea
                datelor tale personale. De aceea, alegem să îți respectăm
                intimitatea și ne dorim să ai aceeași încredere în noi în ceea
                ce privește protecția datelor cu caracter personal. Alegem să te
                informăm întotdeauna despre motivele pentru care avem nevoie de
                datele tale și despre perioada de timp pentru care le păstrăm.
                <br />
                Astfel, ai posibilitatea de a decide în ce scopuri vom avea
                permisiunea să îți utilizăm datele. Pentru a asigura cel mai
                înalt nivel de securitate, informațiile ne sunt întotdeauna
                transmise în formă criptată. Dacă dorești să nu îți mai utilizăm
                datele personale sau dacă dorești stergerea contului tau
                CashClub impreuna cu datele detinute de VAN Consulting SRL, te
                rugăm să ne informezi cu e-mail la contact@cashclub.ro
              </p>
            </div>
            <div>
              <h3>7. Rolul nostru</h3>
              <p>
                CashClub nu are niciun fel de relații comerciale cu Vânzătorii:
                nu este nici vânzător, nici furnizor de bunuri și servicii pe
                care magazinele online le oferă. Prin urmare, nu ne asumăm nicio
                obligație legală care se referă la produsele și serviciile
                oferite de Vânzători (magazinele online partenere).{" "}
              </p>
              <p>
                În baza celor de mai sus, nu putem influența și nu se asumăm
                răspunderea pentru:
              </p>
              <ol>
                <li>
                  calitatea, siguranța sau autenticitatea produselor și
                  serviciilor oferite de Vânzători;
                </li>
                <li>
                  incapacitatea de livrare a produselor și a serviciilor pe care
                  Vânzâtorii le oferă. În măsura permisă de lege, Utilizatorii
                  sunt obligați să ne elibereze pe noi, angajații noștri și alti
                  potențiali parteneri ai noștri de răspunderea pe care o
                  însoțește orice tranzacție cu magazinele online partenere,
                  inclusiv (fără limitări) toate reclamațiile și plângerile
                  legate de realizarea sau nerealizarea tranzacțiilor cu
                  Vânzătorii dar și furnizarea de bunuri și servicii, livrarea
                  lor sau orice altă activitate legată de tranzacția în cauză cu
                  magazinele online partenere.
                </li>
              </ol>
            </div>
            <div>
              <h3>8. Încălcarea termenilor acordului</h3>
              <p>
                Noi (CashClub) ne rezervăm dreptul de a suspenda sau refuza
                accesul unui Utilizator la serviciul CashClub, dacă în urma
                verificărilor pe care le facem, descoperim că Utilizatorul a
                încălcat prevederile prezentului acord. Contul de Utilizator
                poate fi folosit pentru a face cumpărături online doar în nume
                propriu și niciodată în interesul altor persoane sau în numele
                altor persoane. Utilizatorii nu pot întreprinde niciun fel de
                tranzacții cu magazinele online partenere CashClub în vederea
                obținerii bonusului dacă: a) aceștia furnizează date cu caracter
                personal ale altor persoane și utilizează metode de plată care
                nu le aparțin; b) înșeală sau exploatează în mod nejustificat
                oferta Vânzătorului; c) încalcă termenii și condițiile
                prezentului acord și a normelor stabilite de CashClub și de
                magazinele online partenere.
              </p>
              <p>
                Fiecare Utilizator este obligat să se asigure că informațiile
                din contul său CashClub:
              </p>
              <ol>
                <li>
                  nu sunt deranjante, ofensive, defăimătoare sau amenințătoare;
                </li>
                <li>
                  nu intenționează să provoace vătămare, iritare sau orice altă
                  îngrijorare unei alte persoane;
                </li>
                <li>
                  nu conțin viruși, viruși macro sau alte programe concepute să
                  întrerupă, interfereze sau perturbe funcționarea normală a
                  unui computer sau să stocheze în secret, fără autorizație,
                  orice fel de date de natură personală.
                </li>
                <li>
                  nu încalcă legea aplicabilă (inclusiv legea privind protecția
                  consumatorilor, concurența neloială, discriminare, drepturile
                  de autor, mărcile comerciale și de confidențialitate,
                  marketingul fals, dispozițiile legale privind vânzarea la
                  distanță).
                </li>
                <li>
                  nu încalcă drepturile unei persoane sau a unei entități
                  (inclusiv orice drepturi de confidențialitate).
                </li>
                <li>
                  conținutul feedback-ului către magazinele online partenere
                  este corect și precis;
                </li>
                <li>nu promovează niciun bun sau serviciu.</li>
              </ol>
            </div>
            <div>
              <h3>Relațiile cu terții</h3>
              <p>
                Dacă primim informații despre materiale false sau ilegale legate
                de contul dumneavoastră sau de numele dumneavoastră de
                utilizator, veți fi de acord să:
              </p>
              <ol>
                <li>
                  nu sunt deranjante, ofensive, defăimătoare sau amenințătoare;
                </li>
                <li>
                  vă angajați că veți răspunde clar și precis la mesaj în termen
                  de o săptămână, dacă decidem să vă punem în legătură cu
                  persoana de la care am primit informațiile respective.
                </li>
              </ol>
            </div>
            <div>
              <h3>10. Operațiile serviciului CashClub</h3>
              <p>
                Ne rezervăm întodeauna dreptul de a elimina, modifica și edita
                părți ale serviciului CashClub. Acest lucru poate apărea din
                motive legale legate de probleme de siguranță, probleme tehnice
                sau comerciale. Încercăm, dar nu promitem sau garantăm că vom
                trimite o notificare cu 30 de zile înainte de a lua o astfel de
                măsură. Excepții sunt situațiile în care măsuri anterioare sunt
                necesare din motive de siguranță sau din cauza dificultăților
                tehnice care ar avea un impact negativ asupra serviciului
                CashClub. Serviciul CashClub poate fi, de asemenea, indisponibil
                din când în când neputând fi folosit din cauza unor probleme
                tehnice ale site-ului sau ale internetului, în general. Totuși,
                încercăm să rezolvăm aceste dificultăți într-un timp cât mai
                scurt. Cu toate acestea, vă rugăm să luați în considerare faptul
                că nu putem garanta disponibilitatea continuă a seviciului
                CashClub și nici accesul la orice conținut afișat de acesta.
              </p>
              <p>
                În ciuda celor de mai sus, încercăm să ne asigurăm că momentele
                când serviciul CashClub nu este disponibil și timpul în care vă
                informăm de acest lucru sunt reduse la minimum. Din motive de
                siguranță sau alte motive, vă putem solicita să vă schimbați
                parola sau alte informații necesare atunci când vă conectați în
                contul CashClub. Cu toate acestea, nu vă vom solicita niciodată
                parola prin e-mail, telefon sau orice alt mod. Sunteți singurul
                responsabil pentru păstrarea confidențială a parolei și a
                tuturor celorlalte informații de autentificare.
              </p>
            </div>
            <div>
              <h3>11. Responsabilități</h3>
              <ol>
                <li>
                  Scopul nostru este de a furniza seviciul CashClub în cel mai
                  bun mod posibil, în scopul îndeplinirii funcției serviciului
                  CashClub, dar nu putem garanta că serviciul CashClub vă
                  îndeplinește așteptările și cerințele.
                </li>
                <li>
                  CashClub este răspunzător numai pentru cerințele specificate
                  în prezentul acord, dar nu are alt angajament, obligație sau
                  răspundere contractuală legate de daune (precum neglijență,
                  încălcarea unor obligații legale sau orice alte daune).
                </li>
                <li>
                  CashClub nu este răspunzator pentru vătămări corporale sau
                  deces care sunt rezultatul neglijenței noastre
                </li>
                <li>
                  Luând întodeauna în considerare limitările secțiunii 6 de mai
                  jos, CashClub va fi răspunzător doar pentru pierderile sau
                  pagubele directe contractuale, cererile de despăgubire
                  (inclusiv neglijență, încălcare a unei obligații legale sau
                  orice altă daună) doar în cazul în care acestea sunt
                  rezultatul acțiunilor sau lipsei de acțiune a CashClub,
                  angajaților și subcontractorilor săi. Răspunderea CashClub
                  începe din prima zi în care dumneavoastră v-ați creat un cont
                  de utilizator, și aceasta este limitată pentru o perioadă de
                  12 luni la suma de bani pe care CashClub vă datorează sub
                  formă de bonus.
                </li>
                <li>
                  Nu vom fi răspunzători contractual în fața dumneavoastră sau a
                  altei persoane legale pentru niciun tip de vătămare (inclusiv
                  neglijență, încălcare a unei obligații legale sau orice alt
                  prejudiciu) sau pentru situații precum:
                </li>
                <li>
                  pierderi de venit, pierderi de economii sau scăderea
                  profitului afacerii
                </li>
                <li>
                  orice daune, costuri sau cereri de despăgubiri directe sau
                  indirecte, chiar dacă acestea sunt rezultatul nerealizării
                  serviciului CashClub sau furnizarea acestuia cu întârziere sau
                  nerespectarea prezentului acord.
                </li>
                <li>
                  în plus față de ceea ce s-a specificat în mod clar în
                  prezentul acord, toate propunerile, garanțiile, termenii și
                  reglementările (atât explicite cât și implicite care derivă
                  din lege) sunt excluse din prezentul acord, cu excepția
                  cauzelor de fraudă când legea nu permite o astfel de
                  excludere.
                </li>
                <li>
                  din motive de claritate, CashClub nu va fi răspunzător față de
                  dumneavoastră sau oricare altă persoană pentru conținutul pe
                  care ceilalți utilizatori l-au produs despre tranzacțiile în
                  magazinele online partenere.
                </li>
                <li>
                  termenii acestei secțiuni 12 rămân în vigoare, chiar dacă
                  prezentul acord se va încheia.
                </li>
              </ol>
            </div>
            <div>
              <h3>12. Despăgubiri</h3>
              <p>
                Veți despăgubi CashClub pentru toate obligațiile, cererile de
                compensare sau orice alte costuri care pot fi cauzate sau legate
                de: a) orice încălcare a prezentului contract de către
                dumneavoastră sau b) orice tranzacție cu un magazin online
                partener.
              </p>
            </div>
            <div>
              <h3>13. Transfer</h3>
              <p>
                Ne rezervăm dreptul unilateral de a transfera parțial sau
                integral prezentul contract către o terță parte în vederea
                impunerii sau atribuirii unui subcontractor toate sau unele din
                drepturile și obligațiile prezentului contract. De asemenea,
                putem să transferăm acest acord astfel încât garanțiile oferite
                dumneavoastră în temeiul acestui acord să poată fi reduse. Nu
                aveți dreptul să transferați drepturile și obligațiile
                dumneavoastră către un subcontractor sau să încheiați prezentul
                acord fără acordul scris oferit în prealabil de CashClub.
              </p>
            </div>
            <div>
              <h3>14. Încheierea contractului</h3>
              <p>
                Utilizatorul poate înceta utilizarea serviciului CashClub în
                orice moment. CashClub poate rezilia, în orice moment, în mod
                unilateral prezentul contract în cazul în care constată că
                Utilizatorul acționează împotriva principiilor și intereselor
                CashClub. Încheierea contractului va intra în vigoare imediat și
                în acest caz, Utilizatorul va pierde toate bonusurile
                (cashback-urile) primite până în momentul de față din partea
                CashClub. În acest caz, CashClub va avea dreptul de a împiedica
                Utilizatorul să utilizeze serviciul CashClub în viitor.
              </p>
            </div>
            <div>
              <h3>15. Scopul contractului</h3>
              <p>
                Scopul acestui contract reflectă acordul dumneavoastră cu
                privire la serviciul CashClub. Credem că este corect și
                rezonabil. Acesta va înlocui toate acordurile și înțelegerile
                dumneavoastră anterioare în legătură cu serviciul CashClub, cu
                excepția cazurilor de fraudă pe care le-ați comis sau
                informațiilor incorecte pe care le-ați furnizat.
              </p>
            </div>
            <div>
              <h3>16. Revizuirea contractului</h3>
              <p>
                Ne rezervăm dreptul de a revizui prezentul acord din când în
                când și de a afișa o nouă versiune pe site-ul CashClub. După ce
                această nouă versiune este publicată pe site-ul nostru, noua
                versiune a termenilor și condițiilor va intra imediat în
                vigoare, și din acel moment relația dintre dumneavoastră și
                CashClub se va schimba astfel:
              </p>
              <ol>
                <li>
                  Dacă oricare dintre modificările făcute acestui acord vă poate
                  afecta în mod negativ, aveți la dispoziție trei zile
                  calendaristice de la afișarea noii versiunii pentru a
                  reacționa. Dacă nu sunteți de acord cu noile modificări ale
                  contractului, trebuie să ne notificați acest lucru în cursul
                  acestor trei zile calendaristice, și în consecință, trebuie să
                  întrerupeți în întregime utilizarea serviciului CashClub.
                </li>
                <li>
                  În cazul în care revizuirile nu privesc secțiunile operative
                  ale prezentului acord sau nu vă afectează în mod negativ
                  (inclusiv, fără limitări, modificările aduse informațiilor de
                  contact sau secțiunile de editare deja incluse în acest
                  acord); revizuirile intră întodeauna în vigoare imediat după
                  ce noua versiune este adăugată pe site (sau ulterior după ce
                  data a fost specificată în noua versiune a contractului).
                </li>
              </ol>
            </div>
            <div>
              <h3>17. General</h3>
              <p>
                În cazul în care o secțiune a prezentului acord nu este
                considerată valabilă din punct de vedere legal, prezentul acord
                va rămâne în vigoare și va fi aplicabil. Dumneavoastră, în
                calitate de Utilizator și noi, CashClub, suntem două entități
                independente, iar scopul acestui acord nu este de a avea o
                relație de parteneriat sau de angajat - angajator. Dacă într-o
                anumită situație, nu răspundem sau nu acționăm în vreun fel în
                ceea ce privește încălcarea contractului sau a anumitor părți
                ale acestuia, acest lucru nu exclude dreptul nostru de a acționa
                în consecință pe viitor.
              </p>
            </div>
            <div>
              <h3>18. Legea aplicabilă</h3>
              <ol>
                <li>
                  Acest acord și relația dintre dumneavoastră, în calitate de
                  Utilizator și noi, CashClub vor fi supuse legilor din România.
                </li>
                <li>
                  Atât dumneavoastră, Utilizator, cât și noi, CashClub, vom fi
                  supuși autorității judiciare competente din România în ceea ce
                  privește litigiile care decurg din prezentul acord.
                </li>
              </ol>
            </div>
            <div>
              <h3>19. Stocarea acestui contract</h3>
              <p>
                Nu arhivăm separat contracte individuale pe care utilizatorii le
                încheie atunci când se înregistrează pe CashClub. Puteți să îl
                vizualizați pe adresa www.cashclub.ro. Vă solicităm să faceți o
                copie permanentă a acestui acord, să îl printați și să salvați o
                copie în computerul dumneavoastră. Acordul există doar în limba
                română. Informații de contact Adresa la care ne puteți scrie
                pentru orice problemă pe care o aveți legată de contul
                dumneavoastră CashClub este afisata in cadrul sectiunii contact
                a site-ului www.cashclub.ro. Vă rugăm să rețineți că toate
                contactele (inclusiv notificările oficiale) care intră sub
                incidența acestui acord trebuie trimise și primite prin e-mail
                la aceasta adresa de e-mail. Noi vă vom trimite mesajul la
                adresa de e-mail pe care ați indicat-o când v-ați inregistrat ca
                utilizator sau pe care o specificați.
              </p>
            </div>
            <div>
              <h3>Informații de contact</h3>
              <p>
                Adresa la care ne puteți scrie pentru orice problemă pe care o
                aveți legată de contul dumneavoastră CashClub este afisata in
                cadrul sectiunii contact a site-ului www.cashclub.ro. Vă rugăm
                să rețineți că toate contactele (inclusiv notificările oficiale)
                care intră sub incidența acestui acord trebuie trimise și
                primite prin e-mail la aceasta adresa de e-mail. Noi vă vom
                trimite mesajul la adresa de e-mail pe care ați indicat-o când
                v-ați inregistrat ca utilizator sau pe care o specificați.
              </p>
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

export default TermasAndConditions;
