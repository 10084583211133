import React from "react";
import { Helmet } from "react-helmet";

import step1 from "../../assets/images/data-deletion/step1.jpg";
import step2 from "../../assets/images/data-deletion/step2.jpg";
import step3 from "../../assets/images/data-deletion/step3.jpg";
import step4 from "../../assets/images/data-deletion/step4.jpg";
import step5 from "../../assets/images/data-deletion/step5.jpg";

import "./DataDeletion.css";

const DataDeletion = () => {
  return (
    <>
      <Helmet>
        <meta charSet="utf-8" />
        <title>Stergerea datelor din Facebook</title>
      </Helmet>
      <section className="cb-data-deletion">
        <div className="cb-container small-padding">
          <div className="cb-data-deletion-wrapper">
            <div>
              <h1>Stergerea datelor din Facebook</h1>
              <p>
                Daca doriti stergerea datelor salvate in aplicatia Facebook in
                urma autentificarii cu contul de Facebook, va rugam urmariti
                pasii de mai jos.
              </p>
              <h3>Pasi rapizi:</h3>
              <p>
                Acceseaza link-ul{" "}
                <a
                  href=" https://www.facebook.com/settings?tab=applications&ref=settings"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  https://www.facebook.com/settings?tab=applications&ref=settings
                </a>
                , selecteaza si sterge aplicatia CashClub.
              </p>
              <h3>Pasi detaliati:</h3>
            </div>
            <div>
              <h3>1. Setari si Confidentialitate</h3>
              <p>
                Mergi in contul Facebook cu care te-ai autentificat in site-ul
                CashClub si acceseaza meniul de optiuni din drepta sus a
                paginii. Din meniul accesat selectati optiunea Setari si
                Confidentialitate.
              </p>
              <div className="step-image">
                <img src={step1} alt="logo" />
              </div>
            </div>
            <div>
              <h3>2. Setari</h3>
              <p>Din meniul Setari si Confidentialitate selecteaza Setari.</p>

              <div className="step-image">
                <img src={step2} alt="logo" />
              </div>
            </div>
            <div>
              <h3>3. Aplicatii si site-uri web</h3>
              <p>
                Din pagina Setari, in meniul din stanga paginii selecteaza
                Aplicatii si site-uri web.
              </p>
              <div className="step-image">
                <img src={step3} alt="logo" />
              </div>
            </div>
            <div>
              <h3>4. CashClub app</h3>
              <p>
                In pagina Aplicatii si site-uri web, cauta aplicatia CashClub si
                selecteaza butonul Sterge asociat aplicatiei.
              </p>
              <div className="step-image">
                <img src={step4} alt="logo" />
              </div>
            </div>
            <div>
              <h3>5. Stergere aplicatie</h3>
              <p>
                Cand fereastra de stergere a aplicatiei se afizeaza, asigura-te
                ca ai bifat sau debifat optiunile dorite si selecteaza butonul
                Sterge.
              </p>
              <div className="step-image">
                <img src={step5} alt="logo" />
              </div>
            </div>
            <div>
              <h3>Felicitari! Ai sters datele cu succes!</h3>
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

export default DataDeletion;
