import React, { useState } from "react";
import { CouponModal, CurlingCorner, Image } from "../../../../components";

import "./CouponCard.css";

export default function CouponCard({ data }) {
  const [toggleModal, setToggleModal] = useState(false);

  const handleModal = () => {
    if (!toggleModal) {
      setToggleModal(true);
      document.getElementsByTagName("html")[0].className = "menu-no-scroll";
    } else {
      setToggleModal(false);
      document.getElementsByTagName("html")[0].className = "";
    }
  };

  return (
    <>
      {
        <div className="cb-coupons-card" onClick={handleModal}>
          <div className="card-header">
            <Image src={data.shopLogo} alt="Coupon" />
          </div>
          <div className="card-body">
            <p>{data.title}</p>
          </div>
          <div className="card-footer">
            <div className="poupone-code-partial">
              <CurlingCorner data={data} />
            </div>
          </div>
        </div>
      }
      {toggleModal && <CouponModal data={data} handleModal={handleModal} />}
    </>
  );
}
