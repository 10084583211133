import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { getCouponsShops } from "../../../store/actions/categoriesActions";

import { filterCoupons } from "../../../store/actions/couponsActions";

const FilterCoupons = (props) => {
  /* Get Categories */

  const { couponsShopsInfo } = useSelector((state) => state.ccCategories);

  const couponsShopsPageUrl = useSelector(
    (state) => state.ccCategories.couponsShopsInfo.firstPageUrl
  );

  const couponsShops = useSelector(
    (state) => state.ccCategories.couponsShopsInfo.data
  );

  /* Get Pages State */
  const capitalizedPageName =
    props.pageName.charAt(0).toUpperCase() + props.pageName.slice(1);

  const { filterKeyword, sortKeyword, isLoading, error } = useSelector(
    (state) => state[`cc${capitalizedPageName}`]
  );
  const { firstPageUrl } = useSelector(
    (state) => state[`cc${capitalizedPageName}`][`${props.pageName}Info`]
  );

  const oldFilterKeyord = filterKeyword ? filterKeyword : null

  /* Update State */

  const dispatch = useDispatch();

  const handleChange = (e) => {
    /* Reset error */
    if (error !== false) {
      dispatch({
        type: "ERROR_COUPONS",
        payload: {},
      });
    }
    /* End of reset */

    let filterData = `&filters[shopId]=${e.target.value}`;
    let withFilterExpire = `&filters[expireSoon]=1&sortby=end_date&sortdir=asc`
    let noFilterExpire = `&sortby=end_date&sortdir=asc`

    if (!isLoading && filterData !== "&filters[shopId]=all") {
     

      if ((!filterKeyword || oldFilterKeyord !== filterData) && sortKeyword !== withFilterExpire) {
        dispatch(filterCoupons(filterData, firstPageUrl, sortKeyword));
      } else if(sortKeyword === `&filters[expireSoon]=1&sortby=end_date&sortdir=asc`){
        dispatch(filterCoupons(filterData, firstPageUrl, `&sortby=end_date&sortdir=asc`));
      }
    } else if (filterKeyword) {
      dispatch(filterCoupons("", firstPageUrl, sortKeyword === noFilterExpire ? withFilterExpire : sortKeyword ));
    }
  };

  useEffect(() => {
    if (!couponsShopsInfo.data) {
      dispatch(getCouponsShops(couponsShopsPageUrl));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const getFilterValue = new URLSearchParams(oldFilterKeyord).get("filters[shopId]") || "all"

  return (
    <div className="cc-filter">
      <select
        name="filter"
        id="filter-coupons"
        //defaultValue={"DEFAULT"}
        onChange={(e) => handleChange(e)}
        value={getFilterValue}
      >
        <option value="all">Toate cupoanele</option>
        {couponsShops
          ? couponsShops
              .sort((a, b) => a.trimmedDomain.localeCompare(b.trimmedDomain))
              .map((couponShop, idx) => (
                <option key={idx} value={couponShop.id}>
                  {couponShop.trimmedDomain}
                </option>
              ))
          : null}
      </select>
    </div>
  );
};

export default FilterCoupons;