import React from "react"
import { Image, ResponsiveComponent } from "../../../components";

import "./ShopsCard.css";

export default function ShopsCard({data}){
  return (
    <>
      <ResponsiveComponent showOnDesktop showOnTablet>
        <div className="shops-card">
          <div className="shops-card-logo">
            <Image src={data.logo} alt="shop" />
          </div>
          <span></span>
          <p>{`${data.cashbackValue ? data.cashbackValue : data.discount && data.discount } `} cashback
          </p>
        </div>
      </ResponsiveComponent>

      <ResponsiveComponent showOnMobile>
        <div className="shops-card">
          <div className="shops-card-logo">
            <Image src={data.logo} alt="shop" />
          </div>
          <span></span>
          <p>{`${data.cashbackValue ? data.cashbackValue : data.discount && data.discount} `} cashback</p>
        </div>
      </ResponsiveComponent>
  </>
  )
}