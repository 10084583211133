import React, { useState, useEffect, useMemo } from "react";
import { Helmet } from "react-helmet";
import { useDispatch, useSelector } from "react-redux";
import { Link } from "react-router-dom";
import { isIOS } from "react-device-detect";
import FacebookLogin from "react-facebook-login";
import {
  userLogin,
  userFacebookLogin,
  userFacebookLoginApp,
} from "../../store/actions/userActions";
import { LoadingSpinner, ModalPermissions } from "../../components";

import "./Login.css";

const Login = (props) => {
  /* Get State */
  const { isLoading, errorLogin, login } = useSelector((state) => state.ccUser);

  /* Update state */
  const dispatch = useDispatch();

  const handleResetError = () => {
    dispatch({
      type: "ERROR_USER_LOGIN",
      payload: {
        error: null,
      },
    });
  };

  const showModal = useMemo(() => {
    let modalState = false;
    if (errorLogin === "deniedPermission") {
      modalState = true;
      document.getElementsByTagName("html")[0].className = "menu-no-scroll";
    } else {
      modalState = false;
      document.getElementsByTagName("html")[0].className = "";
    }
    return modalState;
  }, [errorLogin]);

  const [credentials, setCredentials] = useState({
    email: "",
    password: "",
  });

  const handleChange = (e) => {
    setCredentials({
      ...credentials,
      [e.target.name]: e.target.value,
    });
  };

  const handleLogin = (e) => {
    e.preventDefault();
    if (errorLogin) {
      dispatch({
        type: "ERROR_USER_LOGIN",
        payload: {
          error: null,
        },
      });
    }
    dispatch(userLogin(props, credentials));
  };

  /* Facebook login */

  const responseFacebook = (response) => {
    const { accessToken } = response;
    window.FB.api("/me/permissions", function (response) {
      const { data } = response;
      dispatch(userFacebookLogin(props, accessToken, data));
    });
  };

  /* Facebook login for webview*/

  const isApp = localStorage.getItem("ccQuery");

  const handelWebViewLoginTrigger = () => {
    window.ReactNativeWebView.postMessage("triggerFbLogin");
  };

  const handelWebViewLogin = (fbApiResponse) => {
    dispatch(userFacebookLoginApp(props, fbApiResponse));
  };

  const messageListener = (message) => {
    if (message && message?.data?.indexOf("CCfbLogListener") >= 0) {
      const fbApiResponse = JSON.parse(message.data);
      handelWebViewLogin(fbApiResponse);
    }
  };

  useEffect(() => {
    if (isApp === "?platform=android&version=1.0.7") {
      document.addEventListener("message", messageListener);
    }

    if (errorLogin) {
      handleResetError();
    }

    return () => {
      document.removeEventListener("message", messageListener);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isApp]);

  return (
    <>
      <Helmet>
        <meta charSet="utf-8" />
        <title>Login</title>
      </Helmet>
      <section className="cc-login">
        <div className="cb-container small-padding">
          <div className="cc-login-wrapper">
            <div className="login">
              <h2 className="cc-login-title">CashClub</h2>

              {isLoading ? (
                <LoadingSpinner white padding={"2"} width={"15"} />
              ) : showModal ? (
                <ModalPermissions handleModal={handleResetError} />
              ) : errorLogin && errorLogin === "Unauthorized" ? (
                <div className="cc-error-messages">
                  <p>
                    Email sau parola gresita, incearca iar sau recupereaza
                    parola
                  </p>
                </div>
              ) : errorLogin && errorLogin !== "Unauthorized" ? (
                <div className="cc-error-messages">
                  <p>
                    A aparut o eroare, reincarca pagina sau incearca mai tarziu
                  </p>
                </div>
              ) : (
                !errorLogin &&
                login &&
                login.data.errors && (
                  <div className="cc-error-messages">
                    <p>{login.data.errors}</p>
                  </div>
                )
              )}

              <form onSubmit={(e) => handleLogin(e)}>
                <input
                  type="email"
                  name="email"
                  placeholder="Email"
                  required
                  onChange={(e) => handleChange(e)}
                  style={{
                    boxShadow: `${
                      errorLogin && errorLogin === "Unauthorized"
                        ? "0 2px 12px 0 rgb(255 0 0 / 42%)"
                        : "0 2px 12px 0 rgba(0,0,0,0.1)"
                    }`,
                  }}
                />
                <input
                  type="password"
                  name="password"
                  placeholder="Parola"
                  required
                  onChange={(e) => handleChange(e)}
                  style={{
                    boxShadow: `${
                      errorLogin && errorLogin === "Unauthorized"
                        ? "0 2px 12px 0 rgb(255 0 0 / 42%)"
                        : "0 2px 12px 0 rgba(0,0,0,0.1)"
                    }`,
                  }}
                />
                <div>
                  <div className="terms-wrapper">
                    <p>
                      Prin continuarea navigarii iti exprimi acordul pentru:{" "}
                      <Link to="/terms-and-conditions">
                        Termeni si Conditii
                      </Link>
                      <Link to="/terms-and-conditions?privacy-policy">
                        Politica de confidentialitate
                      </Link>
                    </p>
                  </div>
                  <button
                    type="submit"
                    value="Submit"
                    name="submit"
                    className="btn primary black login"
                  >
                    Autentificare
                  </button>
                </div>
              </form>
              {!isIOS &&
                (isApp ? (
                  <button
                    className="btn primary blue login"
                    onClick={() => handelWebViewLoginTrigger()}
                  >
                    Logare cu Facebook
                  </button>
                ) : (
                  <FacebookLogin
                    appId="307194730568257"
                    fields="name,email,picture"
                    autoLoad={false}
                    callback={responseFacebook}
                    textButton="Login with Facebook"
                    cssClass="btn primary blue login"
                    isMobile={false}
                    scope="public_profile,email"
                    authType={"reauthorize"}
                  />
                ))}

              <Link to="/register" className="btn outline black">
                Cont nou
              </Link>
              <Link to="/login/forgotten-password" className="grey">
                Mi-am uitat parola
              </Link>
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

export default Login;
