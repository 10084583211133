import React from "react";
import Helmet from "react-helmet";
import { Link } from "react-router-dom";

import "./ThankYou.css";

const ThankYou = () => {
  return (
    <>
      <Helmet>
        <meta charSet="utf-8" />
        <title>Thank You</title>
      </Helmet>
      <section className="cc-thank-you">
        <div className="cb-container small-padding">
          <div className="cc-thank-you-wrapper">
            <h1>Multumim!</h1>
            <h3>Te-ai inregistrat cu succes</h3>
            <div className="thank-you-check"></div>
            <p>
              Un email pentru confirmare a contului a fost trimis catre adresa
              ta.
            </p>
            <p>Confirma contul accesand linkul din email.</p>
            <Link to="/login" className=" btn primary green">
              Autentificare
            </Link>
          </div>
        </div>
      </section>
    </>
  );
};

export default ThankYou;
