import React, { useEffect } from "react";
import { Link } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { getShops, filterShops } from "../../../store/actions/shopsActions";
import ShopsCard from "../shops-card/ShopsCard";
// import auth from "../../../helpers/authentication";

import { Search, MoreArticlesBtn, LoadingSpinner, Filter } from "../../../components";

import "./ShopsListing.css";

const ShopsListing = () => {
  /* Get State */
  const { error, shops, filterKeyword, isLoading } = useSelector((state) => state.ccShops);
  const { firstPageUrl, nextPageUrl } = useSelector((state) => state.ccShops.shopsInfo);

  // const { isAuthenticated } = useSelector((state) => state.ccAccount);

  /* Update State Data */

  const dispatch = useDispatch();

  const handleMoreShops = (e) => {
    e.preventDefault();
    if (isLoading === false) {
      if (!filterKeyword) {
        dispatch(getShops(nextPageUrl));
      } else if (filterKeyword) {
        dispatch(filterShops(JSON.parse(filterKeyword), nextPageUrl));
      }
    }
  };

  useEffect(() => {
    if (error !== false) {
      dispatch({
        type: "ERROR_SHOPS",
        payload: {},
      });
    }
    if (!shops.length || error !== false) {
      dispatch(getShops(firstPageUrl));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  /* Is authenticated */
  // const isActive = auth.isAuthenticated() && isAuthenticated;

  return (
    <section className="shops-listing">
      <div className="cb-container small-padding">
        <div className="shops-wrapper">
          <div className="shops-hero">
            <h1>Magazine partenere</h1>
            <p>
              Exploreaza noi produse si servicii in cele 800 de magazine partenere si bucura-te de bonusurile obtinute, cu fiecare
              sesiune de cumparaturi online.
            </p>
            <div className="sorting-group">
              <Search pageName={"shops"} placeholder={"Cauta magazine..."} />
              <Filter pageName={"shops"} />
            </div>
          </div>

          {
            isLoading ? (
              <LoadingSpinner padding={"2"} width={"10"} />
            ) : error ? (
              <div className="cc-error-messages">
                <p>A aparut o eroare, reincarca pagina sau incearca mai tarziu</p>
              </div>
            ) : !shops.length && filterKeyword ? (
              <div>Nu au fost gasite rezultate pentru aceasta cautare</div>
            ) : shops.length ? (
              <div className="shops-list">
                {shops.map((shop, idx) => (
                  <div key={idx}>
                    <Link to={`/shops/${shop.detailsId}`}>
                      <ShopsCard data={shop} />
                    </Link>
                  </div>
                ))}
              </div>
            ) : null
            // ) : shops.length &&
            //   <div className="shops-list">
            //     {shops.map((shop, idx) => (
            //       <div key={idx}>
            //         <InactiveCardOverlay shopName={shop.name}>
            //           <ShopsCard data={shop}/>
            //         </InactiveCardOverlay>
            //       </div>
            //     ))}
            //   </div>
          }

          <MoreArticlesBtn
            loading={isLoading}
            title={"Mai multe magazine"}
            nextPageUrl={nextPageUrl}
            onClick={(e) => handleMoreShops(e)}
          />
        </div>
      </div>
    </section>
  );
};

export default ShopsListing;
