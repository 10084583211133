import React from 'react';
import { Helmet } from 'react-helmet';
import { Link } from 'react-router-dom';
import './404.css';

const PageNotFound = () => {
  return (
    <>
      <Helmet>
        <meta charSet='utf-8' />
        <title>404</title>
      </Helmet>
      <section className='cb-404'>
        <div className='cb-container medium-padding'>
          <div className='cb-404-wrapper'>
            <div>
              <span>404</span>
              <p>Aceasta pagina nu exista sau a fost mutata la o alta adresa</p>
              <Link to='/' className='btn primary green'>
                Catre prima pagina
              </Link>
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

export default PageNotFound;
