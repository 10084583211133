import React from "react";

import "./WarningModalSettings.css";

import img from "../../../assets/icons/complain.png";
import settingActive from "../../../assets/images/modals/settingActive.jpg";

export default function WarningModalSettings({ handleModal }) {
  const handleStetting = () => {
    if (window.ReactNativeWebView) {
      window.ReactNativeWebView.postMessage("open-settings");
    }
    window.localStorage.setItem("cc_iOS_Settings", true);
    handleModal();
  };
  return (
    <div className="cc-warning-modal-settings">
      <span
        onClick={handleModal}
        className="cc-warning-modal-settings-close hide-mobile"
      ></span>

      <div className="cc-warning-modal-settings-body">
        <span
          onClick={handleModal}
          className="cc-warning-modal-settings-close hide-tablet hide-desktop"
        ></span>
        <div className="cc-warning-modal-settings-wrapper">
          <div className="cc-warning-modal-settings-image">
            <img src={img} alt="info-icon" />
          </div>
          <div className="cc-warning-modal-settings-content">
            <p>
              Permite aplicatiei CashClub sa urmareasca de unde faci cumparaturi
              pentru a primi cashback
            </p>

            <img src={settingActive} alt="ios settings" />

            <button
              className="btn primary green hide-mobile"
              onClick={handleStetting}
            >
              Catre setari
            </button>
          </div>
          <button
            className="btn primary green hide-tablet hide-desktop"
            onClick={handleStetting}
          >
            Catre setari
          </button>
        </div>
      </div>
      <div
        className="cc-warning-modal-settings-underlay"
        onClick={handleModal}
      ></div>
    </div>
  );
}
