import React from "react";

import "./CurlingCorner.css";

export default function CurlingCorner({ data }) {
  const animDelay = 1 + Math.random() * 10;
  const animDuration = 7;
  return (
    <div className="cb-curling-corner">
      <div className="cb-curling-wrapper">
        <div className="curling-content">
          <p className="content-top-text">Afiseaza codul</p>
          <p className="content-bottom-text">{data.code.substring(2)}</p>
        </div>
        <div
          className="curling-overlay"
          style={{
            animationDuration: `${animDuration}s`,
            animationDelay: `${animDelay}s`,
          }}
        >
          <div
            className="curling-overlay-corner"
            style={{
              animationDuration: `${animDuration}s`,
              animationDelay: `${animDelay}s`,
            }}
          ></div>
        </div>
      </div>
    </div>
  );
}
