import React from "react";

import "./WarningModal.css";

import img from "../../../assets/icons/complain.png";

export default function WarningModal({ handleModal }) {
  return (
    <div className="cc-warning-modal">
      <span
        onClick={handleModal}
        className="cc-warning-modal-close hide-mobile"
      ></span>

      <div className="cc-warning-modal-body">
        <span
          onClick={handleModal}
          className="cc-warning-modal-close hide-tablet hide-desktop"
        ></span>
        <div className="cc-warning-modal-wrapper">
          <div className="cc-warning-modal-image">
            <img src={img} alt="info-icon" />
          </div>
          <div className="cc-warning-modal-content">
            <p>
              Pentru a te asigura ca vei primi bonusul aferent achizitiei tale,
              te rugam daca folosesti Safari sa mergi in Settings si sa te
              asiguri ca ai oprit:
            </p>

            <p>a) Prevent cross-site tracking</p>

            <p>b) Block all cookies</p>

            <button
              className="btn primary green hide-mobile"
              onClick={handleModal}
            >
              Am inteles!
            </button>
          </div>
          <button
            className="btn primary green hide-tablet hide-desktop"
            onClick={handleModal}
          >
            Am inteles!
          </button>
        </div>
      </div>
      <div className="cc-warning-modal-underlay" onClick={handleModal}></div>
    </div>
  );
}
