import React from "react";
import { withRouter } from "react-router";
import { VerticalNav } from "../../../components";
import instagram from "../../../assets/images/footer/instagram.png";
import facebook from "../../../assets/images/footer/facebook.png";
import linkedin from "../../../assets/images/footer/linkedin.png";

// import Newsletter from './newsletter/Newsletter'

import "./Footer.css";
import { Link } from "react-router-dom";

function Footer(props) {
  const frequentQuestions = [
    { name: "Cum functioneaza?", link: "/frequent-questions", question: 5 },
    {
      name: "In cat timp primesc banii in cont?",
      link: "/frequent-questions",
      question: 11,
    },
    {
      name: "Se cumuleaza cu reducerile?",
      link: "/frequent-questions",
      question: 12,
    },
    { name: "Cum imi fac cont?", link: "/frequent-questions", question: 10 },
  ];

  const infoLinks = [
    { name: "Ce este cashback?", link: "/cashback" },
    { name: "Termeni si conditii", link: "/terms-and-conditions" },
    { name: "Contact", link: "/contact" },
  ];

  const copyright = new Date().getFullYear();

  if (
    props.location.pathname !== "/login" &&
    props.location.pathname !== "/login/forgotten-password" &&
    props.location.pathname !== "/register" &&
    props.location.pathname !== "/thank-you"
  ) {
    return (
      <>
        {/* <Newsletter */}
        <footer className="footer">
          <div className="cb-container medium-padding">
            <div className="footer-wrapper">
              <div className="footer-info hide-mobile hide-tablet">
                <h2>Despre CashClub</h2>
                <p>
                  Descarca extensia noastra pentru browser si CashClub iti da o
                  parte din banii pe care ii cheltuiesti online inapoi.
                </p>
              </div>
              <nav className="footer-nav hide-mobile">
                <h2>Intrebari frecvente</h2>
                <VerticalNav links={frequentQuestions} />
              </nav>
              <nav className="footer-nav hide-mobile">
                <h2>Link-uri utile</h2>
                <VerticalNav links={infoLinks} />
              </nav>
              {props.location.pathname !== "/" && (
                <div className="footer-social">
                  <a
                    href=" https://www.instagram.com/cashclub.ro/"
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    <img src={instagram} alt="instagram" />
                  </a>
                  <a
                    href="https://www.facebook.com/cashclub.ro/"
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    <img src={facebook} alt="facebook" />
                  </a>
                  <a
                    href="https://www.linkedin.com/company/cashclub/about/"
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    <img src={linkedin} alt="linkedin" />
                  </a>
                </div>
              )}
              <div className="footer-copyright hide-desktop hide-tablet">
                {props.location.pathname === "/" ? (
                  <>
                    <Link to="frequent-questions">Intrebari frecvente</Link>

                    <a
                      href="https://anpc.ro/"
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      ANPC
                    </a>
                    <p>VAN CONSULTING SERVICES S.R.L.</p>
                    <p>CUI: 39743787</p>
                    <p>
                      Copyright {copyright} <span>CashClub</span>
                    </p>
                  </>
                ) : (
                  <>
                    <a
                      href="https://anpc.ro/"
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      ANPC
                    </a>
                    <p>VAN CONSULTING SERVICES S.R.L.</p>
                    <p>CUI: 39743787</p>
                    <p>
                      Copyright {copyright} <span>CashClub</span>
                    </p>
                  </>
                )}
              </div>
            </div>
          </div>
        </footer>
      </>
    );
  } else {
    return null;
  }
}

export default withRouter(Footer);
