// import axios from "axios";
import { apiGet } from "../../helpers/api";
// import auth from "../../helpers/authentication";

/* Get User Info */

export const getUserInfo = (props) => async (dispatch) => {
  try {
    dispatch({
      type: "AWAITING_ACCOUNT",
    });

    const { data } = await apiGet("/accounts");

    dispatch({
      type: "SUCCESS_USER_INFO",
      payload: {
        data,
      },
    });
  } catch (e) {
    dispatch({
      type: "ERROR_ACCOUNT",
      payload: { e },
    });
  }
};

/* Get User History */

export const getUserHistory = (props) => async (dispatch) => {
  try {
    dispatch({
      type: "AWAITING_ACCOUNT_HISTORY",
    });

    const { data } = await apiGet("/accounts/cashback-history");

    dispatch({
      type: "SUCCESS_USER_HISTORY",
      payload: {
        data,
      },
    });
  } catch (e) {
    dispatch({
      type: "ERROR_ACCOUNT",
      payload: { e },
    });
  }
};

/* Get Payment History */

export const getPaymentHistory = () => async (dispatch) => {
  try {
    dispatch({
      type: "AWAITING_PAYMENT_HISTORY",
    });

    const { data } = await apiGet("/accounts/payments");

    dispatch({
      type: "SUCCESS_PAYMENT_HISTORY",
      payload: {
        data,
      },
    });
  } catch (e) {
    dispatch({
      type: "ERROR_PAYMENT_ACCOUNT",
      payload: { e },
    });
  }
};

/* Get Payment Details */

export const getPaymentDetails = (paymentId) => async (dispatch) => {
  try {
    dispatch({
      type: "AWAITING_PAYMENT_DETAILS",
    });

    const { data } = await apiGet(`/accounts/payments/${paymentId}`);

    dispatch({
      type: "SUCCESS_PAYMENT_DETAILS",
      payload: {
        data,
        paymentId,
      },
    });
  } catch (e) {
    dispatch({
      type: "ERROR_PAYMENT_DETAILS",
      payload: { e },
    });
  }
};
