import axios from "axios";

/* Get Products */

export const getProducts = (pageUrl) => async (dispatch) => {
  try {
    dispatch({
      type: "AWAITING_PRODUCTS",
    });

    const response = await axios.get(`${pageUrl}&perPage=20`);

    const { firstPageUrl, nextPageUrl, prevPageUrl, data } = response.data;

    dispatch({
      type: "SUCCESS_GET_PRODUCTS",
      payload: {
        firstPageUrl,
        nextPageUrl,
        prevPageUrl,
        data,
      },
    });
  } catch (e) {
    dispatch({
      type: "ERROR_PRODUCTS",
      payload: {
        e,
      },
    });
  }
};

/* Filter Products */

export const filterProducts = (filterKeyword, pageUrl) => async (dispatch) => {
  try {
    dispatch({
      type: "AWAITING_PRODUCTS",
    });

    const response = await axios.post(`${pageUrl}&perPage=20`, filterKeyword);

    const { firstPageUrl, nextPageUrl, data } = response.data;
    const filterKey = response.config.data;

    dispatch({
      type: "SUCCESS_FILTER_PRODUCTS",
      payload: {
        firstPageUrl,
        nextPageUrl,
        data,
        filterKey,
      },
    });
  } catch (e) {
    dispatch({
      type: "ERROR_PRODUCTS",
      payload: {
        e,
      },
    });
  }
};
