import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { LoadingSpinner, ResponsiveComponent } from "../../../../components";
import { getPaymentHistory } from "../../../../store/actions/accountActions";
import { useDispatch, useSelector } from "react-redux";

import "./AccountPaymentHistory.css";

const AccountPaymentHistory = () => {
  /* Get State */
  const {
    userPaymentHistory,
    paymentIsLoading,
    isLoading,
    errorPayment,
  } = useSelector((state) => state.ccAccount);

  const [paginateHistory, setPaginateHistory] = useState(5);

  /* Update State */
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(getPaymentHistory());

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  /* Paginate */

  const handleMoreHistory = (e) => {
    e.preventDefault();
    setPaginateHistory(() => paginateHistory + 5);
  };

  return (
    <section className="user-payment">
      <div className="cb-container small-padding">
        <div className="user-payment-wrapper">
          <h2 className="user-payment-title">Istoric plati</h2>

          {isLoading || paymentIsLoading ? (
            <LoadingSpinner padding={"2"} width={"10"} />
          ) : errorPayment && !userPaymentHistory ? (
            <div className="cc-error-messages">
              <p>A aparut o eroare, reincarca pagina sau incearca mai tarziu</p>
            </div>
          ) : userPaymentHistory &&
            !errorPayment &&
            userPaymentHistory.length ? (
            <>
              <div className="history-separator hide-tablet hide-desktop"></div>
              <ResponsiveComponent showOnDesktop>
                <div className="user-payments-list">
                  {userPaymentHistory &&
                    userPaymentHistory
                      .slice(0, paginateHistory)
                      .map((item, idx) => (
                        <div key={idx} className="user-payment-list">
                          <div>
                            <h3>Id</h3>
                            <span>{item.id}</span>
                          </div>
                          <div>
                            <h3>Data solicitarii</h3>
                            <span>{item.created_at}</span>
                          </div>
                          <div>
                            <h3>Status plata</h3>
                            <span style={{ color: "#7cc961" }}>
                              {item.status === "paid" ? "platit" : item.status}
                            </span>
                          </div>
                          <div>
                            <h3>Suma estimata</h3>
                            <span>{item.payment_total} Lei</span>
                          </div>
                          <div>
                            <h3>Ultima modificare</h3>
                            <span>{item.updated_at}</span>
                          </div>
                          <div>
                            <Link
                              to={{
                                pathname: "/account/payment-details",
                                paymentId: item.id,
                              }}
                              className="cc-account-actions-btn"
                            >
                              Detalii
                            </Link>
                          </div>
                        </div>
                      ))}
                </div>
              </ResponsiveComponent>

              <ResponsiveComponent showOnMobile>
                <div className="user-mobile-payment-list">
                  {userPaymentHistory &&
                    userPaymentHistory
                      .slice(0, paginateHistory)
                      .map((item, idx) => (
                        <div key={idx} className="user-payment-list">
                          <div>
                            <h3>Data solicitarii</h3>
                            <span>{item.created_at}</span>
                            <span>
                              {item.status === "paid" ? "platit" : item.status}
                            </span>
                          </div>

                          <div>
                            <h3>Suma estimata</h3>
                            <span>{item.payment_total} Lei</span>
                            <Link
                              to={{
                                pathname: "/account/payment-details",
                                paymentId: item.id,
                              }}
                              className="cc-account-actions-btn"
                            >
                              Detalii
                            </Link>
                          </div>
                        </div>
                      ))}
                </div>
              </ResponsiveComponent>

              <div className="cc-listing-more-articles">
                <button
                  type="button"
                  className="btn read-more grey"
                  onClick={(e) => handleMoreHistory(e)}
                  disabled={
                    userPaymentHistory &&
                    userPaymentHistory.length < paginateHistory
                      ? true
                      : false
                  }
                  style={{
                    opacity: `${
                      userPaymentHistory &&
                      userPaymentHistory.length < paginateHistory
                        ? 0.2
                        : 0.6
                    }`,
                  }}
                >
                  Mai mult istoric
                </button>
              </div>
            </>
          ) : (
            <>
              <div>Nu ai istoric plati</div>
              <div className="history-separator hide-tablet hide-desktop"></div>
            </>
          )}
        </div>
      </div>
    </section>
  );
};

export default AccountPaymentHistory;
