import { apiGet } from "../../helpers/api";

/* Get Coupons */

export const getCoupons = (pageUrl) => async (dispatch) => {
  try {
    dispatch({
      type: "AWAITING_COUPONS",
    });

    const response = await apiGet(
      `${pageUrl.replace("https://api.cashclub.ro/api", "")}&perPage=40`
    );

    const { firstPageUrl, nextPageUrl, prevPageUrl, data } = response.data;

    dispatch({
      type: "SUCCESS_GET_COUPONS",
      payload: {
        firstPageUrl,
        nextPageUrl,
        prevPageUrl,
        data,
      },
    });
  } catch (e) {
    dispatch({
      type: "ERROR_COUPONS",
      payload: {
        e,
      },
    });
  }
};

/* Filter Coupons */

export const filterCoupons =
  (filterKey, pageUrl, sortKey) => async (dispatch) => {
    try {
      dispatch({
        type: "AWAITING_COUPONS",
      });

      const response = await apiGet(`${pageUrl.replace("https://api.cashclub.ro/api","")}&perPage=40${filterKey}${!sortKey ? "" : sortKey}`);

      const { firstPageUrl, nextPageUrl, data } = response.data;

      dispatch({
        type: "SUCCESS_FILTER_COUPONS",
        payload: {
          firstPageUrl,
          nextPageUrl,
          data,
          filterKey,
          sortKey,
        },
      });
    } catch (e) {
      dispatch({
        type: "ERROR_COUPONS",
        payload: {
          e,
        },
      });
    }
  };
