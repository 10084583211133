import React from 'react'
import {NavLink} from 'react-router-dom'
import './HorizontalNav.css'

function HorizontalNav(props) {

  return (
    <nav className="horizontal-nav">
      <ul>
        {
        props.links
        ?
          <>
            { props.links.map((item, idx) => (

              <li key={idx}>
                <NavLink

                  to={item.link}
                  exact={true}
                  activeClassName="active"
                  className={props.className}
                  onClick={props.handleClick}
                  style={props.style}
                >
                  {item.name}
                </NavLink>
              </li>
            ))}
          </>
        :
          <li>
            No links data
          </li>
        }
      </ul>
    </nav>
  )
}

export default HorizontalNav