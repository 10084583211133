import React, { useState } from "react";
import iconCopy from "../../../assets/icons/copyV2.svg";
import iconCheck from "../../../assets/icons/checkV2.svg";
import "./CopyToClipboard.css";

export default function CopyToClipboard({ data, title }) {
  const [toggleIcon, setToggleIcon] = useState(null);

  const handleCopy = (code) => {
    setToggleIcon(true);

    let text = document.createElement("textarea");
    document.body.appendChild(text);
    text.value = code;
    text.select();
    document.execCommand("copy");
    document.body.removeChild(text);
  };

  return (
    <div className="cc-copy-to-clipboard">
      <button onClick={() => handleCopy(data)}>
        <img src={toggleIcon ? iconCheck : iconCopy} alt="copy icon" />
        {title ? title : "Copiaza"}
      </button>
    </div>
  );
}
