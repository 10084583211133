const initialState = {
  isLoading: null,
  loadingNewsletter: null,
  errorRegister: null,
  errorLogin: null,
  errorLogout: null,
  errorFrgottenPassword: null,
  errorResetPassword: null,
  errorEditInfo: null,
  errorContact: null,
  errorWithdraw: null,
  errorNewsletter: null,
  errorSMS: null,
  errorAccConfirm: null,
  register: {
    errors: null,
    success: null,
  },
  forgottenPassword: {
    errors: null,
  },
  resetPassword: {
    message: null,
    status: null,
  },
  editUserInfo: null,
  userContactForm: null,
  userWithdraw: null,
  userNewsletter: {
    message: null,
    status: null,
  },
  userSMS: null,
  userLogout: null,
};

const userReducer = (state = initialState, action) => {
  const { type, payload } = action;

  switch (type) {
    case 'AWAITING_USER':
      return {
        ...state,
        isLoading: true,
      };

    case 'AWAITING_USER_NEWSLETTER':
      return {
        ...state,
        loadingNewsletter: true,
      };

    /* Errors */

    case 'ERROR_USER_REGISTER':
      return {
        ...state,
        isLoading: false,
        errorRegister: payload.e ? payload.e : payload.error ? payload.error : null,
      };

    case 'ERROR_USER_LOGIN':
      return {
        ...state,
        isLoading: false,
        errorLogin:
          payload.e && payload.e.response
            ? payload.e.response.data.error
            : payload.error
            ? payload.error
            : payload.e
            ? payload.e
            : null,
      };

    case 'ERROR_USER_LOGOUT':
      return {
        ...state,
        isLoading: false,
        errorLogout: payload.e ? payload.e : null,
      };

    case 'ERROR_EDIT_INFO':
      return {
        ...state,
        isLoading: false,
        errorEditInfo:
          payload.e && payload.e.response && payload.e.response.data
            ? payload.e.response.data.errors
            : payload.e
            ? payload.e
            : null,
      };

    case 'ERROR_FORGOTTEN_PASSWORD':
      return {
        ...state,
        isLoading: false,
        errorFrgottenPassword:
          payload.e && payload.e.response.data.message ? payload.e.response.data.message : payload.e ? payload.e : null,
      };

    case 'ERROR_RESET_PASSWORD':
      return {
        ...state,
        isLoading: false,
        errorResetPassword: payload.e ? payload.e : null,
      };

    case 'ERROR_CONTACT':
      return {
        ...state,
        isLoading: false,
        errorContact: payload.e ? payload.e : null,
      };

    case 'ERROR_WITHDRAW':
      return {
        ...state,
        isLoading: false,
        errorWithdraw:
          payload.e.response && payload.e.response.data ? payload.e.response.data : payload.e ? payload.e : null,
      };

    case 'ERROR_NEWSLETTER':
      return {
        ...state,
        loadingNewsletter: false,
        errorNewsletter: payload.e ? payload.e : null,
      };

    case 'ERROR_SMS':
      return {
        ...state,
        isLoading: false,
        errorSMS: payload.e ? payload.e : null,
      };

    case 'ERROR_ACC_CONFIRMATION':
      return {
        ...state,
        isLoading: false,
        errorAccConfirm: payload.e ? payload.e : null,
      };

    /* Succsess */

    case 'SUCCESS_USER_REGISTER':
      return {
        ...state,
        isLoading: false,
        register: {
          errors: payload.errors,
          success: payload.email,
        },
      };

    case 'SUCCESS_ACC_CONFIRMATION':
      return {
        ...state,
        isLoading: false,
      };

    case 'SUCCESS_USER_LOGIN':
      return {
        ...state,
        isLoading: false,
        login: payload,
      };

    case 'SUCCESS_USER_LOGOUT':
      return {
        ...state,
        isLoading: false,
        userLogout: payload ? payload : null,
      };

    case 'SUCCESS_FORGOTTEN_PASSWORD':
      return {
        ...state,
        isLoading: false,
        forgottenPassword: {
          errors: payload.message ? payload.message : null,
        },
      };

    case 'SUCCESS_RESET_PASSWORD':
      return {
        ...state,
        isLoading: false,
        resetPassword: {
          message: payload.message ? payload.message : null,
          status: payload.status ? payload.status : null,
        },
      };

    case 'SUCCESS_EDIT_INFO':
      return {
        ...state,
        isLoading: false,
        editUserInfo: payload.email ? payload.email : null,
      };

    case 'SUCCESS_CONTACT':
      return {
        ...state,
        isLoading: false,
        userContactForm: payload.data ? payload.data : null,
      };

    case 'SUCCESS_WITHDRAW':
      return {
        ...state,
        isLoading: false,
        userWithdraw: payload.data ? payload.data : null,
      };

    case 'SUCCESS_NEWSLETTER':
      return {
        ...state,
        loadingNewsletter: false,
        userNewsletter: {
          message: payload.data ? payload.data : null,
          status: payload.status ? payload.status : null,
        },
      };

    case 'SUCCESS_SMS':
      return {
        ...state,
        isLoading: false,
        userSMS: payload.data ? payload.data : null,
      };

    default:
      return state;
  }
};

export default userReducer;
