import React, {useEffect, useState} from 'react'

import './FlipCards.css'


const FlipCards = (props) => {

  const cardsData = [
   {
     id: '1',
     seconds: 28,
     display: 0,
     animTime: 6,
     front: props.products[0],
     back: props.products[1]
   },
   {
     id: '2',
     seconds: 24,
     display: 0,
     animTime: 5,
     front: props.products[2],
     back: props.products[3]
   },
   {
     id: '3',
     seconds: 20,
     display: 0,
     animTime: 3,
     front: props.products[4],
     back: props.products[5]
   }
 ]

 const [cards, setCards] = useState(cardsData);

 useEffect(() => {
   const intervals = []

    cards.forEach((item, idx) => {
     if(!intervals[idx]){
       let interval = setInterval(() => {

          setCards(oldCards => {

            const productsIds = []

            oldCards.forEach((oldCardsItem) => {
              productsIds.push(oldCardsItem.front.id, oldCardsItem.back.id)
            })

            const newProducts = props.products.filter((product) => {
              return !productsIds.find(id => id === product.id)
            })

            const newProduct = newProducts[Math.floor(Math.random() * (newProducts.length - 1))];

            return oldCards.reduce((acc, card)=>{
              if(card.id === item.id){
                if(card.display === 0 ){

                  return acc.concat({
                    ...card,
                    display: 180,
                    back: {
                      ...newProduct
                    }
                  })

                } else if(card.display === 180) {

                  return acc.concat({
                    ...card,
                    display: 0,
                    front: {
                      ...newProduct
                    }
                  })

                }

              }

              return acc.concat(card)

            }, [])

          })


       }, item.seconds / 2 * 1000);

       intervals.push(interval)
     }
    })

   return () => intervals.map((item) => clearInterval(item))

 // eslint-disable-next-line react-hooks/exhaustive-deps
 }, []);

  return (
    <div className={`flip-card-products ${props.className}`}>
      {cards.map((card, idx)=> (
        <div className={`card-${card.id}`} key={idx}>
          <div className='flip-card-container'>
            <div className='flip-card-inner' style={{ transform: 'rotateY('+card.display+'deg)', transition: 'all '+card.animTime+'s ease'}}>
                <div className='flip-card-front' style={{ zIndex: `${card.display === 0 ? 1 : 0}`, transition: 'all '+card.animTime+'s ease'}}>
                  <div className='card-image'>
                    <img src={card.front.img} alt='product1'/>
                  </div>
                  <div className='card-summary'>
                    <h3 className='summary-title'>{card.front.title}face</h3>
                    <img src={card.front.icon} className='summary-icon' alt='product icon'/>
                    <span className='summary-discount'>{card.front.discount}</span>
                    <span className='summary-shop'>{card.front.shop}</span>
                  </div>
                </div>
                <div className='flip-card-back' style={{ zIndex: `${card.display === 0 ? 0 : 1}`, transition: 'all '+card.animTime+'s ease'}}>
                <div className='card-image'>
                  <img src={card.back.img} alt='product1'/>
                </div>
                <div className='card-summary'>
                  <h3 className='summary-title'>{card.back.title}back</h3>
                  <img src={card.back.icon} className='summary-icon' alt='product icon'/>
                  <span className='summary-discount'>{card.back.discount}</span>
                  <span className='summary-shop'>{card.back.shop}</span>
                </div>
              </div>
            </div>
          </div>
        </div>
      ))}
    </div>
  )
}

export default FlipCards