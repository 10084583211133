import React, { useState, useEffect, createRef } from 'react';

import './FaToggleText.css';

const FaToggleText = (props) => {
  const [toggleText, setToggleText] = useState(0);

  const { question } = props.props.location;

  const question5 = createRef();
  const question11 = createRef();
  const question12 = createRef();
  const question10 = createRef();

  const handleScroll = (nr) => {
    const refName = nr === 5 ? question5 : nr === 10 ? question10 : nr === 11 ? question11 : nr === 12 && question12;
    refName.current.scrollIntoView({
      behavior: 'smooth',
      block: 'start',
      inline: 'nearest',
    });
  };

  const handleScrollTo = (e) => {
    e.currentTarget.scrollIntoView(true);
  };

  const handleClick = (idx) => {
    if (toggleText === idx) {
      setToggleText('0');
    } else {
      setToggleText(idx);
    }
  };

  useEffect(() => {
    if (question) {
      setToggleText(question);
      handleScroll(question);
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <section className='fa-toggle-text'>
      <div className='cb-container'>
        <div className='fa-toggle-text-wrapper'>
          <div>
            <div
              className='fa-toggle-text-box'
              onClick={(e) => {
                handleClick(1);
                handleScrollTo(e);
              }}
            >
              <h3 className={toggleText === 1 ? 'open' : 'closed'}>Ce inseamna cashback? </h3>
              <div className={toggleText === 1 ? 'is-visible' : 'is-hidden'}>
                <p>
                  Pentru majoritatea oamenilor conceptul ”cashback” este un concept nou. Constatarea aceasta vine si din
                  faptul ca este intrebarea pe care o primim cel mai des. Un site de cashback este o platformă de
                  cumpărături online care oferă membrilor săi o parte din bani înapoi, reprezentând un procent din
                  valoarea comenzii plasate. Procentul diferă in funcție de magazinul partener si poate fi de la 1% pana
                  la 30% din valoarea produsului, sau serviciului achiziționat.
                </p>
                <p>
                  Dar noua ne plac personajele din viata reala, asa ca vom lua exemplul celui mai nou membru
                  CashClub.ro. Mariei ii place sa isi aranjeze casa cu cel mai nou tip de mobilier de la Vivre, ii place
                  sa isi cumpere haine si incaltaminte on-line de la Epantofi sau Fashiondays, si-a cumparat o bicicleta
                  de dama de la Decathlon. Cumpara in mod regulat carti de la elefant, Libris sau Carturesti, iar
                  partenerul ei comanda regulat pe Aliexpress. Anul trecut avea aceleasi obiceiuri de cumparaturi, insa
                  la inceputul acestui an a aflat de www.CashClub.ro de la prietena ei Alexandra care i-a recomandat
                  platforma prin linkul ei de refferal aflat in "Contul meu" si ambele au primit cate 30 de lei la prima
                  comanda a Mariei. Astfel, pastrandu-si obiceiurile de consum, Maria a primit in contul ei CashClub
                  deja 100 de lei de la inceputul anului pana acum doar folosind platforma CashClub, a solicitat plata
                  si a primit deja banii in contul ei. Mai mult, a inceput si ea sa recomande altor prieteni si acum
                  primeste 30 de lei, atat ea cat si prietenii ei, pentru fiecare prieten care si-a creat un cont nou pe
                  platforma si care a plasat cel putin o comanda valida.
                </p>
              </div>
            </div>
            <div
              className='fa-toggle-text-box'
              onClick={(e) => {
                handleClick(2);
                handleScrollTo(e);
              }}
            >
              <h3 className={toggleText === 2 ? 'open' : 'closed'}>
                Ce conditii sunt necesare pentru a ma bucura de cashback?
              </h3>
              <div className={toggleText === 2 ? 'is-visible' : 'is-hidden'}>
                <p>
                  Tot ce iti trebuie pentru a primi bani in contul tau CashClub este sa iti creezi un cont sa instalezi
                  extensie pentru desktop sau aplicatia pentru mobil si sa faci cumparaturi ca si pana acum la
                  magazinele tale preferate listate pe platforma.
                </p>
              </div>
            </div>
            <div
              className='fa-toggle-text-box'
              onClick={(e) => {
                handleClick(3);
                handleScrollTo(e);
              }}
            >
              <h3 className={toggleText === 3 ? 'open' : 'closed'}>
                Produsele achizitionate prin CashClub au un cost mai mare?
              </h3>
              <div className={toggleText === 3 ? 'is-visible' : 'is-hidden'}>
                <p>
                  Nu! CashClub este doar despre a primi bani inapoi, economisind cu fiecare produs cumparat. Platesti
                  absolut acelasi pret pe produsele achitionate, insa primesti un procent, in functie de magazin, inapoi
                  in contul tau CashClub. In loc sa te duci direct la adresa web a magazinului unde vrei sa comanzi
                  treci mai intai prin contul tau CashClub, da click pe banner-ul magazinului unde vrei sa comanzi si
                  dupa aceea urmeaza pasii pe care ii urmezi in mod normal pe site-ul magazinului partener. Dupa aceea
                  trebuie doar sa ai rabdare pana vezi banii in contul tau ca fiind platibili si s-au adunat in balanta
                  ta de bani pe care ii poti retrage deoarece bonusurile trec prin 3 etape diferite:
                </p>
                <ul>
                  <li>inregistrat - in momentul in care se plaseaza comanda</li>
                  <li>aprobat - de obicei cand trece perioada de retur posibila (aprox. 30 de zile)</li>
                  <li>
                    platitibil - dupa aproximatix inca 15 zile magazinul face plata catre noi si vei vedea comisionul ca
                    fiind acessibil pentru retragere.
                  </li>
                </ul>
              </div>
            </div>
            <div
              className='fa-toggle-text-box'
              onClick={(e) => {
                handleClick(4);
                handleScrollTo(e);
              }}
            >
              <h3 className={toggleText === 4 ? 'open' : 'closed'}>Cat costa extensia sau aplicatia CashClub?</h3>
              <div className={toggleText === 4 ? 'is-visible' : 'is-hidden'}>
                <p>
                  Utilizarea extensiei si aplicatiei mobile CashClub sunt GRATUITE si te poti bucura de beneficiile
                  acestora, oricand si oriunde te-ai afla, fie pe laptop fie pe mobil..
                </p>
              </div>
            </div>
            <div
              className='fa-toggle-text-box'
              ref={question5}
              onClick={(e) => {
                handleClick(5);
                handleScrollTo(e);
              }}
            >
              <h3 className={toggleText === 5 ? 'open' : 'closed'}>Cum functioneaza cashback?</h3>
              <div className={toggleText === 5 ? 'is-visible' : 'is-hidden'}>
                <p>
                  Cashback functioneaza intr-un mod extrem de simplu. Sa ne ne gandim spre exemplu la un produs pe care
                  il doresti: un telefon mobil. Prin intermediul CashClub noi promovam acest telefon, iar partenerii
                  nostri achita un mic procent pentru toate achizitiile generate de catre platforma noastra. Tot ce
                  trebuie sa faceti este sa instalati extensia, iar apoi magia primirii banilor inapoi, se intampla cu
                  fiecare click. Acest procent, pe care il primim, noi il impartim cu voi, userii nostri, iar voi
                  economisiti la fiecare achizitie.
                </p>
              </div>
            </div>
            <div
              className='fa-toggle-text-box'
              onClick={(e) => {
                handleClick(6);
                handleScrollTo(e);
              }}
            >
              <h3 className={toggleText === 6 ? 'open' : 'closed'}>
                Ce pot sa fac daca in lista de magazine partenere nu se regaseste magazinul pe care eu il doresc?
              </h3>
              <div className={toggleText === 6 ? 'is-visible' : 'is-hidden'}>
                <p>
                  Lista de magazine partenere este una extrem de variata, avem 800 de magazine partenere, cu o multime
                  de reduceri si promotii. In cazul in care, magazinul dorit nu se regaseste in aceasta lista, te rugam
                  sa ne contactezi la contact@cashclub.ro, iar noi vom incerca sa il transformam in partenerul nostru,
                  pentru a te bucura de cashback.
                </p>
              </div>
            </div>
            <div
              className='fa-toggle-text-box'
              onClick={(e) => {
                handleClick(7);
                handleScrollTo(e);
              }}
            >
              <h3 className={toggleText === 7 ? 'open' : 'closed'}>
                Cum pot sa beneficiez de banii pe care ii primesc inapoi?
              </h3>
              <div className={toggleText === 7 ? 'is-visible' : 'is-hidden'}>
                <p>
                  Pentru a beneficia de acesti bani, trebuie sa iti creezi un cont, sa downloadezi extensia de broswer
                  sau sa iti instalezi aplicatia de mobil. In momentul in care incepi sa cumperi prin intermediul
                  CashClub, automat economisesti, iar in contul tau va aparea o suma de bani. Aceasta suma de bani, va
                  putea fi retrasa in momentul in care vei atinge pragul de 50 de lei. Dupa ce ai minim 50 de lei in
                  cont, vei observa un buton de ”Retragere”, iar dupa apasarea acestuia va trebui sa iti
                  introduci IBAN-ul, numele titularului de cont si numarul de telefon. Astfel, prin cateva click-uri
                  rapide, banii ajung in contul personal, iar tu te vei putea bucura de ei.
                </p>
              </div>
            </div>
            <div
              className='fa-toggle-text-box'
              onClick={(e) => {
                handleClick(8);
                handleScrollTo(e);
              }}
            >
              <h3 className={toggleText === 8 ? 'open' : 'closed'}>
                Cat de sigure sunt tranzactiile prin intermediul CashClub?
              </h3>
              <div className={toggleText === 8 ? 'is-visible' : 'is-hidden'}>
                <p>
                  Toate tranzactiile in bani pentru produselor preferate se realizeaza online prin intermediul
                  sistemelor securizate de plata ale magazinelor partenere, astfel ca nu vei realiza nicio tranzactie
                  financiara pe platforma noastra, platile fiind efectuate ca si pana acum pe site-urile magazinelor
                  partenere.
                </p>
              </div>
            </div>
            <div
              className='fa-toggle-text-box'
              onClick={(e) => {
                handleClick(9);
                handleScrollTo(e);
              }}
            >
              <h3 className={toggleText === 9 ? 'open' : 'closed'}>
                Ce este diferit atunci cand cumpar prin CashClub?
              </h3>
              <div className={toggleText === 9 ? 'is-visible' : 'is-hidden'}>
                <p>
                  Pentru a intelege mai bine diferenta, vom explica pas cu pas procesul de achizitie prin intermediul
                  CashClub. In primul rand, trebuie sa instalezi extensia sau aplicatia mobila CashClub, in functie de
                  gadget-ul pe care il folosesti mai frecvent (extensie pentru dektop/laptop si aplicatie pentru
                  telefon/tableta). Apoi, intri pe platforma, cauti magazinul preferat, accepti termenii si conditiile
                  si faci cumparaturi simplu si rapid, ca si pana acum. Dupa ce termini sesiunea de cumparaturi si
                  achiti toate produsele, un procent din valoarea sumei platite, iti va reveni tie direct in contul tau
                  CashClub. In momentul in care primesti banii economisiti, acestia vor fi accesibili in ”Contul meu”.
                  Practic, ai doar de castigat si de ce sa nu cumperi economisind de fiecare data?
                </p>
              </div>
            </div>
            <div
              className='fa-toggle-text-box'
              ref={question10}
              onClick={(e) => {
                handleClick(10);
                handleScrollTo(e);
              }}
            >
              <h3 className={toggleText === 10 ? 'open' : 'closed'}>Cum imi fac cont?</h3>
              <div className={toggleText === 10 ? 'is-visible' : 'is-hidden'}>
                <p>
                  CashClub este extrem de simplu si prietenos, de aceea sa iti faci un cont va fi floare la ureche. Ai
                  nevoie doar de cateva momente a selecta pagina ”Contul meu” si pentru a introduce cateva date
                  personale precum: nume, prenume, email si parola. Daca doresti o inregistrare mai rapida, atunci poti
                  oricand sa te loghezi utilizand contul Facebook. Contul este singura modalitate de a urmari sumele de
                  bani adunate in urma cumparaturilor.
                </p>
              </div>
            </div>
            <div
              className='fa-toggle-text-box'
              ref={question11}
              onClick={(e) => {
                handleClick(11);
                handleScrollTo(e);
              }}
            >
              <h3 className={toggleText === 11 ? 'open' : 'closed'}>In cat timp primesc banii in cont?</h3>
              <div className={toggleText === 11 ? 'is-visible' : 'is-hidden'}>
                <p>
                  Fiecare suma de bani, castigata prin cumparaturile realizate, intra in contul tau in momentul in care
                  tranzactia este efectuata si acceptata de magazinele partenere. Bonusurile tale trec prin 3 etape
                  diferite:
                </p>
                <ul>
                  <li>inregistrat - in momentul in care se plaseaza comanda</li>
                  <li>aprobat - de obicei cand trece perioada de retur posibila (aprox. 30 de zile)</li>
                  <li>
                    platitibil - dupa aproximatix inca 15 zile magazinul face plata catre noi si vei vedea comisionul ca
                    fiind acessibil pentru retragere.
                  </li>
                </ul>
                <p>Deci, in medie, in 45 de zile vei putea solicita plata pentru sumele acumulate in contul tau.</p>
              </div>
            </div>
            <div
              className='fa-toggle-text-box'
              ref={question12}
              onClick={(e) => {
                handleClick(12);
                handleScrollTo(e);
              }}
            >
              <h3 className={toggleText === 12 ? 'open' : 'closed'}>
                Se cumuleaza bonusurile cashback cu reducerile?{' '}
              </h3>
              <div className={toggleText === 12 ? 'is-visible' : 'is-hidden'}>
                <p>
                  Da, pe CashClub ai acces zilnic la o multime de promotii! Fiecare promotie se cumuleaza cu bonusul
                  primit pentru achizitiile realizate, ceea ce inseamna mai multi bani economisiti pentru tine.
                </p>
              </div>
            </div>
            <div
              className='fa-toggle-text-box'
              onClick={(e) => {
                handleClick(13);
                handleScrollTo(e);
              }}
            >
              <h3 className={toggleText === 13 ? 'open' : 'closed'}>Ce pot cauta in pagina de „Comparator de preturi”?</h3>
              <div className={toggleText === 13 ? 'is-visible' : 'is-hidden'}>
                <p>
                  Pagina "Comparator de preturi" din platforma functioneaza exact ca un comparator de preturi dar si ca o sursa de
                  inspiratie. Astfel, avand integrate produsele comercializate de majoritatea partenerilor nostri poti
                  cauta cel mai bun pret sau poti cauta o idee de cadou.
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default FaToggleText;
