import React, { useState } from "react";
import iconCopy from "../../../assets/icons/copy.svg";
import iconCheck from "../../../assets/icons/check.svg";

import "./CouponCopy.css";

export default function CouponCopy({ data }) {
  const [toggleIcon, setToggleIcon] = useState(null);

  const handleCopy = (code) => {
    setToggleIcon(true);
  
    let text = document.createElement("textarea");
    document.body.appendChild(text);
    text.value = code;
    text.select();
    document.execCommand("copy");
    document.body.removeChild(text)
  };

  return (
    <div className="cc-coupon-copy">
      <div className="cc-coupon-copy-text">
        <p>Copiati codul si introduceti-l in cos</p>
      </div>
      <div className="cc-coupon-copy-wrapper">
        <div className="cc-coupon-copy-value">
          <span className={`${toggleIcon && "cc-coupon-copy-sucess"}`}>
            {data}
          </span>
        </div>
        <div className="cc-coupon-copy-button">
          <button onClick={() => handleCopy(data)}>
            <img src={toggleIcon ? iconCheck : iconCopy} alt="copy icon" />
            Copiaza cod
          </button>
        </div>
      </div>
    </div>
  );
}
