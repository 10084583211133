import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { getPaymentDetails } from "../../../../store/actions/accountActions";
import { LoadingSpinner, ResponsiveComponent } from "../../../../components";
import { Helmet } from "react-helmet";

import "./PaymentDetails.css";

const PaymentDetails = (props) => {
  const dispatch = useDispatch();
  /* Get state */

  const { userPaymentDetails, errorDetails, detailsIsloading } = useSelector(
    (state) => state.ccAccount
  );

  const paymentId = userPaymentDetails.paymentId;
  const details = userPaymentDetails.details;

  const [paginateHistory, setPaginateHistory] = useState(5);

  /* Update state */

  useEffect(() => {
    const ID = props.location.paymentId
      ? props.location.paymentId
      : paymentId
      ? paymentId
      : null;

    if (ID) {
      dispatch(getPaymentDetails(ID));
    } else {
      props.history.push("/");
    }
    //eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  /* Paginate */

  const handleMoreHistory = (e) => {
    e.preventDefault();
    setPaginateHistory(() => paginateHistory + 5);
  };

  return (
    <>
      <Helmet>
        <meta charSet="utf-8" />
        <title>Detalii Plata</title>
      </Helmet>
      <section className="cc-payment-details">
        <div className="cb-container small-padding">
          <div className="cc-payment-details-wrapper">
            <h1>Detalii plata</h1>

            {detailsIsloading ? (
              <LoadingSpinner padding={"2"} width={"10"} />
            ) : errorDetails && !detailsIsloading && !details ? (
              <div className="cc-error-messages">
                <p>
                  A aparut o eroare, reincarca pagina sau incearca mai tarziu
                </p>
              </div>
            ) : (
              details && (
                <>
                  <div className="payment-details-header">
                    <div>
                      <div>
                        <p>
                          Status cerere:
                          <span style={{ color: "#7cc961" }}>
                            {details.status === "paid"
                              ? "platit"
                              : details.status}
                          </span>
                        </p>
                        <p>
                          Persoana contact:
                          <span>{details.contact_person}</span>
                        </p>
                        <p>
                          Telefon: <span>{details.contact_phone}</span>
                        </p>
                        <p>
                          Mesaj: <span>{details.message}</span>
                        </p>
                        <p>
                          Total: <span>{details.payment_total} Lei</span>
                        </p>
                      </div>
                      <div>
                        <p>
                          Email: <span>{details.contact_email}</span>
                        </p>
                        <p>
                          Iban: <span>{details.iban}</span>
                        </p>
                        <p>
                          Data cerere: <span>{details.created_at}</span>
                        </p>
                        <p>
                          Titular cont: <span>{details.account_holder}</span>
                        </p>
                      </div>
                    </div>
                  </div>

                  <div className="payment-details-list">
                    <h2>Lista tranzactii</h2>
                    <ResponsiveComponent showOnDesktop>
                      <div>
                        <div>
                          <span>Magazin</span>
                          <span>Data cumpararii</span>
                          <span>Status plata</span>
                          <span>Suma estimata</span>
                        </div>
                        {details.commissions
                          .slice(0, paginateHistory)
                          .map((item, idx) => (
                            <div key={idx}>
                              <span>{item.shopName}</span>
                              <span>{item.createdAt}</span>
                              <span style={{ color: "#7cc961" }}>
                                {item.payment_status === "paid"
                                  ? "platit"
                                  : item.status}
                              </span>
                              <span>{item.estimatedAmount} Lei</span>
                            </div>
                          ))}
                      </div>
                    </ResponsiveComponent>

                    <ResponsiveComponent showOnMobile>
                      <div>
                        <div>
                          <span>Magazin</span>
                          <span>Suma estimata</span>
                        </div>
                        {details.commissions
                          .slice(0, paginateHistory)
                          .map((item, idx) => (
                            <div key={idx}>
                              <div>
                                <span>{item.shopName}</span>
                                <span>{item.createdAt}</span>
                              </div>
                              <div>
                                <span>{item.estimatedAmount} Lei</span>
                                <span style={{ color: "#7cc961" }}>
                                  {item.payment_status === "paid"
                                    ? "platit"
                                    : item.status}
                                </span>
                              </div>
                            </div>
                          ))}
                      </div>
                    </ResponsiveComponent>
                  </div>

                  <div className="cc-listing-more-articles">
                    <button
                      type="button"
                      className="btn read-more grey"
                      onClick={(e) => handleMoreHistory(e)}
                      disabled={
                        details.commissions &&
                        details.commissions.length < paginateHistory
                          ? true
                          : false
                      }
                      style={{
                        opacity: `${
                          details.commissions &&
                          details.commissions.length < paginateHistory
                            ? 0.2
                            : 0.6
                        }`,
                      }}
                    >
                      Mai mult istoric
                    </button>
                  </div>
                </>
              )
            )}
          </div>
        </div>
      </section>
    </>
  );
};

export default PaymentDetails;
