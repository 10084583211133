import React, { Component } from "react";
import Slider from "react-slick";
import 'slick-carousel/slick/slick.css'
import 'slick-carousel/slick/slick-theme.css'
import './Carousel.css'

export default class SimpleSlider  extends Component {

  render() {
    const settings = {
      autoplay: this.props.autoplay ? this.props.autoplay : true,
      speed: this.props.speed ? this.props.speed : 5000,
      autoplaySpeed: this.props.autoplaySpeed ? this.props.autoplaySpeed : 500,
      cssEase: this.props.cssEase ? this.props.cssEase : 'linear',
      slidesToShow: this.props.slidesToShow ? this.props.slidesToShow : 4,
      slidesToScroll: this.props.slidesToScroll ? this.props.slidesToScroll : 1,
      infinite: this.props.infinite ? this.props.infinite : true,
      dots: false,

      responsive:
        this.props.responsive ? this.props.responsive :
      [
        {
          breakpoint: 1024,
          settings: {
            slidesToShow: 1,
            slidesToScroll: 1,
            initialSlide: 1,
            infinite: true,
            dots: false
          }
        },
        {
          breakpoint: 769,
          settings: {
            slidesToShow: 1,
            slidesToScroll: 1,
            initialSlide: 1,
            infinite: true,
            dots: false
          }
        }
      ]
    };

    return (
      <Slider {...settings}>
        {this.props.children}
      </Slider>
    );
  }
}