import axios from "axios";
import auth from "./authentication";

const API_ROOT = process.env.REACT_APP_API_ROOT;

const defaultOptions = () => ({
  headers: {
    Authorization: `Bearer ${auth.token()}`,
  },
});

export const apiUrl = (path) => {
  return `${API_ROOT}${path}`;
};

export const apiGet = (path, options = {}) => {
  return axios.get(apiUrl(path), {
    ...defaultOptions(),
    ...options,
  });
};

export const apiPost = (path, params, options = {}) => {
  return axios.post(apiUrl(path), params, {
    ...defaultOptions(),
    ...options,
  });
};

export const apiPut = (path, params, options = {}) => {
  return axios.put(apiUrl(path), params, {
    ...defaultOptions(),
    ...options,
  });
};