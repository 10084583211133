import React from "react";
import { Link } from "react-router-dom";
import {
  CcCarousel,
  FadeLogo,
  ResponsiveComponent,
} from "../../../../components";

import logosData from "../../../../assets/data/logos";

import "./StepTwo.css";

function StepTwo() {
  const fadeLogoSettings = [
    {
      id: "1",
      seconds: 16,
      display: 0,
      animTime: 2,
      front: logosData[0],
      back: logosData[1],
    },
    {
      id: "2",
      seconds: 18,
      display: 0,
      animTime: 2,
      front: logosData[2],
      back: logosData[3],
    },
    {
      id: "3",
      seconds: 12,
      display: 0,
      animTime: 2,
      front: logosData[4],
      back: logosData[5],
    },
    {
      id: "4",
      seconds: 15,
      display: 0,
      animTime: 2,
      front: logosData[6],
      back: logosData[7],
    },
  ];

  return (
    <section className="step-two">
      <div className="cb-container medium-padding no-side-padding-mobile">
        <div className="step-two-wrapper">
          <div className="step-two-logos">
            <ResponsiveComponent showOnDesktop>
              <FadeLogo
                fadeLogoSettings={fadeLogoSettings}
                logosData={logosData}
              />
            </ResponsiveComponent>
          </div>

          <div className="step-two-content">
            <span className="content-label">Pasul 1</span>
            <h2>Vezi lista completa de magazine partenere</h2>
            <p>
              Ai acces rapid la cea mai mare retea de magazine partenere din
              Romania, adaptate nevoilor si preferintelor tale.
            </p>
            <div className="hide-mobile">
              <Link to="/shops" className="btn-append-arrow">
                Magazine partenere
              </Link>
            </div>
          </div>

          <div className="step-two-carousel hide-tablet hide-desktop">
            <ResponsiveComponent showOnMobile>
              <CcCarousel
                name="hp-clients"
                options={{
                  duration: logosData.length * 8000,
                }}
                breakpoints={[
                  { media: "@media (max-width: 768px)", width: 190 },
                  { media: "@media (min-width: 769px)", width: 250 },
                ]}
              >
                {logosData.map((itm, idx) => (
                  <div key={idx} className="slide">
                    <img src={itm.img} alt="logo1" />
                  </div>
                ))}
              </CcCarousel>
            </ResponsiveComponent>
          </div>

          <div className="hide-tablet hide-desktop">
            <Link to="/shops" className="btn outline black">
              <span className="btn-append-arrow">Magazine Partenere</span>
            </Link>
          </div>
        </div>
      </div>
    </section>
  );
}

export default StepTwo;
