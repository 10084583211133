import { apiGet } from "../../helpers/api";

/* Get afiliate link */

export const getAffiliateLink = (affiliateRoute) => async (dispatch) => {
  try {
    dispatch({
      type: "AWAITING_AFFILIATE",
    });

    const { data } = await apiGet(affiliateRoute);

    dispatch({
      type: "SUCCESS_AFFILIATE",
      payload: {
        data,
      },
    });
  } catch (e) {
    dispatch({
      type: "ERROR_AFFILIATE",
      payload: {
        e,
      },
    });
  }
};
