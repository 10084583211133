import React, { useState } from "react";

import "./InviteUserReferral.css";

const InviteUserreferral = (props) => {
  const [toggleModal, setToggleModal] = useState(false);

  const handleModal = () => {
    if (toggleModal === false) {
      setToggleModal(true);
      document.getElementsByTagName("html")[0].className = "menu-no-scroll";
    } else {
      setToggleModal(false);
      document.getElementsByTagName("html")[0].className = "";
    }
  };

  const handleCopy = () => {
    var copyText = document.getElementById("cc-user-refferal");
    copyText.select();
    copyText.setSelectionRange(0, 99999);
    document.execCommand("copy");
  };
  return (
    <div className="user-invite">
      <p>
        Primeste 30 lei tu si 30 lei prietenii tai pentru fiecare prieten care
        accepta invitatia ta de a crea cont pe CashClub.ro si acumuleaza minim
        10 lei din cashback aprobat.
      </p>
      <button type="button" onClick={handleModal}>
        Invita-ti prietenii
      </button>
      {toggleModal === true && (
        <div className="user-refferal-modal">
          <div className="user-refferal-modal-inner">
            <span onClick={handleModal} className="refferal-modal-close">
              Inchide
            </span>
            <h3>Link referral</h3>
            <p>
              Invita-ti prietenii folosind linkul de referral de mai jos si
              primesti 30 lei pentru fiecare prieten care isi creaza cont pe
              CashClub.ro si acumuleaza minim 10 lei din cashback aprobat.
            </p>
            <div className="user-refferal-modal-copy">
              <input
                readOnly
                type="text"
                value={`https://cashclub.ro/register?ccref=${props.userReferralCode}`}
                id="cc-user-refferal"
              />
              <button type="button" onClick={handleCopy}>
                Copiaza
              </button>
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

export default InviteUserreferral;
