import React from "react";
import { useDispatch, useSelector } from "react-redux";
import { filterCoupons } from "../../../store/actions/couponsActions";

export default function SortCoupons(props) {
  /* Get Pages State */
  const capitalizedPageName =
    props.pageName.charAt(0).toUpperCase() + props.pageName.slice(1);
  const { filterKeyword, isLoading, error,sortKeyword } = useSelector(
    (state) => state[`cc${capitalizedPageName}`]
  );
  const { firstPageUrl } = useSelector(
    (state) => state[`cc${capitalizedPageName}`][`${props.pageName}Info`]
  );

  
  let val2 = filterKeyword
    ? `&sortby=end_date&sortdir=asc`
    : "&filters[expireSoon]=1&sortby=end_date&sortdir=asc";

  /* Update state */

  const dispatch = useDispatch();

  const handleChange = (e) => {
    /* Reset error */
    if (error !== false) {
      dispatch({
        type: "ERROR_COUPONS",
        payload: {},
      });
    }
    /* End of reset */

    const sortData = e.target.value;

    if (!isLoading) {
      dispatch(
        filterCoupons(
          !filterKeyword ? "" : filterKeyword, firstPageUrl, sortData)
      ); // Sort coupons
    } else {
    }
  };

  return (
    <div className="cc-filter">
      <select
        name="filter"
        id="sort-coupons"
        //defaultValue={"DEFAULT"}
        onChange={(e) => handleChange(e)}
        value={sortKeyword || ""}
      >
        <option value="DEFAULT" disabled>
          Sorteaza
        </option>
        <option value={``}>Populare</option>
        <option value={`&sortby=start_date&sortdir=desc`}>Cele mai noi</option>
        <option value={val2}>Expira in curand</option>
        <option value={`&sortby=discount_percent&sortdir=desc`}>Valoarea reducerii</option>
      </select>
    </div>
  );
}

export function useSortDiscountValue(data) {
  const { sortKeyword } = useSelector((state) => state.ccCoupons);

  if (sortKeyword === "byDiscount") {
    data.sort(
      (a, b) => parseFloat(b.discount_percent) - parseFloat(a.discount_percent)
    );
  }
  return data;
}