import React, { useEffect, useState } from "react";
import { withRouter } from "react-router";
import { useSelector } from "react-redux";
import { HorizontalNav, VerticalNav } from "../../../components";
import auth from "../../../helpers/authentication";

import "./Header.css";

const Header = (props) => {
  const accountData = useSelector((state) => state.ccAccount);

  const firstName = useSelector((state) =>
    state.ccAccount.userInfo ? state.ccAccount.userInfo.first_name : null
  );

  const lastName = useSelector((state) =>
    state.ccAccount.userInfo ? state.ccAccount.userInfo.last_name : null
  );

  const name = useSelector((state) =>
    state.ccUser.login && state.ccUser.login.data.user
      ? state.ccUser.login.data.user.name
      : null
  );

  const isAuth = accountData && auth.isAuthenticated();

  const [showMenu, setShowMenu] = useState(null);

  const navLinks = [
    { name: "Cashback", link: "/cashback" },
    { name: "Comparator de preturi", link: "/products" },
    { name: "Magazine", link: "/shops" },
    // { name: "Promotii", link: "/promos" },
    { name: "Cupoane reducere", link: "/coupons" },
  ];

  const mobileNavLinks = [
    { name: "Profilul meu", link: "/" },
    { name: "Cashback", link: "/cashback" },
    { name: "Comparator preturi", link: "/products" },
    { name: "Magazine", link: "/shops" },
    { name: "Cupoane reducere", link: "/coupons" },
    // { name: "Promotii", link: "/promos" },
    { name: "Editeaza profilul", link: "/account/edit-account" },
    { name: "Contact", link: "/contact" },
  ];

  const handleClick = () => {
    if (!showMenu) {
      setShowMenu(true);
      document.getElementsByTagName("html")[0].className = "menu-no-scroll";
    } else {
      setShowMenu(null);
      document.getElementsByTagName("html")[0].className = "";
    }
  };

  const handleLogOut = () => {
    auth.logout(() => {
      props.history.push("/login");
    });
  };

  useEffect(() => {
    setShowMenu(null);
    document.getElementsByTagName("html")[0].className = "";
  }, [props]);

  if (
    !props.location.pathname.includes("/login") &&
    !props.location.pathname.includes("/forgotten-password") &&
    !props.location.pathname.includes("/register") &&
    !props.location.pathname.includes("/login/reset") &&
    !props.location.pathname.includes("/thank-you")
  ) {
    return (
      <header
        className="header"
        style={{
          background: `${
            props.location.pathname === "/" ? "rgba(104,222,107,1)" : "#ffffff"
          }`,
        }}
      >
        <div className="cb-container small-padding">
          <div className="header-wrapper">
            <div className="header-logo">
              <HorizontalNav
                links={[{ name: "CashClub", link: "/" }]}
                style={{
                  color: `${
                    props.location.pathname === "/" && !showMenu
                      ? "#ffffff"
                      : "#000000"
                  }`,
                }}
              />
            </div>
            <nav className="header-nav hide-mobile hide-tablet hide-desktop">
              <HorizontalNav links={navLinks} />
            </nav>
            <div className="header-account hide-mobile hide-tablet hide-desktop">
              {isAuth ? (
                <HorizontalNav
                  links={[
                    {
                      name: `${
                        firstName
                          ? firstName + " " + lastName
                          : name
                          ? name
                          : "Contul meu"
                      }`,
                      link: "/account",
                    },
                  ]}
                />
              ) : (
                <HorizontalNav
                  links={[{ name: "Autentificare", link: "/login" }]}
                />
              )}
            </div>
            <div className="header-extension hide-mobile hide-tablet hide-desktop">
              {/* <a
              href='https://chrome.google.com/webstore/detail/cashclub/bmcjeckemmdkoapcmpmffabkelgknlme?hl=ro&fbclid=IwAR1eIJFJ9Sh4XHeyDL1FkrQFIdTZKB35DO4NHmfDGxYjrwcS_aJZCCjOTy0'
              target='_blank'
              rel='noopener noreferrer'
              className='btn primary green hide-mobile hide-tablet'
            >
              Descarca extensia
            </a> */}
            </div>
            <div className="header-mobile-toggle">
              {isAuth ? (
                <div
                  id="burger-menu"
                  className={showMenu === true ? "open" : ""}
                  onClick={handleClick}
                >
                  <span
                    style={{
                      background: `${
                        props.location.pathname === "/" && !showMenu
                          ? "#ffffff"
                          : "#000000"
                      }`,
                    }}
                  ></span>
                  <span
                    style={{
                      background: `${
                        props.location.pathname === "/" && !showMenu
                          ? "#ffffff"
                          : "#000000"
                      }`,
                    }}
                  ></span>
                  <span
                    style={{
                      background: `${
                        props.location.pathname === "/" && !showMenu
                          ? "#ffffff"
                          : "#000000"
                      }`,
                    }}
                  ></span>
                  <span
                    style={{
                      background: `${
                        props.location.pathname === "/" && !showMenu
                          ? "#ffffff"
                          : "#000000"
                      }`,
                    }}
                  ></span>
                </div>
              ) : (
                <HorizontalNav
                  links={[{ name: "Autentificare", link: "/login" }]}
                />
              )}
            </div>

            {showMenu && (
              <nav className="header-mobile-nav">
                <div className="cb-container small-padding">
                  {/* {
                    isAuth ?

                      <VerticalNav links={[{name: 'Contul meu', link: '/account'}]} handleClick={handleClick} />

                      :

                      <VerticalNav links={[{name: 'Autentificare', link: '/login'}]} handleClick={handleClick} />

                    } */}

                  <VerticalNav
                    links={mobileNavLinks}
                    handleClick={handleClick}
                  />
                  <button onClick={handleLogOut}>Logout</button>
                </div>
              </nav>
            )}
          </div>
        </div>
      </header>
    );
  } else {
    return null;
  }
};

export default withRouter(Header);
