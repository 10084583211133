import React, { useEffect, useState } from "react";
import { isIOS } from "react-device-detect";
import {
  InactiveCardOverlay,
  ResponsiveComponent,
  TimeAvailability,
  WarningModal,
  WarningModalSettings,
} from "../../../../components";
import { useSelector } from "react-redux";
import auth from "../../../../helpers/authentication";
import ShopViewAffiliate from "./shop-view-affiliate/ShopViewAffiliate";
import CouponCard from "../../../coupons/components/coupon-card/CouponCard";

import "./ShopView.css";

export default function ShopView({ data }) {
  const [paginateDiscount, setPaginateDiscount] = useState(5);
  // const [paginateTag, setPaginateTag] = useState(5);

  const handleCoupon = (coupon) => {
    coupon.shopLogo = data.logo;
    coupon.aff_link = data.aff_link;
    return coupon;
  };

  /* Is authenticated */
  const { isAuthenticated } = useSelector((state) => state.ccAccount);
  const isActive = auth.isAuthenticated() && isAuthenticated;

  /* Pagination */
  const handleMoreDiscount = (e) => {
    e.preventDefault();
    setPaginateDiscount(() => paginateDiscount + 5);
  };

  // const handleMoreTag = (e) => {
  //   e.preventDefault();
  //   setPaginateTag(() => paginateTag + 5);
  // };

  const [toggleModal, setToggleModal] = useState(false);

  const handleModal = (variant) => {
    if (typeof variant === "string" && toggleModal !== variant) {
      setToggleModal(variant);
      document.getElementsByTagName("html")[0].className = "menu-no-scroll";
    } else {
      setToggleModal(false);
      document.getElementsByTagName("html")[0].className = "";
    }
  };

  useEffect(() => {
    const isIos107 = localStorage.getItem("ccQuery");
    const iOSSettings = localStorage.getItem("cc_iOS_Settings");

    isIOS && !isIos107 && handleModal("noSettings");
    isIOS && isIos107 && !iOSSettings && handleModal("withSettings");

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <section className="cc-shop-view">
      <div className="cb-container small-padding">
        <div className="cc-shop-view-wrapper">
          <div className="shop-view-commission-list">
            <div className="commission-list-title">
              <h2>
                Primesti {data.discount && data.discount} cashback in{" "}
                {data.trimmedDomain && data.trimmedDomain}
              </h2>
            </div>

            {/* {data.cashback_categories.length ? (
              <div className="commission-list-tags">
                {data.cashback_categories.slice(0, paginateTag).map((category, idx) => (
                  <span key={idx}>{category.name}</span>
                ))}
                {data.cashback_categories.length > 5 && (
                  <button
                    type="button"
                    className="btn read-more grey small"
                    onClick={(e) => handleMoreTag(e)}
                    disabled={data.cashback_categories.length < paginateTag ? true : false}
                    style={{
                      opacity: `${data.cashback_categories.length < paginateTag ? 0.2 : 0.6}`,
                    }}
                  >
                    Mai mult
                  </button>
                )}
              </div>
            ) : null} */}

            <ResponsiveComponent showOnTablet>
              <div className="shop-view-affiliate">
                <ShopViewAffiliate data={data} />
              </div>
            </ResponsiveComponent>

            <div className="commission-list-view">
              {data.cashback_categories && data.cashback_categories.length ? (
                <>
                  <h3>Cashback</h3>
                  <div
                    className="commission-list-view-inner"
                    style={{
                      marginBottom: `${
                        data.cashback_categories.length > 5 ? "5rem" : "0rem"
                      }`,
                    }}
                  >
                    <ul>
                      {data.cashback_categories
                        .slice(0, paginateDiscount)
                        .map((category, idx) => (
                          <li key={idx}>
                            <span>{`Cashback - ${
                              category.name && category.name
                            }`}</span>
                            <span>{`${category.commission}%`}</span>
                          </li>
                        ))}
                    </ul>
                    {data.cashback_categories.length > 5 && (
                      <button
                        type="button"
                        className="btn read-more grey"
                        onClick={(e) => handleMoreDiscount(e)}
                        disabled={
                          data.cashback_categories.length < paginateDiscount
                            ? true
                            : false
                        }
                        style={{
                          opacity: `${
                            data.cashback_categories.length < paginateDiscount
                              ? 0.2
                              : 0.6
                          }`,
                        }}
                      >
                        Mai mult
                      </button>
                    )}
                  </div>
                </>
              ) : null}
              {data.avg_payment_term ? (
                <TimeAvailability
                  text={`Termenul mediu de plata al cashback-ului: ${data.avg_payment_term}`}
                />
              ) : null}
            </div>

            {data.coupons.length ? (
              <div className="commission-list-coupon">
                <h3>Cupoane active</h3>

                <div className="commission-list-coupon-wrapper">
                  {data.coupons.map((coupon, idx) => (
                    <div key={idx}>
                      {isActive ? (
                        <CouponCard data={handleCoupon(coupon)} />
                      ) : (
                        <InactiveCardOverlay shopName={coupon.shopName}>
                          <CouponCard data={handleCoupon(coupon)} />
                        </InactiveCardOverlay>
                      )}
                    </div>
                  ))}
                </div>
              </div>
            ) : null}
          </div>

          <ResponsiveComponent hideOnTablet>
            <div className="shop-view-affiliate">
              <ShopViewAffiliate data={data} />
            </div>
          </ResponsiveComponent>
        </div>
      </div>

      {toggleModal === "noSettings" && (
        <WarningModal handleModal={handleModal} />
      )}
      {toggleModal === "withSettings" && (
        <WarningModalSettings handleModal={handleModal} />
      )}
    </section>
  );
}
