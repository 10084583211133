import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { filterCoupons, getCoupons } from "../../../../store/actions/couponsActions";
import auth from "../../../../helpers/authentication";
import CouponCard from "../coupon-card/CouponCard";
import { InactiveCardOverlay, LoadingSpinner, MoreArticlesBtn, FilterCoupons, SortCoupons } from "../../../../components";

import "./CouponsListing.css";

export default function CouponsListing() {
  /* Get State */
  const { error, coupons, filterKeyword, sortKeyword, isLoading } = useSelector((state) => state.ccCoupons);
  const { firstPageUrl, nextPageUrl } = useSelector((state) => state.ccCoupons.couponsInfo);
  const { isAuthenticated } = useSelector((state) => state.ccAccount);

  /* Update State Data */

  const dispatch = useDispatch();

  const handleMoreCoupons = (e) => {
    e.preventDefault();
    if (isLoading === false) {
      if (!filterKeyword && !sortKeyword) {
        dispatch(getCoupons(nextPageUrl));
      } else if (filterKeyword || sortKeyword) {
        dispatch(filterCoupons(filterKeyword, nextPageUrl, sortKeyword));
      }
    }
  };

  useEffect(() => {
    if (error !== false) {
      dispatch({
        type: "ERROR_COUPONS",
        payload: {},
      });
    }

    if (!coupons.length || error !== false) {
      dispatch(getCoupons(firstPageUrl));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  /* Is authenticated */
  const isActive = auth.isAuthenticated() && isAuthenticated;

  return (
    <section className="coupons-listing">
      <div className="cb-container small-padding">
        <div className="coupons-wrapper">
          <div className="coupons-hero">
            <h1>Cupoane reducere</h1>
            <p>
              Coduri de reducere exclusive la magazinele partenere. Acestea se aplica pe cosul de cumparaturi inainte de
              finalizarea comenzii.
            </p>
            <div className="sorting-group">
              <FilterCoupons pageName="coupons" />
              <SortCoupons pageName="coupons" />
            </div>
          </div>

          {isLoading ? (
            <LoadingSpinner padding={"2"} width={"10"} />
          ) : error ? (
            <div className="cc-error-messages">
              <p>A aparut o eroare, reincarca pagina sau incearca mai tarziu</p>
            </div>
          ) : (!coupons.length && filterKeyword) || (!coupons.length && sortKeyword) ? (
            <div>Nu au fost gasite rezultate pentru aceasta cautare</div>
          ) : isActive && coupons.length ? (
            <div className="coupons-list">
              {coupons.map((coupon, idx) => (
                <div className="coupons-list-item" key={idx}>
                  <CouponCard data={coupon} />
                </div>
              ))}
            </div>
          ) : (
            coupons.length && (
              <div className="coupons-list">
                {coupons.map((coupon, idx) => (
                  <div className="coupons-list-item" key={idx}>
                    <InactiveCardOverlay shopName={coupon.shopName}>
                      <CouponCard data={coupon} />
                    </InactiveCardOverlay>
                  </div>
                ))}
              </div>
            )
          )}

          <MoreArticlesBtn
            title={"Mai multe cupoane"}
            nextPageUrl={nextPageUrl}
            onClick={(e) => handleMoreCoupons(e)}
            loading={isLoading}
          />
        </div>
      </div>
    </section>
  );
}
