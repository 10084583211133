import React, { useState, useEffect } from 'react';

import './MoreArticlesBtn.css';

const MoreArticlesBtn = (props) => {
  const [holdScroll, setHoldScroll] = useState(0);
  const [oldHoldScroll, setOldHoldScroll] = useState(0);

  const restoreScroll = () => {
    setHoldScroll(window.scrollY);
  };

  useEffect(() => {
    if (!props.loading && holdScroll !== oldHoldScroll) {
      window.scrollTo({ top: holdScroll });
      setOldHoldScroll(holdScroll);
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props.loading]);

  return (
    <div className='cc-listing-more-articles'>
      <button
        className='btn read-more grey'
        disabled={!props.nextPageUrl ? true : false}
        onClick={(e) => {
          props.onClick(e);
          restoreScroll();
        }}
        style={{ opacity: `${!props.nextPageUrl ? 0.2 : 0.6}` }}
      >
        {props.title}
      </button>
    </div>
  );
};

export default MoreArticlesBtn;
