import React, { useEffect } from 'react';

const ResponsiveComponent = (props) => {
  const [width, setWidth] = React.useState(window.innerWidth);
  const mobileBreackPoint = 769;
  const tabletBreackPoint = 1024;
  const handleWindowResize = () => {
    setWidth(window.innerWidth);
  };

  useEffect(() => {
    window.addEventListener('resize', handleWindowResize);
    return () => window.removeEventListener('resize', handleWindowResize);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <>
      {props.showOnDesktop
        ? width >= mobileBreackPoint
          ? props.children
          : null
        : props.showOnMobile
        ? width < mobileBreackPoint
          ? props.children
          : null
        : props.hideOnTablet
        ? width >= tabletBreackPoint
          ? props.children
          : null
        : props.showOnTablet
        ? width < tabletBreackPoint
          ? props.children
          : null
        : null}
    </>
  );
};

export default ResponsiveComponent;
