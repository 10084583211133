import lg1 from '../images/shops/logo1.png'
//import lg2 from '../images/shops/logo2.png'
import lg3 from '../images/shops/logo3.png'
import lg4 from '../images/shops/logo4.png'
import lg5 from '../images/shops/logo5.png'
//import lg6 from '../images/shops/logo6.png'
import lg7 from '../images/shops/logo7.png'
import lg8 from '../images/shops/logo8.png'
import lg9 from '../images/shops/logo9.png'
import lg10 from '../images/shops/logo10.png'
import lg11 from '../images/shops/logo11.png'
import lg12 from '../images/shops/logo12.png'
//import lg13 from '../images/shops/logo13.png'
import lg14 from '../images/shops/logo14.png'
import lg15 from '../images/shops/logo15.png'
//import lg16 from '../images/shops/logo16.png'
import lg17 from '../images/shops/logo17.png'
import lg18 from '../images/shops/logo18.png'
import lg19 from '../images/shops/logo19.png'
import lg20 from '../images/shops/logo20.png'
import lg21 from '../images/shops/logo21.jpg'
import lg22 from '../images/shops/logo22.jpg'
import lg23 from '../images/shops/logo23.jpg'



const products = [
  {
    id: '1',
    img: lg1
  },
  // {
  //   id: '2',
  //   img: lg2
  // },
  {
    id: '3',
    img: lg3
  },
  {
    id: '4',
    img: lg4
  },
  {
    id: '5',
    img: lg5
  },
  // {
  //   id: '6',
  //   img: lg6
  // },
  {
    id: '7',
    img: lg7
  },
  {
    id: '8',
    img: lg8
  },
  {
    id: '9',
    img: lg9
  },
  {
    id: '10',
    img: lg10
  },
  {
    id: '11',
    img: lg11
  },
  {
    id: '12',
    img: lg12
  },
  // {
  //   id: '13',
  //   img: lg13
  // },
  {
    id: '14',
    img: lg14
  },
  {
    id: '15',
    img: lg15
  },
  // {
  //   id: '16',
  //   img: lg16
  // },
  {
    id: '17',
    img: lg17
  },
  {
    id: '18',
    img: lg18
  },
  {
    id: '19',
    img: lg19
  },
  {
    id: '20',
    img: lg20
  },
  {
    id: '21',
    img: lg21
  },
  {
    id: '22',
    img: lg22
  },
  {
    id: '23',
    img: lg23
  }
]

export default products