import React, { useState }from 'react'

import img1 from '../../../../assets/icons/question.svg'

import './CbToggleText.css'

const CbToggleText = () => {

  const [toggleText, setToggleText] = useState(0)

  const handleClick = (idx) => {
    if(toggleText === idx) {
      setToggleText('0');
    } else {
      setToggleText(idx);
    }
  }


  return (
    <section className='cb-toggle-text hide-mobile'>
      <div className='cb-container medium-padding'>
        <div className='cb-toggle-text-wrapper'>

          <div className='cb-toggle-text-header'>
            <img src={img1} alt='icon'/>
            <h2>Intrebari frecvente</h2>
          </div>

          <div>
            <div className='cb-toggle-text-box' onClick={() => handleClick(1)}>
              <h3 className={toggleText === 1 ? 'open' : 'closed'}>Cum imi fac cont?</h3>
              <div className={toggleText === 1 ? 'is-visible' : 'is-hidden'}>
                <p>CashClub este extrem de simplu si prietenos, de aceea sa iti faci un cont va fi floare la ureche. Ai nevoie doar de cateva momente a selecta pagina ”Contul meu” si pentru a introduce cateva date personale precum: nume, prenume, email si parola. Daca doresti o inregistrare mai rapida, atunci poti oricand sa te loghezi utilizand contul Facebook. Contul este singura modalitate de a urmari sumele de bani adunate in urma cumparaturilor.</p>
              </div>
            </div>
            <div className='cb-toggle-text-box' onClick={() => handleClick(2)}>
              <h3 className={toggleText === 2 ? 'open' : 'closed'}>In cat timp primesc banii in cont?</h3>
              <div className={toggleText === 2 ? 'is-visible' : 'is-hidden'}>
                <p>Fiecare suma de bani, castigata prin cumparaturile realizate, intra in contul tau in momentul in care tranzactia este efectuata si acceptata de magazinele partenere. Bonusurile tale trec prin 3 etape diferite:</p>
                <ul>
                  <li>inregistrat - in momentul in care se plaseaza comanda</li>
                  <li>aprobat - de obicei cand trece perioada de retur posibila (aprox. 30 de zile)</li>
                  <li>platitibil - dupa aproximatix inca 15 zile magazinul face plata catre noi si vei vedea comisionul ca fiind acessibil pentru retragere.</li>
                </ul>
                <p>Deci, in medie, in 45 de zile vei putea solicita plata pentru sumele acumulate in contul tau.</p>
              </div>
            </div>
            <div className='cb-toggle-text-box' onClick={() => handleClick(3)}>
              <h3 className={toggleText === 3 ? 'open' : 'closed'}>Cum functioneaza cashback?</h3>
              <div className={toggleText === 3 ? 'is-visible' : 'is-hidden'}>
                <p>Cashback functioneaza intr-un mod extrem de simplu. Sa ne ne gandim spre exemplu la un produs pe care il doresti: un telefon mobil. Prin intermediul CashClub noi promovam acest telefon, iar partenerii nostri achita un mic procent pentru toate achizitiile generate de catre platforma noastra.  Tot ce trebuie sa faceti este sa instalati extensia, iar apoi magia primirii banilor inapoi, se intampla cu fiecare click. Acest procent, pe care il primim, noi il impartim cu voi, userii nostri, iar voi economisiti la fiecare achizitie.</p>
              </div>
            </div>
            <div className='cb-toggle-text-box' onClick={() => handleClick(4)}>
              <h3 className={toggleText === 4 ? 'open' : 'closed'}>Ce pot sa fac daca in lista de magazine partenere nu se regaseste magazinul pe care eu il doresc?</h3>
              <div className={toggleText === 4 ? 'is-visible' : 'is-hidden'}>
                <p>Lista de magazine partenere este una extrem de variata, avem 800 de magazine partenere, cu o multime de reduceri si promotii. In cazul in care, magazinul dorit nu se regaseste in aceasta lista, te rugam sa ne contactezi la contact@cashclub.ro, iar noi vom incerca sa il transformam in partenerul nostru, pentru a te bucura de cashback.</p>
              </div>
            </div>
            <div className='cb-toggle-text-box' onClick={() => handleClick(5)}>
              <h3 className={toggleText === 5 ? 'open' : 'closed'}>Cat de sigure sunt tranzactiile prin intermediul CashClub?</h3>
              <div className={toggleText === 5 ? 'is-visible' : 'is-hidden'}>
                <p>Toate tranzactiile in bani pentru produselor preferate se realizeaza online prin intermediul sistemelor securizate de plata ale magazinelor partenere, astfel ca nu vei realiza nicio tranzactie financiara pe platforma noastra, platile fiind efectuate ca si pana acum pe site-urile magazinelor partenere.</p>
              </div>
            </div>
          </div>

        </div>
      </div>
    </section>
  )
}

export default CbToggleText