import React from "react";
import { Helmet } from "react-helmet";
import AccountUserInfo from "./components/account-user-info/AccountUserInfo";
import AccountUserHistory from "./components/account-user-history/AccountUserHistory";
import AccountPaymentHistory from "./components/account-payment-history/AccountPaymentHistory";

const Account = () => {
  return (
    <>
      <Helmet>
        <meta charSet="utf-8" />
        <title>Account</title>
      </Helmet>
      <AccountUserInfo />
      <AccountUserHistory />
      <AccountPaymentHistory />
    </>
  );
};

export default Account;
