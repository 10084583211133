import axios from "axios";

/* Get Shops */

export const getShops = (pageUrl) => async (dispatch) => {
  try {
    dispatch({
      type: "AWAITING_SHOPS",
    });

    const response = await axios.get(`${pageUrl}&perPage=40`);

    const { firstPageUrl, nextPageUrl, prevPageUrl, data } = response.data;

    dispatch({
      type: "SUCCESS_GET_SHOPS",
      payload: {
        firstPageUrl,
        nextPageUrl,
        prevPageUrl,
        data,
      },
    });
  } catch (e) {
    dispatch({
      type: "ERROR_SHOPS",
      payload: {
        e,
      },
    });
  }
};

/* Filter Shops */

export const filterShops = (filterData, pageUrl) => async (dispatch) => {
  try {
    dispatch({
      type: "AWAITING_SHOPS",
    });

    const response = await axios.post(`${pageUrl}&perPage=40`, filterData);

    const { firstPageUrl, nextPageUrl, data } = response.data;
    const filterKey = response.config.data;

    dispatch({
      type: "SUCCESS_FILTER_SHOPS",
      payload: {
        firstPageUrl,
        nextPageUrl,
        data,
        filterKey,
      },
    });
  } catch (e) {
    dispatch({
      type: "ERROR_SHOPS",
      payload: {
        e,
      },
    });
  }
};
