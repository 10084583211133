const initialState = {
  isLoading: false,
  affiliateError: null,
  affiliateSuccess: null,
};

const afiliateReducer = (state = initialState, action) => {
  const { type, payload } = action;

  switch (type) {
    case 'AWAITING_AFFILIATE':
      return {
        ...state,
        isLoading: true,
      };

    case 'ERROR_AFFILIATE':
      return {
        ...state,
        isLoading: false,
        affiliateError: payload.e && payload.e.data ? payload.e.data.message : payload.e ? payload.e : null,
      };

    case 'SUCCESS_AFFILIATE':
      return {
        ...state,
        isLoading: false,
        affiliateSuccess: payload.data && payload.data.link ? payload.data.link : null,
      };

    default:
      return state;
  }
};

export default afiliateReducer;
