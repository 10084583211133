import React from 'react'
import {Carousel} from '../../../../components'

import './CbBenefits.css'

function CbBenefits(){

  return (
    <section className="cb-benefits">
      <div className="cb-container medium-padding">
        <div className="cb-benefits-wrapper">
          <Carousel
            responsive={[
              {
                breakpoint: 60000,
                settings: {
                  arrows: true,
                  slidesToShow: 4,
                  slidesToScroll: 1,
                  initialSlide: 1,
                  pauseOnHover: true,
                  swipe: true,
                  autoplay: false,
                  infinite: true,
                  dots: false
                }
              },
              {
                breakpoint: 769,
                settings: {
                  arrows: true,
                  slidesToShow: 1,
                  slidesToScroll: 1,
                  initialSlide: 1,
                  pauseOnHover: true,
                  swipe: true,
                  autoplay: false,
                  infinite: true,
                  dots: false,
                  autoplaySpeed: 1,
                  speed: 500,
                  cssEase: 'linear'
                }
              }
            ]}
          >

            <div className="slide">
              <h3>Sute de magazine</h3>
              <p>Ai acces gratuit la peste 800 de magazine partenere, pentru a-ti indeplini toate dorintele.</p>
            </div>
            <div className="slide">
              <h3>Usor de folosit</h3>
              <p>Relaxare si bucurie la fiecare achizitie online. Iti faci rapid cont, apoi incepi sa strangi bani cumparand.</p>
            </div>
            <div className="slide">
              <h3>Bonus la cumparaturi</h3>
              <p>Profita de comisioanele si reducerile oferite de magazinele partenere si primeste bani inapoi la fiecare achizitie.</p>
            </div>
            <div className="slide">
              <h3>Accesibil oriunde</h3>
              <p>Cumpara orice iti doresti, indiferent de ora sau locatie.</p>
            </div>

          </Carousel>
        </div>
      </div>
    </section>
  )
}

export default CbBenefits